import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ISpotifySearchSuggestion, SpotifySearch } from './SpotifySearch';

export interface ICopySongDialogProps {
  open: boolean;
  actionIsRunning?: boolean;
  onCopy: (newTitle: string, spotifySelected: ISpotifySearchSuggestion | undefined) => void;
  onClose: () => void;
}

export const CopySongDialog: React.FC<ICopySongDialogProps> = ({
  open,
  actionIsRunning,
  onCopy,
  onClose,
}) => {
  const { t } = useTranslation();

  const [newTitle, setNewTitle] = useState<string>('');
  const [spotifySelected, setSpotifySelect] = useState<ISpotifySearchSuggestion | undefined>(
    undefined,
  );
  const [clearSpotifySelect, setClearSpotifySelect] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const hasErrors = (): boolean => {
    let error = false;
    if (!newTitle && !spotifySelected) {
      error = true;
    }
    setError(error);
    return error;
  };

  const handleCopy = () => {
    if (!hasErrors()) {
      onCopy(newTitle, spotifySelected);
    }
  };

  const handleNewTitleClick = () => {
    setSpotifySelect(undefined);
    setClearSpotifySelect(true);
    setError(false);
  };

  const handleNewTitleChange = (e: any) => {
    setSpotifySelect(undefined);
    setNewTitle(e.target.value);
    setClearSpotifySelect(true);
    setError(false);
  };

  const handleSpotifySelect = (suggestion: ISpotifySearchSuggestion) => {
    setSpotifySelect(suggestion);
    setNewTitle('');
    setClearSpotifySelect(false);
    setError(false);
  };

  const handleAutoCompleteClick = () => {
    setNewTitle('');
    setClearSpotifySelect(false);
    setError(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{ padding: '20px' }}>
      <DialogTitle id="alert-dialog-title">{t('Dashboard.Songs.CopyWorkDeclaration')}</DialogTitle>
      <DialogContent
        sx={{
          p: 3,
        }}
      >
        <TextField
          fullWidth
          autoComplete="off"
          variant="filled"
          label={t('Dashboard.Songs.NewTitle')}
          name="roba_title"
          required
          value={newTitle}
          disabled={actionIsRunning}
          helperText={error ? t('Dashboard.Songs.CopyFormError') : ''}
          error={error}
          style={{ marginBottom: '16px' }}
          onChange={handleNewTitleChange}
          onClick={handleNewTitleClick}
        />
        <SpotifySearch
          disabled={!!actionIsRunning}
          label={t('Dashboard.Songs.ImportFromSpotify')}
          clear={clearSpotifySelect}
          helperText={error ? t('Dashboard.Songs.CopyFormError') : ''}
          error={error}
          onSelect={handleSpotifySelect}
          onClick={handleAutoCompleteClick}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCopy} disabled={actionIsRunning} variant="contained">
          {t('Dashboard.Songs.Copy')}
        </Button>
        <Button onClick={onClose} disabled={actionIsRunning}>
          {t('Dashboard.Common.Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
