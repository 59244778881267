import { validateIpi } from '../../../helper/ValidateIPI';
import { validateEmail, validateRequiredFields } from '../../../helper/ValidationHelper';
import { IWriter, writerRequiredFields } from '../../../interfaces/Writer';

export const getWriterFormErrors = (formData: IWriter, t: any) => {
  const errors = validateRequiredFields(writerRequiredFields, formData, t);

  if (formData.roba_email && !validateEmail(formData.roba_email)) {
    errors.roba_email = t('MasterDataForm.ValidEmail');
  }

  if (
    (formData.roba_society_performing || formData.roba_society_mechanical) &&
    !formData.roba_ipinumber
  ) {
    errors.roba_ipinumber = t('MasterDataForm.MandatoryField');
  }

  if (formData.roba_ipinumber && !validateIpi(formData.roba_ipinumber)) {
    errors.roba_ipinumber = t('MasterDataForm.ValidIpi');
  }

  return errors;
};

export const convertNonAsciiLetters = (str: string) => {
  return str
    .replaceAll('Ä', 'Ae')
    .replaceAll('ä', 'ae')
    .replaceAll('Ö', 'Oe')
    .replaceAll('ö', 'oe')
    .replaceAll('Ü', 'Ue')
    .replaceAll('ü', 'ue')
    .replaceAll('é', 'e')
    .replaceAll('è', 'e')
    .replaceAll('á', 'a')
    .replaceAll('à', 'a');
};
