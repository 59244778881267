import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/Image';
import { downloadDoc, getDocs } from '../../../services/httpService';
import { useAuth } from '../../provider/AuthProvider';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';
import { IFormDocument } from '../../../interfaces/FormDocument';

export const Forms: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { selectedUser } = useAuth();

  const [docs, setDocs] = useState<{ [type: string]: IFormDocument[] }>({});
  const [downloading, setDownloading] = useState<boolean>(false);

  const getDocuments = async () => {
    const docs = await getDocs();
    const docsByCategory = docs.reduce(
      (docsByCategory: { [type: string]: IFormDocument[] }, doc: IFormDocument) => {
        const categorySplit = doc.Kategorie?.split('##');
        const category = categorySplit.length
          ? i18n.language.includes('de')
            ? categorySplit[0]
            : categorySplit[1]
          : undefined;
        if (category) {
          if (!docsByCategory[category]) {
            // eslint-disable-next-line no-param-reassign
            docsByCategory[category] = [];
          }
          docsByCategory[category].push(doc);
        }
        return docsByCategory;
      },
      {},
    );
    const sortedKeys = Object.keys(docsByCategory).sort();
    const sortedDocsByCategory = sortedKeys.reduce((acc, key) => {
      const sortedDocs = docsByCategory[key].sort((a: IFormDocument, b: IFormDocument) =>
        a.Title.localeCompare(b.Title, undefined, { sensitivity: 'base' }),
      );
      acc[key] = sortedDocs;
      return acc;
    }, {} as { [type: string]: IFormDocument[] });
    setDocs(sortedDocsByCategory);
  };

  const getFileIcon = (doc: IFormDocument) => {
    const fileTypeSplit = doc.name.split('.');
    const fileType = fileTypeSplit[fileTypeSplit.length - 1];
    switch (fileType.toLowerCase()) {
      case 'pdf':
        return <PictureAsPdfIcon />;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <ImageIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  const handleFileDownload = async (doc: IFormDocument) => {
    setDownloading(true);
    try {
      const response: any = await downloadDoc(doc);
      const fileName = response.headers['content-disposition']
        .split(';')
        .find((n: string) => n.includes('filename='))
        .replace('filename=', '')
        .replaceAll("'", '')
        .replaceAll('"', '');
      saveAs(response.data, fileName);
    } catch (e) {
      toast.error(t('Dashboard.Songs.RecordingsDownloadError'));
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);

  if (!selectedUser) {
    return (
      <Box
        sx={{
          mt: 20,
          mb: 30,
          textAlign: 'center',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          maxWidth: 'var(--Content-maxWidth)',
          m: 'var(--Content-margin)',
          width: 'var(--Content-width)',
        }}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} sx={{ position: 'relative' }}>
          <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
            <Box>
              <Typography
                variant="h1"
                style={{
                  maxWidth: '500px',
                  marginBottom: '32px',
                  fontSize: '36px',
                  textTransform: 'none',
                }}
              >
                {t('Dashboard.Sidebar.Forms')}
              </Typography>
            </Box>
            <Card>
              <CardContent>
                {Object.keys(docs).map((type: string) => (
                  <div style={{ marginBottom: '80px' }}>
                    <h3 style={{ marginBottom: 0 }}>{type}</h3>
                    <List>
                      {docs[type].map((doc: IFormDocument, index) => (
                        <ListItem key={index} divider>
                          <ListItemIcon>{getFileIcon(doc)}</ListItemIcon>
                          <ListItemText
                            primary={<Typography variant="subtitle1">{doc.Title}</Typography>}
                            secondary={
                              <Typography variant="body2" component="span">
                                {i18n.language.includes('de') ? doc.Info : doc.InfoEN}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="download"
                              disabled={downloading}
                              onClick={() => handleFileDownload(doc)}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                ))}
              </CardContent>
            </Card>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
