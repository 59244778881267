import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Collapse, ListItem, IconButton } from '@mui/material';
import type { ListItemProps } from '@mui/material';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';

interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean;
  children?: React.ReactNode;
  chip?: React.ReactNode;
  depth: number;
  icon?: React.ReactNode;
  info?: React.ReactNode;
  iconBig?: React.ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  collapsedSidebar: boolean;
  disabled?: boolean;
}

export const DashboardSidebarItem: React.FC<DashboardSidebarItemProps> = props => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    iconBig,
    info,
    open: openProp,
    path,
    title,
    collapsedSidebar,
    disabled,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(!!openProp);

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          mb: 0.5,
          py: 0,
          px: 2,
        }}
        {...other}
      >
        {collapsedSidebar ? (
          <IconButton>{iconBig}</IconButton>
        ) : (
          <Button
            endIcon={
              !open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />
            }
            disableRipple
            onClick={handleToggle}
            startIcon={icon}
            sx={{
              color: active ? 'secondary.main' : 'neutral.300',
              justifyContent: 'flex-start',
              pl: `${paddingLeft}px`,
              pr: 3,
              textAlign: 'left',
              textTransform: 'none',
              width: '100%',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255, 0.08)',
              },
              '& .MuiButton-startIcon': {
                color: active ? 'secondary.main' : 'neutral.400',
                fontSize: '20px',
              },
              '& .MuiButton-endIcon': {
                color: 'neutral.400',
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {info}
          </Button>
        )}
        <Collapse in={open} sx={{ mt: 0.5 }}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2,
      }}
    >
      <Link
        to={path as string}
        target={path?.includes('http') ? '_blank' : '_self'}
        rel="noopener noreferrer"
      >
        {collapsedSidebar ? (
          <IconButton
            sx={{
              borderRadius: 1,
              color: disabled ? 'neutral.500' : 'neutral.300',
              justifyContent: 'flex-start',
              textAlign: 'left',
              textTransform: 'none',
              width: '100%',
              ...(active && {
                backgroundColor: 'rgba(255,255,255, 0.08)',
                color: 'secondary.main',
              }),
              '&:hover': {
                backgroundColor: disabled ? 'transparent' : 'rgba(255,255,255, 0.08)',
              },
              cursor: disabled ? 'default' : 'pointer',
            }}
            title={title}
          >
            {iconBig}
          </IconButton>
        ) : (
          <Button
            component="a"
            startIcon={icon}
            endIcon={chip}
            disableRipple
            sx={{
              borderRadius: 1,
              color: disabled ? 'neutral.500' : 'neutral.300',
              justifyContent: 'flex-start',
              pl: `${paddingLeft}px`,
              pr: 3,
              textAlign: 'left',
              textTransform: 'none',
              cursor: disabled ? 'default' : 'pointer',
              width: '210px',
              ...(active && {
                backgroundColor: 'rgba(255,255,255, 0.08)',
                color: 'secondary.main',
                fontWeight: 'fontWeightBold',
              }),
              '& .MuiButton-startIcon': {
                color: active ? 'secondary.main' : disabled ? 'neutral.500' : 'neutral.300',
              },
              '&:hover': {
                backgroundColor: disabled ? 'transparent' : 'rgba(255,255,255, 0.08)',
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {info}
          </Button>
        )}
      </Link>
    </ListItem>
  );
};
