import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Dropzone } from '../lib/Dropzone';

export interface IMultipleWorksDialogProps {
  open: boolean;
  actionIsRunning: boolean;
  onUpload: (file: File) => void;
  onClose: () => void;
}

export const MultipleWorksDialog: React.FC<IMultipleWorksDialogProps> = ({
  open,
  actionIsRunning,
  onUpload,
  onClose,
}) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);

  const hasErrors = (): boolean => {
    let error = false;
    if (!file) {
      error = true;
    }
    setError(error);
    return error;
  };

  const handleUpload = () => {
    if (!hasErrors()) {
      onUpload(file as File);
    }
  };

  const handleFileChange = (files: File[]) => {
    setFile(files[0]);
    setError(false);
  };

  const handleFileReject = () => {
    toast.error(t('Dashboard.Songs.FileNotExpectedFormat'));
  };

  useEffect(() => {
    if (!actionIsRunning && file) {
      setFile(undefined);
    }
  }, [actionIsRunning]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{ padding: '20px' }}>
      <DialogTitle id="alert-dialog-title">{t('Dashboard.Songs.SubmitMultipleWorks')}</DialogTitle>
      <DialogContent
        sx={{
          p: 3,
        }}
      >
        {file && (
          <Typography style={{ marginBottom: '6px', fontWeight: 'bold' }}>
            {t('Dashboard.Songs.SelectedFile')}: {file.name}
          </Typography>
        )}
        <Dropzone
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
          }}
          disabled={actionIsRunning}
          onChange={handleFileChange}
          onFileReject={handleFileReject}
        />
        {error && <FormHelperText error>{t('Dashboard.Songs.ValidFile')}</FormHelperText>}
        <p>
          {t('Dashboard.Songs.SubmitMultipleWorksHint1')}&nbsp;
          <Link to="/dashboard/forms">Forms</Link>&nbsp;
          {t('Dashboard.Songs.SubmitMultipleWorksHint2')}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpload} disabled={actionIsRunning} variant="contained">
          {t('Dashboard.Songs.Upload')}
        </Button>
        <Button onClick={onClose} disabled={actionIsRunning}>
          {t('Dashboard.Common.Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
