import React, { useEffect, useState } from 'react';
import { Box, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ISongWriter, ISong, ISongWriterPublisher, ESongState } from '../../../../interfaces/Song';
import { IWriter } from '../../../../interfaces/Writer';
import { ISongFormStep, ISongFormSteps } from '../SongForm';
import {
  deleteSongFile,
  getSongWriters,
  getWriters,
  saveSongWriters,
  updateSong,
} from '../../../../services/httpService';
import { useAuth } from '../../../provider/AuthProvider';
import loadingSpinner from '../../../../assets/images/loadingSpinner.svg';
import { SongFormWritersModal } from './SongFormWritersModal';
import { SongFormWritersTable } from './SongFormWritersTable';

export interface ISongFormWritersProps {
  songData: ISong;
  steps: ISongFormSteps;
  onSongDataChange: (data: ISong) => void;
  onDelete: () => void;
  onStepChange: (step: ISongFormStep) => void;
}

export const SongFormWriters: React.FC<ISongFormWritersProps> = ({
  songData,
  steps,
  onSongDataChange,
  onDelete,
  onStepChange,
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);
  const [actionIsRunningText, setActionIsRunningText] = useState<string>(
    t('Dashboard.Songs.Saving'),
  );
  const [writers, setWriters] = useState<IWriter[]>([]);
  const [initSongWriters, setInitSongWriters] = useState<ISongWriter[]>([]);
  const [showWriterModal, setShowWriterModal] = useState<boolean>(false);
  const [writerToEdit, setWriterToEdit] = useState<IWriter | undefined>(undefined);
  const [addedSongWriter, setAddedSongWriter] = useState<string | undefined>(undefined);

  const _saveSongWriters = async (songWriters: ISongWriter[], step: ISongFormStep) => {
    setActionIsRunning(true);
    setActionIsRunningText(t('Dashboard.Songs.SavingWriters'));
    try {
      const songWritersToSave = songWriters.filter((sW: ISongWriter) => !(sW.added && sW.deleted));
      const data = {
        roba_songid: songData.roba_songid,
        songWriters: songWritersToSave,
      };
      await saveSongWriters(data);
      toast.success(t('Dashboard.Songs.SongWritersSaveSuccess'));
      onStepChange(step);
    } catch {
      toast.error(t('Dashboard.Songs.SongWritersGetError'));
    } finally {
      setActionIsRunning(false);
      setActionIsRunningText('');
    }
  };

  const updateSongData = async (data: ISong) => {
    setActionIsRunning(true);
    setActionIsRunningText(t('Dashboard.Songs.SavingWriters'));
    try {
      await updateSong(
        {
          ...data,
          statuscode: ESongState.Draft,
        },
        [],
      );
    } catch {
      toast.error(t('Dashboard.Songs.SongUpdateError'));
      setActionIsRunning(false);
    } finally {
      setActionIsRunning(true);
      setActionIsRunningText('');
    }
  };

  const handleDeleteFile = async () => {
    if (window.confirm(t('Dashboard.Songs.FileDeleteTitle'))) {
      try {
        setActionIsRunning(true);
        setActionIsRunningText(t('Dashboard.Songs.DeletingFile'));
        await deleteSongFile(songData.roba_songid as string);
        onSongDataChange({ ...songData, uploadedFileName: undefined });
        toast.success(t('Dashboard.Songs.FileDeleteSuccess'));
      } catch (e) {
        toast.error(t('Dashboard.Songs.FileDeleteError'));
      } finally {
        setActionIsRunning(false);
      }
    }
  };

  const _getSongWriters = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await getSongWriters(params.id as string);
      const songWriters = response.value?.map((songWriter: any) => {
        const writer = {
          roba_songwritersid: songWriter.roba_songwritersid,
          roba_title: songWriter.roba_title,
          roba_name: songWriter.roba_name,
          roba_writer: songWriter._roba_writer_value,
          roba_role: songWriter.roba_role,
          roba_share: songWriter.roba_share,
          roba_share_text: songWriter.roba_share_text,
          roba_share_editor: songWriter.roba_share_editor,
          roba_share_editor_text: songWriter.roba_share_editor_text,
          roba_iscollecting: songWriter.roba_iscollecting,
        };
        const publisher = songWriter.publisher.map((publisher: any) => ({
          roba_songwriters_publishersid: publisher.roba_songwriters_publishersid,
          roba_agreementipiid: publisher._roba_agreementipiid_value,
          roba_share: publisher.roba_share,
          roba_unmanagedpublishername: publisher.roba_unmanagedpublishername,
          roba_unmanagedpublisheripinumber: publisher.roba_unmanagedpublisheripinumber,
          isThirdParty: !publisher._roba_agreementipiid_value,
        }));
        publisher.sort(
          (a: ISongWriterPublisher, b: ISongWriterPublisher) =>
            parseInt(b.roba_share as string) - parseInt(a.roba_share as string),
        );
        return {
          ...writer,
          publisher,
        };
      });
      setInitSongWriters(songWriters);
    } catch {
      toast.error(t('Dashboard.Songs.SongWritersGetError'));
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = async (
    songWriters: ISongWriter[],
    file: File | undefined,
    step: ISongFormStep,
    arValue: number,
    ispublicdomain: boolean,
    publicDomainIncludingText: boolean,
    hasChanged: boolean,
  ) => {
    if (
      songData.roba_gema_performance_distribution !== arValue ||
      songData.roba_ispublicdomain !== ispublicdomain ||
      songData.roba_publicdomainincludingtext !== publicDomainIncludingText ||
      file ||
      hasChanged
    ) {
      const data: any = {
        ...songData,
        roba_gema_performance_distribution:
          songData.roba_gema_performance_distribution !== arValue
            ? arValue
            : songData.roba_gema_performance_distribution,
        roba_ispublicdomain:
          songData.roba_ispublicdomain !== ispublicdomain
            ? ispublicdomain
            : songData.roba_ispublicdomain,
        roba_publicdomainincludingtext:
          songData.roba_publicdomainincludingtext !== publicDomainIncludingText
            ? publicDomainIncludingText
            : songData.roba_publicdomainincludingtext,
      };
      if (file) {
        data.file = file;
        data.uploadedFileName = file?.name;

        // file changed
        if (songData.uploadedFileName) {
          data.deleteFile = true;
        }
      }
      // changed from editor permission to public domain
      if (!songData.roba_ispublicdomain && ispublicdomain && songData.uploadedFileName) {
        data.deleteFile = true;
      }
      onSongDataChange(data);
      await updateSongData(data);
    }
    if (hasChanged) {
      await _saveSongWriters(songWriters, step);
    }
    onStepChange(step);
  };

  const handleSaveWriter = async (createdWriterId: string | undefined): Promise<void> => {
    const writers = await getWriters();
    setWriters(writers);
    handleCloseWriterModal();
    if (createdWriterId) {
      setAddedSongWriter(createdWriterId);
    }
  };

  const handleEditWriter = (writerId: string) => {
    const writerToEdit = writers.find((writer: IWriter) => writer.roba_writerid === writerId);
    setWriterToEdit(writerToEdit);
    setShowWriterModal(true);
  };

  const handleCloseWriterModal = () => {
    setShowWriterModal(false);
    setWriterToEdit(undefined);
  };

  const init = async (): Promise<void> => {
    const writers = await getWriters();
    setWriters(writers);
    _getSongWriters();
  };

  useEffect(() => {
    init();
  }, []);

  if (isLoading) {
    return (
      <CardContent>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
        </Box>
      </CardContent>
    );
  }

  return (
    <>
      <SongFormWritersTable
        songData={songData}
        initSongWriters={initSongWriters}
        writers={writers}
        addedSongWriter={addedSongWriter}
        steps={steps}
        isLoading={isLoading}
        actionIsRunning={actionIsRunning}
        actionIsRunningText={actionIsRunningText}
        onDelete={onDelete}
        onNavigate={navigate}
        onShowCreateWriterModal={(showWriterModal: boolean) => setShowWriterModal(showWriterModal)}
        onDeleteFile={handleDeleteFile}
        onEditWriter={handleEditWriter}
      />
      <SongFormWritersModal
        open={showWriterModal}
        writerToEdit={writerToEdit}
        onSave={handleSaveWriter}
        onClose={handleCloseWriterModal}
      />
    </>
  );
};
