import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { Box, Button, CardActions, CardContent, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ESongState,
  ISong,
  ISongAlternativeTitle,
  ISongRecording,
  ISongRecordingArtist,
  ISongWriter,
  newSongRecording,
  songRecordingArtistRequiredFields,
  songRecordingRequiredFields,
} from '../../interfaces/Song';
import {
  createSongRecording,
  getSong,
  getSongRecordings,
  getSongWriters,
} from '../../services/httpService';
import { useAuth } from '../provider/AuthProvider';
import loadingSpinner from '../../assets/images/loadingSpinner.svg';
import { SongFormOverviewDetails } from '../lib/SongFormOverviewDetails/SongFormOverviewDetails';
import { IWriter } from '../../interfaces/Writer';
import { ISelectOption } from '../../interfaces/SelectOption';
import { SongFormTab } from '../forms/SongForm/SongForm';
import { SongRecordingModal } from '../forms/SongForm/SongRecordingModal';
import { validateISRC, validateRequiredFields } from '../../helper/ValidationHelper';
import { MessageToRobaModal } from './MessageToRobaModal';

export interface ISongListDetailsProps {
  songId: string;
  writers: IWriter[];
  fieldOptions: { [field: string]: ISelectOption[] };
}

export const SongListDetails: React.FC<ISongListDetailsProps> = ({
  songId,
  writers,
  fieldOptions,
}) => {
  const { t } = useTranslation();
  const { selectedUser } = useAuth();

  const [songData, setSongData] = useState<ISong | undefined>(undefined);
  const [titles, setTitles] = useState<ISongAlternativeTitle[]>([]);
  const [recordings, setRecordings] = useState<ISongRecording[] | undefined>(undefined);
  const [songWriters, setSongWriters] = useState<ISongWriter[] | undefined>(undefined);
  const [formData, setFormData] = useState<ISongRecording>(newSongRecording);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);
  const [openRecordingDialog, setOpenRecordingDialog] = useState<boolean>(false);
  const [openMessageDialog, setOpenMessageDialog] = useState<boolean>(false);
  const [artists, setArtists] = useState<ISongRecordingArtist[]>([]);
  const [actionIsRunningText, setActionIsRunningText] = useState<string>('');
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const mainRecording = useMemo(
    () => (recordings || []).find((recording: ISongRecording) => recording.roba_ismainrecording),
    [recordings],
  );

  const isLoading = useMemo(
    () => !songData || !recordings || !songWriters,
    [songData, recordings, songWriters],
  );

  const handleAddRecording = () => {
    setFormData({
      ...newSongRecording,
      roba_title: songData?.roba_title as string,
      roba_durationmins: songData?.roba_durationmins as string,
      roba_durationsecs: songData?.roba_durationsecs as string,
    });
    setOpenRecordingDialog(true);
  };

  const handleChange = (value: any, key: string) => {
    setFormData((formData: ISongRecording) => ({ ...formData, [key]: value }));
    setHasChanged(true);
    if (value && errors[key]) {
      setErrors((errors: { [field: string]: string }) => {
        const nextErrors = { ...errors };
        delete nextErrors[key];
        return nextErrors;
      });
    }
  };
  const handleSpotifySelect = (suggestion: any) => {
    const albumTypeMap: any = {
      album: '722040000',
      single: '722040001',
      compilation: '722040002',
    };
    const recording = {
      ...newSongRecording,
      roba_title: suggestion.title,
      roba_durationmins: suggestion.durationmins,
      roba_durationsecs: suggestion.durationsecs,
      roba_isrc: suggestion.isrc,
      roba_album: suggestion.album,
      roba_album_releasedon: suggestion.releaseDate,
      roba_album_type:
        suggestion.albumType && albumTypeMap[suggestion.albumType]
          ? albumTypeMap[suggestion.albumType]
          : null,
      roba_spotifyid: suggestion.id,
      roba_imageurl: suggestion.image,
    };
    setFormData(recording);
    setHasChanged(true);
    const recordingArtists = (suggestion.artists || []).map((artist: any) => ({
      roba_artistid: uuidv4(),
      roba_name: artist.name,
      roba_spotifyid: artist.id,
      added: true,
    }));
    setArtists(recordingArtists as ISongRecordingArtist[]);
  };

  const hasErrors = (): { [field: string]: string } => {
    let errors: any = validateRequiredFields(songRecordingRequiredFields, formData, t);

    if (formData.roba_isrc && !validateISRC(formData.roba_isrc)) {
      errors.roba_isrc = t('Dashboard.Songs.ISRCError');
    }

    if (!formData.roba_durationmins && !formData.roba_durationsecs) {
      errors.roba_durationmins = t('MasterDataForm.MandatoryField');
      errors.roba_durationsecs = t('MasterDataForm.MandatoryField');
    }

    const recordingArtists = artists.filter((artist: ISongRecordingArtist) => !artist.deleted);
    if (recordingArtists.length) {
      recordingArtists.forEach((artist: ISongRecordingArtist) => {
        if (!errors.artists) {
          const artistRequiredFieldsErrors = validateRequiredFields(
            songRecordingArtistRequiredFields,
            artist,
            t,
          );
          if (Object.keys(artistRequiredFieldsErrors).length) {
            errors = { ...errors, artists: { ...artistRequiredFieldsErrors } };
          }
        }
      });
    } else {
      errors = { ...errors, noArtist: t('Dashboard.Songs.NoArtistError') };
    }

    setErrors(errors);
    return errors;
  };

  const scrollToRequiredField = (errors: { [field: string]: string }) => {
    if (Object.keys(errors).length) {
      let elemId = Object.keys(errors)[0];
      if (elemId === 'artists') {
        elemId = 'artists-table';
      }
      const element = document.getElementById(elemId);
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  };

  const addRecording = async (data: any) => {
    setActionIsRunning(true);
    try {
      showRecordingActionText(data);
      await createSongRecording(data, progressEvent => {
        if (data.file) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      toast.success(t('Dashboard.Songs.RecordingsCreateSuccess'));
      handleCloseDialog();
      getRecordings();
    } catch (e: any) {
      if (e.response?.status === 406) {
        toast.error(t('Dashboard.Songs.FileSampleRateError'));
        return;
      }
      toast.error(t('Dashboard.Songs.RecordingsCreateError'));
    } finally {
      setActionIsRunning(false);
    }
  };

  const showRecordingActionText = (data: any) => {
    setActionIsRunning(true);
    setActionIsRunningText(t('Dashboard.Songs.SavingRecording'));
    if (data.file) {
      setTimeout(() => {
        setActionIsRunningText(t('Dashboard.Songs.UploadingFile'));
      }, 2000);
    }
  };

  const handleSubmit = (fileEdited: boolean): void => {
    const errors = hasErrors();
    if (Object.keys(errors).length) {
      scrollToRequiredField(errors);
      return;
    }

    if (hasChanged) {
      const artistsToSave = artists.filter(
        (artist: ISongRecordingArtist) => !(artist.added && artist.deleted),
      );
      const data = {
        ...formData,
        fileEdited,
        roba_songid: songData?.roba_songid as string,
        artists: artistsToSave,
      };
      addRecording(data);
    }
  };

  const handleCloseDialog = (): void => {
    setFormData({
      ...newSongRecording,
      roba_title: songData?.roba_title as string,
      roba_durationmins: songData?.roba_durationmins as string,
      roba_durationsecs: songData?.roba_durationsecs as string,
    });
    setArtists([]);
    setErrors({});
    setHasChanged(false);
    setOpenRecordingDialog(false);
  };

  const handleArtistsChange = (artists: ISongRecordingArtist[]) => {
    setArtists(artists);
    setHasChanged(true);
    setErrors((errors: { [field: string]: string }) => {
      const nextErrors = { ...errors };
      delete nextErrors.noArtist;
      return nextErrors;
    });
  };

  const getSongData = async () => {
    try {
      const data = await getSong(songId);
      setSongData(data?.song);
      const titles = data?.titles?.value?.map((title: any) => ({
        roba_additionaltitleid: title.roba_additionaltitleid,
        roba_title: title.roba_title,
      }));
      setTitles(titles);
    } catch {
      toast.error(t('Dashboard.Songs.SongGetError'));
    }
  };

  const getRecordings = async () => {
    try {
      const response = await getSongRecordings(songId);
      const recordings = response?.map((recording: any) => {
        const artists = recording.artists.map((artist: any) => ({
          roba_artistid: artist.roba_artistid,
          roba_appleid: artist.roba_appleid,
          roba_isni: artist.roba_isni,
          roba_name: artist.roba_name,
          roba_type: artist.roba_type,
          roba_spotifyid: artist.roba_spotifyid,
        }));
        return {
          roba_recordingid: recording.roba_recordingid,
          roba_ismainrecording: recording.roba_ismainrecording,
          artists,
          roba_type: recording.roba_type,
          roba_type_commission: recording.roba_type_commission,
          roba_islibrary: recording.roba_islibrary,
          roba_title: recording.roba_title,
          roba_mixversion: recording.roba_mixversion,
          roba_isrc: recording.roba_isrc,
          roba_durationmins: recording.roba_durationmins,
          roba_durationsecs: recording.roba_durationsecs,
          roba_title_language: recording.roba_title_language,
          roba_spotifyid: recording.roba_spotifyid,
          roba_album_type: recording.roba_album_type,
          roba_album: recording.roba_album,
          roba_album_version: recording.roba_album_version,
          roba_album_releasedon: recording.roba_album_releasedon,
          roba_album_barcode: recording.roba_album_barcode,
          roba_album_barcode_ean: recording.roba_album_barcode_ean,
          roba_album_cataloguenumber: recording.roba_album_cataloguenumber,
          roba_isinstrumental: recording.roba_isinstrumental,
          roba_lyrics_language: recording.roba_lyrics_language,
          roba_lyrics: recording.roba_lyrics,
          uploadedFileName: recording.uploadedFileName,
          roba_imageurl: recording.roba_imageurl,
        };
      });
      setRecordings(recordings);
    } catch {
      toast.error(t('Dashboard.Songs.RecordingsGetError'));
    }
  };

  const _getSongWriters = async (): Promise<void> => {
    try {
      const response = await getSongWriters(songId);
      const songWriters = response.value?.map((songWriter: any) => ({
        roba_songwritersid: songWriter.roba_songwritersid,
        roba_title: songWriter.roba_title,
        roba_name: songWriter.roba_name,
        roba_writer: songWriter._roba_writer_value,
        roba_role: songWriter.roba_role,
        roba_share: songWriter.roba_share,
        roba_share_text: songWriter.roba_share_text,
        roba_share_editor: songWriter.roba_share_editor,
        roba_share_editor_text: songWriter.roba_share_editor_text,
        roba_iscollecting: songWriter.roba_iscollecting,
        publisher: songWriter.publisher.map((publisher: any) => ({
          roba_songwriters_publishersid: publisher.roba_songwriters_publishersid,
          roba_agreementipiid: publisher._roba_agreementipiid_value,
          roba_share: publisher.roba_share,
          roba_unmanagedpublishername: publisher.roba_unmanagedpublishername,
          roba_unmanagedpublisheripinumber: publisher.roba_unmanagedpublisheripinumber,
        })),
      }));
      setSongWriters(songWriters);
    } catch {
      toast.error(t('Dashboard.Songs.SongWritersGetError'));
    }
  };

  useEffect(() => {
    if (songId && selectedUser) {
      getSongData();
      getRecordings();
      _getSongWriters();
    }
  }, [songId, selectedUser]);

  return (
    <TableRow>
      <TableCell
        colSpan={7}
        sx={{
          p: 0,
          position: 'relative',
          '&:after': {
            position: 'absolute',
            content: '" "',
            top: 0,
            left: 0,
            backgroundColor: 'primary.main',
            width: 3,
            height: 'calc(100% + 1px)',
          },
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              mt: 20,
              mb: 30,
              textAlign: 'center',
            }}
          >
            <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
          </Box>
        ) : (
          <>
            <CardContent>
              <SongFormOverviewDetails
                songData={songData as ISong}
                actionIsRunning={false}
                fieldOptions={fieldOptions}
                titles={titles}
                writers={writers}
                songWriters={songWriters as ISongWriter[]}
                recordings={recordings as ISongRecording[]}
              />
            </CardContent>
            <CardActions
              sx={{
                flexWrap: 'wrap',
                m: -1,
                position: 'relative',
              }}
            >
              {songData?.statuscode !== ESongState.Processed && (
                <>
                  <Link
                    to={`/dashboard/songs/edit/${songId}/${SongFormTab.Song}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button type="submit" sx={{ m: 1 }} variant="contained">
                      {t('Dashboard.Songs.AddAlternateTitles')}
                    </Button>
                  </Link>
                  <Link
                    to={`/dashboard/songs/edit/${songId}/${SongFormTab.Writers}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button type="submit" sx={{ m: 1 }} variant="contained">
                      {t('Dashboard.Songs.AddWriter')}
                    </Button>
                  </Link>
                  <Link
                    to={`/dashboard/songs/edit/${songId}/${SongFormTab.Recordings}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button type="submit" sx={{ m: 1 }} variant="contained">
                      {t('Dashboard.Songs.AddRecording')}
                    </Button>
                  </Link>
                </>
              )}
              {songData?.statuscode === ESongState.Processed && (
                <>
                  <Button
                    type="submit"
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={handleAddRecording}
                  >
                    {t('Dashboard.Songs.AddRecording')}
                  </Button>
                  <Button
                    type="submit"
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => setOpenMessageDialog(true)}
                  >
                    {t('Dashboard.Songs.MessageToRoba')}
                  </Button>
                  <SongRecordingModal
                    open={openRecordingDialog}
                    mainRecording={mainRecording}
                    formData={formData}
                    fieldOptions={fieldOptions}
                    artists={artists}
                    errors={errors}
                    actionIsRunning={actionIsRunning}
                    actionIsRunningText={actionIsRunningText}
                    uploadProgress={uploadProgress}
                    onFormChange={handleChange}
                    onSave={handleSubmit}
                    onClose={handleCloseDialog}
                    onSpotifySelect={handleSpotifySelect}
                    onArtistsChange={handleArtistsChange}
                  />
                  <MessageToRobaModal
                    songId={songId}
                    open={openMessageDialog}
                    onClose={() => setOpenMessageDialog(false)}
                  />
                </>
              )}
            </CardActions>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
