import React, { useEffect, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { IMyData } from '../../interfaces/MyData';
import { getMyData } from '../../services/httpService';
import { IPublisher } from '../../interfaces/Publisher';
import { ISelectedUser } from '../../interfaces/SelectedUser';

export interface IAuthContext {
  userId: string | null;
  selectedUser: ISelectedUser | null;
  myData: IMyData | undefined;
  defaultPublisher: IPublisher | undefined;
  publisher: IPublisher[];
  getContactData: () => void;
  setUserId: (userId: string | null) => void;
  setSelectedUser: (selectedUser: ISelectedUser | null) => void;
  setDefaultPublisher: (defaultPublisher: IPublisher | undefined) => void;
}

export const AuthContext = React.createContext<Partial<IAuthContext>>({});

export const AuthProvider = ({ children }: any) => {
  const { t } = useTranslation();

  const [userId, setUserId] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<ISelectedUser | null>(null);
  const [myData, setMyData] = useState<IMyData | undefined>(undefined);

  const getContactData = async () => {
    try {
      const myData = await getMyData(userId as string);
      const agreementIpiNumbers = myData?.agreementIpiNumbers.reduce(
        (agreementIpiNumbers: any[], agreementIpiNumber: any) => {
          const x = agreementIpiNumbers.find(
            a => a.roba_ipinummerid === agreementIpiNumber.roba_ipinummerid,
          );
          if (!x) {
            return agreementIpiNumbers.concat([agreementIpiNumber]);
          }
          return agreementIpiNumbers;
        },
        [],
      );
      setSelectedUser({
        id: userId as string,
      });
      setMyData({ ...myData, agreementIpiNumbers } as IMyData);
      /*
      if (!myData.agreements.length) {
        toast.error(t('Dashboard.StartPage.NoAgreementError'));
      } else {
        setSelectedUser({
          id: userId as string,
          defaultAgreement: myData._roba_default_agreement_value,
        });
        setMyData(myData as IMyData);
      }
      */
    } catch {
      toast.error(t('Dashboard.StartPage.GetAgreementsError'));
    }
  };

  useEffect(() => {
    if (userId && !myData) {
      getContactData();
    }
  }, [userId]);

  const providedValue = React.useMemo(
    () => ({
      userId,
      selectedUser,
      myData,
      getContactData,
      setUserId,
      setSelectedUser,
    }),
    [userId, selectedUser, myData, getContactData, setUserId, setSelectedUser],
  );

  return <AuthContext.Provider value={providedValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const data = useContext(AuthContext);
  return data;
};
