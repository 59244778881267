export const validateRequiredFields = (
  requiredFields: string[],
  data: any,
  t: any,
): { [field: string]: string } => {
  return requiredFields.reduce((errors: any, field: string) => {
    if (!data[field as keyof any]) {
      return { ...errors, [field]: t('MasterDataForm.MandatoryField') };
    }
    return errors;
  }, {});
};

export const validateZip = (valueToTest: string): boolean => {
  const zipRegex = /^[0-9]{5}$/;
  return zipRegex.test(valueToTest);
};

export const validateEmail = (valueToTest: string): boolean => {
  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return emailRegex.test(valueToTest);
};

export const validateIBAN = (valueToTest: string): boolean => {
  const ibanRegex =
    /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$$/;
  return ibanRegex.test(valueToTest);
};

export const validateBIC = (valueToTest: string): boolean => {
  const bicRegex =
    /^([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)$/;
  return bicRegex.test(valueToTest);
};

export const validateABACode = (valueToTest: string): boolean => {
  const abaRegex = /^[0-9]{9}$/;
  return abaRegex.test(valueToTest);
};

export const validateUSTaxId = (valueToTest: string): boolean => {
  const usTaxRegex1 = /^\\d{2}-?\\d{7}$/;
  const usTaxRegex2 = /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;
  const usTaxRegex3 =
    /^(9\d{2})[- ]{0,1}((7[0-9]{1}|8[0-8]{1})|(9[0-2]{1})|(9[4-9]{1}))[- ]{0,1}(\d{4})$/;
  const allRegex = [usTaxRegex1, usTaxRegex2, usTaxRegex3];
  return allRegex.some((regex: any) => regex.test(valueToTest));
};

export const validateUstId = (valueToTest: string): boolean => {
  const ustIdRegex =
    /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;
  return ustIdRegex.test(valueToTest);
};

export const validateISRC = (valueToTest: string): boolean => {
  const isrcRegex = /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/;
  return isrcRegex.test(valueToTest);
};
