export interface IAccountFormData {
  roba_type: number | null;
  roba_companyname: string;
  roba_firstname: string;
  roba_lastname: string;
  roba_email: string;
  roba_phone: string;
  roba_street: string;
  roba_zip: string;
  roba_city: string;
  roba_landauswahl: number | null;
  roba_state: string;
  roba_country: string;
  roba_ipinumber: string;
  roba_cs_choice: number | null;
  roba_collectingsociety?: number | null;
  roba_collectingsociety_member?: string;
  roba_collectingsociety_2?: number | null;
  roba_collectingsociety_member_2?: string;
  roba_pseudonym?: string;
  roba_pseudonym_ipinumber?: string;
  roba_pseudonym_2?: string;
  roba_pseudonym_ipinumber_2?: string;
  roba_bankaccount_owner: string;
  roba_bankaccount_bankname: string;
  roba_bankaccount_iban: string;
  roba_bankaccount_bic: string;
  roba_abacode: string;
  roba_bankcity: string;
  roba_taxid: string;
  roba_taxenabled: boolean;
  roba_taxid_ustnr: string;
  roba_accept_datamodification_notice: boolean;
  roba_accept_doubletaxation: boolean;
  roba_accept_privacy?: boolean;
}

export enum EAccountFormType {
  'Person' = 722040001,
  'Company' = 722040000,
}

export enum ECollectingSocietyType {
  'None' = 722040000,
  'PRODiffMRO' = 722040003,
}

export enum EAccountFormStep {
  'Account' = 'Account',
  'CollectingSociety' = 'CollectingSociety',
  'Bank' = 'Bank',
  'Tax' = 'Tax',
  'Confirm' = 'Confirm',
}

export const initAccountFormData: IAccountFormData = {
  roba_type: EAccountFormType.Person,
  roba_companyname: '',
  roba_firstname: '',
  roba_lastname: '',
  roba_email: '',
  roba_phone: '49',
  roba_street: '',
  roba_zip: '',
  roba_city: '',
  roba_state: '',
  roba_landauswahl: 722040066,
  roba_country: 'Deutschland',
  roba_ipinumber: '',
  roba_cs_choice: null,
  roba_collectingsociety: null,
  roba_collectingsociety_member: '',
  roba_collectingsociety_2: null,
  roba_collectingsociety_member_2: '',
  roba_pseudonym: '',
  roba_pseudonym_ipinumber: '',
  roba_pseudonym_2: '',
  roba_pseudonym_ipinumber_2: '',
  roba_bankaccount_owner: '',
  roba_bankaccount_bankname: '',
  roba_bankaccount_iban: '',
  roba_bankaccount_bic: '',
  roba_abacode: '',
  roba_bankcity: '',
  roba_taxid: '',
  roba_taxenabled: false,
  roba_taxid_ustnr: '',
  roba_accept_datamodification_notice: false,
  roba_accept_doubletaxation: false,
  roba_accept_privacy: false,
};

export const GERMANY = 722040066;
export const SWISS = 722040207;
export const AUSTRIA = 722040182;
export const USA = 722040251;
export const CANADA = 722040119;
export const EUCOUNTRIES = [
  722040044, 722040150, 722040055, 722040173, 722040065, 722040182, 722040066, 722040191, 722040073,
  722040192, 722040078, 722040196, 722040079, 722040206, 722040089, 722040216, 722040107, 722040217,
  722040110, 722040219, 722040132, 722040243, 722040137, 722040006, 722040142, 722040014, 722040143,
];

export const GEMA = 722040038;
export const AKM = 722040007;
export const SUISA = 722040103;
