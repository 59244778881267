import React, { useMemo } from 'react';
// import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
// import { useAuth } from '../../hooks/use-auth';
import { Cog as CogIcon } from '../icons/cog';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { SwitchHorizontalOutlined as SwitchHorizontalOutlinedIcon } from '../icons/switch-horizontal-outlined';
import { logout } from '../../services/httpService';
import { useAuth } from '../provider/AuthProvider';

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: React.FC<AccountPopoverProps> = props => {
  const navigate = useNavigate();
  const { anchorEl, onClose, open, ...other } = props;
  const { myData } = useAuth();

  const user = useMemo(
    () => ({
      avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
      name: myData?.fullname,
    }),
    [myData],
  );

  /*
  const agreement = useMemo(
    () =>
      myData?.agreements.find((account: IAgreement) => account.accountid === selectedUser?.id)?.name,
    [selectedUser, myData],
  );
  */

  const handleLogout = async (): Promise<void> => {
    try {
      onClose?.();
      await logout();
      navigate('/login');
    } catch (err) {
      console.error(err);
      // toast.error('Unable to logout.');
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 40,
            width: 40,
          }}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1">{user.name}</Typography>
          {/* account && (
            <Typography color="textSecondary" variant="body2">
              {account}
            </Typography>
          ) */}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <Link to="/dashboard/settings/account">
          <MenuItem component="a" style={{ marginBottom: '6px' }}>
            <ListItemIcon>
              <UserCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">Account</Typography>} />
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
        </MenuItem>
      </Box>
    </Popover>
  );
};
