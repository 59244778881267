import React, { useMemo } from 'react';
import {
  Button,
  Box,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { InfoOutlined } from '@mui/icons-material';
import { ISongWriter, ISongWriterPublisher } from '../../../../interfaces/Song';
import { useAuth } from '../../../provider/AuthProvider';
import { validateIpi } from '../../../../helper/ValidateIPI';
import { IAgreementIPINumber } from '../../../../interfaces/MyData';
import { IPISearch } from '../../../lib/IPISearch';

export interface ISongFormWritersPublisherProps {
  songWriter: ISongWriter;
  actionIsRunning: boolean;
  isLoading: boolean;
  errors: any;
  onSongWriterPublisherChange: (value: any, key: string, songWriterPublisherId: string) => void;
  onSongWriterPublisherChangeFromIPISearch: (data: any, songWriterPublisherId: string) => void;
  onDeleteSongWriterPublisher: (songWriterPublisherId: string) => void;
  onAddSongWriterPublisher: (isCoPublisher?: boolean) => void;
  onAddSongWriterThirdPartyPublisher: () => void;
  onSetSongWriterPublisherToAll: (songWriterId: string) => void;
  onFinish: (songWriterId: string) => void;
}

export const SongFormWritersPublisher: React.FC<ISongFormWritersPublisherProps> = ({
  songWriter,
  actionIsRunning,
  isLoading,
  errors,
  onSongWriterPublisherChange,
  onSongWriterPublisherChangeFromIPISearch,
  onDeleteSongWriterPublisher,
  onAddSongWriterPublisher,
  onAddSongWriterThirdPartyPublisher,
  onSetSongWriterPublisherToAll,
  onFinish,
}) => {
  const { t } = useTranslation();
  const { myData } = useAuth();

  const getShareError = (swPublisher: ISongWriterPublisher, errors: any): boolean => {
    if (errors?.roba_swPublisher_share && !swPublisher.roba_share) {
      return true;
    }
    if (errors?.roba_total_share_publisher && swPublisher.roba_share) {
      return true;
    }
    return false;
  };

  const getIPIError = (swPublisher: ISongWriterPublisher, errors: any): boolean => {
    if (!errors?.roba_swPublisher_ipi) {
      return false;
    }
    if (
      swPublisher.roba_unmanagedpublisheripinumber &&
      !validateIpi(swPublisher.roba_unmanagedpublisheripinumber)
    ) {
      return true;
    }
    return false;
  };

  const handleIPISearchSelect = (selectedPublisher: any, sWPublisher: ISongWriterPublisher) => {
    const roba_unmanagedpublishername = `${
      selectedPublisher.roba_firstname ? `${selectedPublisher.roba_firstname} ` : ''
    }${selectedPublisher.roba_lastname}`;
    onSongWriterPublisherChangeFromIPISearch(
      {
        roba_unmanagedpublishername,
        roba_unmanagedpublisheripinumber: selectedPublisher.roba_ipinumber,
      },
      sWPublisher?.roba_songwriters_publishersid,
    );
  };

  const handleIPISearchClear = (sWPublisher: ISongWriterPublisher) => {
    onSongWriterPublisherChangeFromIPISearch(
      {
        roba_unmanagedpublishername: undefined,
        roba_unmanagedpublisheripinumber: undefined,
      },
      sWPublisher?.roba_songwriters_publishersid,
    );
  };

  return (
    <TableRow>
      <TableCell
        colSpan={7}
        sx={{
          p: 0,
          position: 'relative',
          '&:after': {
            position: 'absolute',
            content: '" "',
            top: 0,
            left: 0,
            backgroundColor: 'primary.main',
            width: 3,
            height: 'calc(100% + 1px)',
          },
        }}
      >
        <CardContent>
          <Box
            sx={{
              pl: 9,
            }}
          >
            <Grid container>
              <Typography variant="h6">{t('Dashboard.Songs.Publishers')}</Typography>
              <Divider sx={{ my: 2 }} />
              {songWriter.publisher.map((sWPublisher: ISongWriterPublisher) => (
                <Grid
                  container
                  spacing={3}
                  sx={{ mb: 2 }}
                  style={{ display: sWPublisher.deleted ? 'none' : 'flex' }}
                >
                  {!sWPublisher.isThirdParty && (
                    <Grid item md={8} xs={12}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel required>{t('Dashboard.Songs.Publisher')}</InputLabel>
                        <Select
                          required
                          variant="filled"
                          label={t('Dashboard.Songs.Publisher')}
                          value={sWPublisher?.roba_agreementipiid}
                          disabled={actionIsRunning || isLoading}
                          error={
                            !!errors?.roba_swPublisher_publisher &&
                            !sWPublisher?.roba_agreementipiid
                          }
                          onChange={(e: any) =>
                            onSongWriterPublisherChange(
                              e.target.value,
                              'roba_agreementipiid',
                              sWPublisher?.roba_songwriters_publishersid,
                            )
                          }
                        >
                          {(myData?.agreementIpiNumbers || [])
                            .filter(
                              (agreementIpiNumber: IAgreementIPINumber) =>
                                (!!sWPublisher?.roba_agreementipiid &&
                                  agreementIpiNumber.roba_ipinummerid ===
                                    sWPublisher?.roba_agreementipiid) ||
                                !songWriter.publisher.find(
                                  (p: ISongWriterPublisher) =>
                                    !p.deleted &&
                                    agreementIpiNumber.roba_ipinummerid === p.roba_agreementipiid,
                                ),
                            )
                            .map((agreementIpiNumber: IAgreementIPINumber) => (
                              <MenuItem
                                key={agreementIpiNumber.roba_ipinummerid}
                                value={agreementIpiNumber.roba_ipinummerid}
                              >
                                {agreementIpiNumber.roba_ipiname}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors?.roba_swPublisher_publisher &&
                          !sWPublisher?.roba_agreementipiid && (
                            <FormHelperText error>
                              {errors?.roba_swPublisher_publisher}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  )}
                  {sWPublisher.isThirdParty && (
                    <Grid item md={8} xs={12}>
                      <IPISearch
                        isPublisherSearch
                        disabled={actionIsRunning || isLoading}
                        label={t('Dashboard.Songs.Publisher')}
                        noOptionsText={t('Dashboard.Songs.NoPublishers')}
                        loadingText={t('Dashboard.Songs.LoadingPublishers')}
                        initValue={sWPublisher.roba_unmanagedpublishername}
                        helperText={
                          errors?.roba_swPublisher_name && !sWPublisher.roba_unmanagedpublishername
                            ? errors?.roba_swPublisher_name
                            : ''
                        }
                        error={
                          errors?.roba_swPublisher_name && !sWPublisher.roba_unmanagedpublishername
                        }
                        onSelect={(selectedWriter: any) =>
                          handleIPISearchSelect(selectedWriter, sWPublisher)
                        }
                        onClear={() => handleIPISearchClear(sWPublisher)}
                      />
                      {/*
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          required
                          autoComplete="off"
                          variant="filled"
                          label={t('Dashboard.Songs.Publisher')}
                          value={sWPublisher.roba_unmanagedpublishername}
                          disabled={actionIsRunning}
                          helperText={
                            errors?.roba_swPublisher_name &&
                            !sWPublisher.roba_unmanagedpublishername
                              ? errors?.roba_swPublisher_name
                              : ''
                          }
                          error={
                            errors?.roba_swPublisher_name &&
                            !sWPublisher.roba_unmanagedpublishername
                          }
                          onChange={(e: any) =>
                            onSongWriterPublisherChange(
                              e.target.value,
                              'roba_unmanagedpublishername',
                              sWPublisher?.roba_songwriters_publishersid,
                            )
                          }
                        /> 
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          autoComplete="off"
                          variant="filled"
                          label={t('Dashboard.Writers.IPINumber')}
                          value={sWPublisher.roba_unmanagedpublisheripinumber}
                          disabled={actionIsRunning}
                          helperText={
                            getIPIError(sWPublisher, errors) ? errors?.roba_swPublisher_ipi : ''
                          }
                          error={getIPIError(sWPublisher, errors)}
                          onChange={(e: any) =>
                            onSongWriterPublisherChange(
                              e.target.value,
                              'roba_unmanagedpublisheripinumber',
                              sWPublisher?.roba_songwriters_publishersid,
                            )
                          }
                        /> */}
                    </Grid>
                  )}
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      required
                      autoComplete="off"
                      type="number"
                      variant="filled"
                      label={t('Dashboard.Songs.PublishingClaim')}
                      value={sWPublisher.roba_share}
                      disabled={actionIsRunning}
                      InputProps={{
                        inputProps: { min: 1, max: 100, step: 1 },
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      helperText={
                        errors?.roba_swPublisher_share && !sWPublisher.roba_share
                          ? errors?.roba_swPublisher_share
                          : ''
                      }
                      error={getShareError(sWPublisher, errors)}
                      onChange={(e: any) =>
                        onSongWriterPublisherChange(
                          e.target.value,
                          'roba_share',
                          sWPublisher?.roba_songwriters_publishersid,
                        )
                      }
                    />
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <IconButton
                      disabled={actionIsRunning}
                      title={t('Dashboard.Songs.DeletePublisher')}
                      style={{ marginTop: '8px' }}
                    >
                      <DeleteOutlineIcon
                        onClick={() =>
                          onDeleteSongWriterPublisher(sWPublisher?.roba_songwriters_publishersid)
                        }
                        style={{ cursor: 'pointer' }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item md={8} xs={12} />
                <Grid item md={3} xs={12}>
                  {errors?.roba_total_share_publisher && (
                    <FormHelperText error>{errors?.roba_total_share_publisher}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={1} xs={12} />
              </Grid>
            </Grid>
            <Button
              sx={{ mt: 3, mr: 2 }}
              type="submit"
              variant="outlined"
              disabled={actionIsRunning}
              onClick={() => onAddSongWriterPublisher()}
            >
              {t('Dashboard.Songs.AddControlledPublisher')}
            </Button>
            {/*! !songWriter.publisher.filter(
              (swPublisher: ISongWriterPublisher) => !swPublisher.deleted,
            ).length && (
              <Button
                sx={{ mt: 3, mr: 2 }}
                type="submit"
                variant="outlined"
                disabled={actionIsRunning}
                onClick={() => onAddSongWriterPublisher(true)}
              >
                {t('Dashboard.Songs.AddCoPublisher')}
              </Button>
            ) */}
            <Button
              sx={{ mt: 3 }}
              type="submit"
              variant="outlined"
              disabled={actionIsRunning}
              onClick={() => onAddSongWriterThirdPartyPublisher()}
            >
              {t('Dashboard.Songs.AddUnontrolledPublisher')}
            </Button>
            <Tooltip
              title={<p style={{ fontSize: '16px' }}>{t('Dashboard.Songs.PublishersInfo')}</p>}
              style={{ position: 'relative', top: '10px', marginRight: '20px' }}
            >
              <IconButton>
                <InfoOutlined />
              </IconButton>
            </Tooltip>
            {!!songWriter.publisher.filter(
              (swPublisher: ISongWriterPublisher) => !swPublisher.deleted,
            ).length && (
              <Button
                sx={{ mt: 3, mr: 2 }}
                type="submit"
                variant="outlined"
                disabled={actionIsRunning}
                onClick={() => onSetSongWriterPublisherToAll(songWriter.roba_songwritersid)}
              >
                {t('Dashboard.Songs.UsePublisherRelationship')}
              </Button>
            )}
            {!!songWriter.publisher.filter(
              (swPublisher: ISongWriterPublisher) => !swPublisher.deleted,
            ).length && (
              <Button
                sx={{ mt: 3, mr: 2 }}
                type="submit"
                variant="contained"
                disabled={actionIsRunning}
                onClick={() => onFinish(songWriter.roba_songwritersid as string)}
              >
                {t('Dashboard.Songs.Done')}
              </Button>
            )}
          </Box>
        </CardContent>
      </TableCell>
    </TableRow>
  );
};
