import React, { useMemo } from 'react';
import { Box, Container, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { HelpSideNav } from './HelpSideNav';
import { HelpContactForm } from './HelpContactForm';
import { HelpFAQ } from './HelpFAQ';

export interface IHelpFormData {
  casetypecode: string;
  title: string;
  description: string;
}

export const Help: React.FC = () => {
  const location = useLocation();
  const isFAQ = useMemo(() => location.pathname.includes('faq'), [location]);

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          maxWidth: 'var(--Content-maxWidth)',
          p: '24px',
          width: 'var(--Content-width)',
        }}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} sx={{ position: 'relative' }}>
          <HelpSideNav />
          <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
            {isFAQ ? <HelpFAQ /> : <HelpContactForm />}
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
