export const robaGemaPerformanceDistributionDefault = 0.64;

export enum ESongState {
  Draft = 722040003,
  Submitted = 1,
  Processed = 722040001,
  Error = 722040002,
}

export interface ISong {
  statuscode: ESongState;
  roba_songid?: string;
  roba_title: string;
  roba_durationmins: string;
  roba_durationsecs: string;
  roba_hassamples: boolean;
  roba_sampleslicensed: boolean;
  roba_has_text: boolean;
  roba_gema_performance_distribution: number;
  roba_editorapproval: string;
  roba_ispublicdomain: boolean;
  roba_publicdomainincludingtext: boolean;
  createdon?: string;
  _roba_client_value: string;
  // _roba_agreementid_value: string;
  songWriter?: string;
  isAccount?: boolean;
  uploadedFileName?: string;
}

export const newSong: ISong = {
  statuscode: ESongState.Draft,
  roba_title: '',
  roba_durationmins: '',
  roba_durationsecs: '',
  roba_hassamples: false,
  roba_sampleslicensed: false,
  roba_has_text: false,
  roba_gema_performance_distribution: robaGemaPerformanceDistributionDefault,
  roba_editorapproval: '',
  roba_ispublicdomain: false,
  roba_publicdomainincludingtext: false,
  _roba_client_value: '',
  // _roba_agreementid_value: '',
};

export interface ISongAlternativeTitle {
  roba_additionaltitleid?: string;
  roba_title: string;
  added?: boolean;
  edited?: boolean;
  deleted?: boolean;
}

export interface ISongWriter {
  roba_songwritersid: string;
  roba_writer?: string | null;
  roba_name: string;
  roba_role?: number | null;
  roba_share?: string | null;
  roba_share_text?: string | null;
  roba_share_editor?: string | null;
  roba_share_editor_text?: string | null;
  roba_iscollecting: boolean | null;
  createdon: string;
  publisher: ISongWriterPublisher[];
  added?: boolean;
  edited?: boolean;
  deleted?: boolean;
}

export interface ISongWriterPublisher {
  roba_songwriters_publishersid: string;
  roba_agreementipiid: string | null;
  roba_share: string | null;
  roba_unmanagedpublishername?: string;
  roba_unmanagedpublisheripinumber?: string;
  isThirdParty?: boolean;
  added?: boolean;
  edited?: boolean;
  deleted?: boolean;
}

export interface ISongRecording {
  roba_songid?: string;
  roba_recordingid?: string;
  artists?: ISongRecordingArtist[];
  roba_ismainrecording: boolean;
  roba_type: string;
  roba_type_commission: string | null;
  roba_islibrary: boolean;
  roba_title: string;
  roba_mixversion: string;
  roba_isrc: string;
  roba_durationmins: string | null;
  roba_durationsecs: string | null;
  roba_advisory: string | null;
  roba_title_language: string | null;
  roba_recordlabel: string;
  roba_recordlabel_gvlcode: string;
  roba_genre: string[];
  roba_spotifyid: string;
  roba_album_type: string;
  roba_album: string;
  roba_album_version: string;
  roba_album_releasedon: Date | null;
  roba_album_barcode: string;
  roba_album_barcode_ean: string;
  roba_album_cataloguenumber: string;
  roba_isinstrumental: boolean;
  roba_lyrics_language: string;
  roba_lyrics: string;
  roba_imageurl: string;
  file?: File;
  uploadedFileName?: string;
  fileEdited?: boolean;
}

export interface ISongRecordingArtist {
  roba_artistid?: string;
  roba_appleid?: string;
  roba_isni?: string;
  roba_name: string;
  roba_type?: string | null;
  roba_spotifyid?: string;
  new?: boolean;
  added?: boolean;
  edited?: boolean;
  deleted?: boolean;
}

export const newSongAlternativeTitle: ISongAlternativeTitle = {
  roba_title: '',
  added: true,
};

export const newSongWriter: ISongWriter = {
  roba_songwritersid: '',
  roba_writer: null,
  roba_name: '',
  roba_role: null,
  roba_share: null,
  roba_share_text: null,
  roba_share_editor: null,
  roba_share_editor_text: null,
  roba_iscollecting: null,
  publisher: [],
  added: true,
  createdon: new Date().toISOString(),
};
export const newSongWriterPublisher: ISongWriterPublisher = {
  roba_songwriters_publishersid: '',
  roba_agreementipiid: null,
  roba_share: null,
  added: true,
};

export const newSongRecording: ISongRecording = {
  roba_ismainrecording: false,
  roba_type: '',
  roba_type_commission: null,
  roba_islibrary: false,
  roba_title: '',
  roba_mixversion: '',
  roba_isrc: '',
  roba_durationmins: null,
  roba_durationsecs: null,
  roba_advisory: null,
  roba_title_language: null,
  roba_recordlabel: '',
  roba_recordlabel_gvlcode: '',
  roba_genre: [],
  roba_spotifyid: '',
  roba_album_type: '',
  roba_album: '',
  roba_album_version: '',
  roba_album_releasedon: null,
  roba_album_barcode: '',
  roba_album_barcode_ean: '',
  roba_album_cataloguenumber: '',
  roba_isinstrumental: false,
  roba_lyrics_language: '',
  roba_lyrics: '',
  roba_imageurl: '',
};

export const newSongRecordingArtist: ISongRecordingArtist = {
  roba_appleid: '',
  roba_isni: '',
  roba_name: '',
  roba_type: null,
  roba_spotifyid: '',
  added: true,
};

export const songRequiredFields = ['roba_title'];
export const songWriterRequiredFields = ['roba_writer', 'roba_role'];
export const songRecordingRequiredFields = [
  // 'roba_type',
  'roba_title',
  // 'roba_isrc',
  // 'roba_durationsecs',
  // 'roba_title_language',
  // 'roba_advisory',
  // 'roba_recordlabel',
];
export const songRecordingArtistRequiredFields = ['roba_name'];

export const songWriterRoleComposerValue = 722040000;
export const songWriterRoleAuthorValue = 722040001;
export const songWriterRoleComposerAuthorValue = 722040002;
export const songWriterArrangerRoleValue = 722040003;
export const songWriterAdaptorRoleValue = 722040004;

export const getSongWriterRoles = (t: any): { title: string; value: number }[] => [
  {
    title: t('Dashboard.Songs.Composer'),
    value: songWriterRoleComposerValue,
  },
  {
    title: t('Dashboard.Songs.Author'),
    value: songWriterRoleAuthorValue,
  },
  {
    title: t('Dashboard.Songs.ComposerAuthor'),
    value: songWriterRoleComposerAuthorValue,
  },
  {
    title: t('Dashboard.Songs.Arranger'),
    value: songWriterArrangerRoleValue,
  },
  {
    title: t('Dashboard.Songs.Adaptor'),
    value: songWriterAdaptorRoleValue,
  },
];

export const getSongStates = (t: any): { title: string; value: number; color: string }[] => [
  {
    title: t('Dashboard.Songs.StateDraft'),
    value: ESongState.Draft,
    color: 'warning',
  },
  {
    title: t('Dashboard.Songs.StateSubmitted'),
    value: ESongState.Submitted,
    color: 'success',
  },
  {
    title: t('Dashboard.Songs.StateProcessed'),
    value: ESongState.Processed,
    color: 'primary',
  },
  {
    title: t('Dashboard.Songs.StateError'),
    value: ESongState.Error,
    color: 'error',
  },
];
