import React, { useMemo } from 'react';
import { Box, TextField, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ISongWriter } from '../../../../interfaces/Song';
import { IWriter } from '../../../../interfaces/Writer';

export interface ISongFormWritersAutocompleteProps {
  songWriter: ISongWriter;
  songWriters: ISongWriter[];
  writers: IWriter[];
  actionIsRunning: boolean;
  errors: any;
  onSongWriterChange: (value: any, key: string, songWriterId: string) => void;
}

export const SongFormWritersAutocomplete: React.FC<ISongFormWritersAutocompleteProps> = ({
  songWriter,
  songWriters,
  writers,
  actionIsRunning,
  errors,
  onSongWriterChange,
}) => {
  const { t } = useTranslation();

  const getError = () => {
    if (errors?.roba_writer) {
      return errors?.roba_writer;
    }
    if (errors?.roba_iscollecting_missingFields) {
      const writer = getWriterOptions(songWriter.roba_writer).find(
        (writer: IWriter) => writer.roba_writerid === songWriter.roba_writer,
      );
      if (writer) {
        const hasValidAddress =
          !!writer?.roba_street1 &&
          !!writer?.roba_postalcode &&
          !!writer?.roba_city &&
          !!writer?.roba_country;
        if (!writer?.roba_email || !hasValidAddress) {
          return errors?.roba_iscollecting_missingFields;
        }
      }
    }
    return '';
  };

  const getWriterOptions = (selectedSongWriterId: string | null | undefined) => {
    const options = writers
      .filter(
        (writer: IWriter) =>
          (selectedSongWriterId && writer.roba_writerid === selectedSongWriterId) ||
          !songWriters.find(
            (songWriter: ISongWriter) => songWriter.roba_writer === writer.roba_writerid,
          ),
      )
      .map((writer: IWriter) => {
        const firstName = writer.roba_firstname ? `${writer.roba_firstname} ` : '';
        const lastName = writer.roba_lastname;
        const ipiNumber = writer.roba_ipinumber ? `, IPI: ${writer.roba_ipinumber}` : '';
        const label = `${firstName}${lastName}${ipiNumber}`;
        return {
          ...writer,
          label,
        };
      });
    options.sort((a: any, b: any) => a.label.localeCompare(b.label));
    return options;
  };

  return (
    <Autocomplete
      key={songWriter.roba_writer ? songWriter.roba_writer : songWriter.roba_songwritersid}
      options={getWriterOptions(songWriter.roba_writer)}
      sx={{ width: 300 }}
      value={getWriterOptions(songWriter.roba_writer).find(
        (writer: IWriter) => writer.roba_writerid === songWriter.roba_writer,
      )}
      onChange={(_, newValue: any) => {
        onSongWriterChange(
          newValue?.roba_writerid,
          'roba_writer',
          songWriter.roba_songwritersid as string,
        );
      }}
      disabled={actionIsRunning}
      renderInput={params => (
        <TextField
          {...params}
          required
          variant="filled"
          label={t('Dashboard.Songs.SelectWriter')}
          helperText={getError() || ''}
          error={!!getError()}
          disabled={actionIsRunning}
        />
      )}
      renderOption={(props, option: IWriter) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <b>{`${option.roba_firstname ? `${option.roba_firstname} ` : ''}${
            option.roba_lastname
          }`}</b>
          {`${option.roba_ipinumber ? `, IPI: ${option.roba_ipinumber}` : ''}`}
        </Box>
      )}
    />
  );
};
