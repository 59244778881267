export const getFormattedDate = (d: Date | null) => {
  if (!d) {
    return '';
  }
  const date = new Date(d);
  const day = date.getDate();
  const formattedDay = day < 10 ? `0${day}` : day;
  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const year = date.getFullYear();
  return `${formattedDay}.${formattedMonth}.${year}`;
};
