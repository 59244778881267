import { Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export interface IDropzoneProps {
  disabled: boolean;
  accept?: { [fileType: string]: string[] };
  maxSize?: number;
  placeholder?: string;
  openTrigger?: number;
  onChange: (files: File[]) => void;
  onFileReject?: () => void;
}

export const Dropzone: React.FC<IDropzoneProps> = ({
  disabled,
  accept,
  maxSize,
  placeholder,
  openTrigger,
  onChange,
  onFileReject,
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    multiple: false,
    accept,
    maxSize,
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (!disabled && acceptedFiles.length) {
      onChange(acceptedFiles);
    }
  }, [acceptedFiles, disabled]);

  useEffect(() => {
    if (onFileReject && !disabled && fileRejections.length) {
      onFileReject();
    }
  }, [fileRejections, disabled]);

  useEffect(() => {
    if (openTrigger && openTrigger !== 1) {
      open();
    }
  }, [openTrigger]);

  return (
    <div className="container">
      {disabled ? (
        <div {...getRootProps({ style, onClick: event => event.stopPropagation() })}>
          <input {...getInputProps()} />
          <Typography>
            {placeholder || 'Drag n drop eine Datei oder klicke um eine Datei auszuwählen'}
          </Typography>
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Typography>
            {placeholder || 'Drag n drop eine Datei oder klicke um eine Datei auszuwählen'}
          </Typography>
        </div>
      )}
    </div>
  );
};
