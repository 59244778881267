import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  ESongState,
  ISong,
  ISongRecording,
  ISongRecordingArtist,
  newSongRecording,
  songRecordingArtistRequiredFields,
  songRecordingRequiredFields,
} from '../../../interfaces/Song';
import { Scrollbar } from '../../template/scrollbar';
import { SongRecordingModal } from './SongRecordingModal';
import { validateISRC, validateRequiredFields } from '../../../helper/ValidationHelper';
import { getFormattedDate } from '../../../helper/DateHelper';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';
import {
  createSongRecording,
  deleteSongRecording,
  deleteSongRecordingFile,
  getSongRecordings,
  getSpotifyAlbumData,
  updateSong,
  updateSongRecording,
} from '../../../services/httpService';
import { ISongFormStep, ISongFormSteps } from './SongForm';
import { ISelectOption } from '../../../interfaces/SelectOption';

export interface ISongFormRecordingsProps {
  songData: ISong;
  fieldOptions: { [field: string]: ISelectOption[] } | undefined;
  steps: ISongFormSteps;
  onDelete: () => void;
  onStepChange: (step: ISongFormStep) => void;
}

export const SongFormRecordings: React.FC<ISongFormRecordingsProps> = ({
  songData,
  fieldOptions,
  steps,
  onDelete,
  onStepChange,
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recordings, setRecordings] = useState<ISongRecording[]>([]);
  const [formData, setFormData] = useState<ISongRecording>(newSongRecording);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);
  const [actionIsRunningText, setActionIsRunningText] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [artists, setArtists] = useState<ISongRecordingArtist[]>([]);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const mainRecording = useMemo(
    () => recordings.find((recording: ISongRecording) => recording.roba_ismainrecording),
    [recordings],
  );

  const hasErrors = (): { [field: string]: string } => {
    let errors: any = validateRequiredFields(songRecordingRequiredFields, formData, t);

    if (formData.roba_isrc && !validateISRC(formData.roba_isrc)) {
      errors.roba_isrc = t('Dashboard.Songs.ISRCError');
    }

    const missingDuractionMins =
      !formData.roba_durationmins ||
      formData.roba_durationmins === '0' ||
      formData.roba_durationmins === '00';
    const missingDuractionSecs =
      !formData.roba_durationsecs ||
      formData.roba_durationsecs === '0' ||
      formData.roba_durationsecs === '00';

    if (missingDuractionMins && missingDuractionSecs) {
      errors.roba_durationmins = t('MasterDataForm.MandatoryField');
      errors.roba_durationsecs = t('MasterDataForm.MandatoryField');
    }
    const recordingArtists = artists.filter((artist: ISongRecordingArtist) => !artist.deleted);
    if (recordingArtists.length) {
      recordingArtists.forEach((artist: ISongRecordingArtist) => {
        if (!errors.artists) {
          const artistRequiredFieldsErrors = validateRequiredFields(
            songRecordingArtistRequiredFields,
            artist,
            t,
          );
          if (Object.keys(artistRequiredFieldsErrors).length) {
            errors = { ...errors, artists: { ...artistRequiredFieldsErrors } };
          }
        }
      });
    } else {
      errors = { ...errors, noArtist: t('Dashboard.Songs.NoArtistError') };
    }

    setErrors(errors);
    return errors;
  };

  const addRecording = async (data: any) => {
    setActionIsRunning(true);
    setActionIsRunningText(t('Dashboard.Songs.SavingRecording'));
    try {
      await createSongRecording(data, progressEvent => {
        if (data.file) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      if (songData.statuscode === ESongState.Submitted) {
        await updateSong(
          {
            ...songData,
            statuscode: ESongState.Draft,
          },
          [],
        );
      }
      toast.success(t('Dashboard.Songs.RecordingsCreateSuccess'));
      closeDialog();
      getRecordings();
    } catch (e: any) {
      if (e.response?.status === 406) {
        toast.error(t('Dashboard.Songs.FileSampleRateError'));
        return;
      }
      toast.error(t('Dashboard.Songs.RecordingsCreateError'));
    } finally {
      setActionIsRunning(false);
      setUploadProgress(0);
    }
  };

  const updateRecording = async (data: any) => {
    setActionIsRunning(true);
    setActionIsRunningText(t('Dashboard.Songs.SavingRecording'));
    try {
      await updateSongRecording(data, progressEvent => {
        if (data.file) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      if (songData.statuscode === ESongState.Submitted) {
        await updateSong(
          {
            ...songData,
            statuscode: ESongState.Draft,
          },
          [],
        );
      }
      toast.success(t('Dashboard.Songs.RecordingsUpdateSuccess'));
      closeDialog();
      getRecordings();
    } catch (e: any) {
      if (e.response?.status === 406) {
        toast.error(t('Dashboard.Songs.FileSampleRateError'));
        return;
      }
      toast.error(t('Dashboard.Songs.RecordingsUpdateError'));
    } finally {
      setActionIsRunning(false);
      setUploadProgress(0);
    }
  };

  const deleteRecording = async (recordingId: string) => {
    if (window.confirm(t('Dashboard.Songs.RecordingsDeleteTitle'))) {
      try {
        setActionIsRunning(true);
        await deleteSongRecording(recordingId);
        if (songData.statuscode === ESongState.Submitted) {
          await updateSong(
            {
              ...songData,
              statuscode: ESongState.Draft,
            },
            [],
          );
        }
        toast.success(t('Dashboard.Songs.RecordingsDeleteSuccess'));
        getRecordings();
      } catch (e) {
        toast.error(t('Dashboard.Songs.RecordingsDeleteError'));
      } finally {
        setActionIsRunning(false);
      }
    }
  };

  const deleteRecordingFile = async (recordingId: string) => {
    if (window.confirm(t('Dashboard.Songs.FileDeleteTitle'))) {
      try {
        setActionIsRunning(true);
        setActionIsRunningText(t('Dashboard.Songs.DeletingFile'));
        await deleteSongRecordingFile(recordingId);
        if (songData.statuscode === ESongState.Submitted) {
          await updateSong(
            {
              ...songData,
              statuscode: ESongState.Draft,
            },
            [],
          );
        }
        setFormData({ ...formData, file: undefined, uploadedFileName: undefined });
        toast.success(t('Dashboard.Songs.FileDeleteSuccess'));
      } catch (e) {
        toast.error(t('Dashboard.Songs.FileDeleteError'));
      } finally {
        setActionIsRunning(false);
      }
    }
  };

  const handleChange = (value: any, key: string) => {
    setFormData((formData: ISongRecording) => ({ ...formData, [key]: value }));
    setHasChanged(true);
    if (value && errors[key]) {
      setErrors((errors: { [field: string]: string }) => {
        const nextErrors = { ...errors };
        delete nextErrors[key];
        return nextErrors;
      });
    }
  };

  const handleSpotifySelect = async (suggestion: any) => {
    const albumTypeMap: any = {
      album: '722040000',
      single: '722040001',
      compilation: '722040002',
    };
    const recording: ISongRecording = {
      ...newSongRecording,
      roba_title: suggestion.title,
      roba_durationmins:
        suggestion.durationmins?.length === 1
          ? `0${suggestion.durationmins}`
          : suggestion.durationmins,
      roba_durationsecs:
        suggestion.durationsecs?.length === 1
          ? `0${suggestion.durationsecs}`
          : suggestion.durationmins,
      roba_isrc: suggestion.isrc,
      roba_album: suggestion.album,
      roba_album_releasedon: suggestion.releaseDate,
      roba_album_type:
        suggestion.albumType && albumTypeMap[suggestion.albumType]
          ? albumTypeMap[suggestion.albumType]
          : null,
      roba_spotifyid: suggestion.id,
      roba_imageurl: suggestion.image,
    };
    const albumData: any = await getSpotifyAlbumData(suggestion.albumId);
    if (albumData?.external_ids?.upc) {
      recording.roba_album_barcode = albumData?.external_ids?.upc;
    }
    if (albumData?.external_ids?.ean) {
      recording.roba_album_barcode_ean = albumData?.external_ids?.ean;
    }
    setFormData(recording);
    setHasChanged(true);
    const recordingArtists = (suggestion.artists || []).map((artist: any) => ({
      roba_artistid: uuidv4(),
      roba_name: artist.name,
      roba_spotifyid: artist.id,
      added: true,
      new: true,
    }));
    setArtists(recordingArtists as ISongRecordingArtist[]);
  };

  const scrollToRequiredField = (errors: { [field: string]: string }) => {
    if (Object.keys(errors).length) {
      let elemId = Object.keys(errors)[0];
      if (elemId === 'artists') {
        elemId = 'artists-table';
      }
      const element = document.getElementById(elemId);
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    }
  };

  const handleSubmit = (fileEdited: boolean): void => {
    const errors = hasErrors();
    if (Object.keys(errors).length) {
      scrollToRequiredField(errors);
      return;
    }

    if (hasChanged) {
      const artistsToSave = artists.filter(
        (artist: ISongRecordingArtist) => !(artist.added && artist.deleted),
      );
      const data = {
        ...formData,
        fileEdited,
        roba_songid: songData.roba_songid as string,
        artists: artistsToSave,
      };
      if (data.roba_recordingid) {
        updateRecording(data);
      } else {
        addRecording(data);
      }
    }
  };

  const handleAddRecording = () => {
    setFormData({
      ...newSongRecording,
      roba_title: songData.roba_title,
      roba_durationmins: songData.roba_durationmins,
      roba_durationsecs: songData.roba_durationsecs,
    });
    setOpenDialog(true);
  };

  const handleEditRecording = (recording: ISongRecording) => {
    setFormData(recording);
    setArtists(recording.artists as ISongRecordingArtist[]);
    setOpenDialog(true);
  };

  const handleArtistsChange = (artists: ISongRecordingArtist[]) => {
    setArtists(artists);
    setHasChanged(true);
    setErrors((errors: { [field: string]: string }) => {
      const nextErrors = { ...errors };
      delete nextErrors.noArtist;
      return nextErrors;
    });
  };

  const handleCloseDialog = (): void => {
    setFormData({
      ...newSongRecording,
      roba_title: songData.roba_title,
      roba_durationmins: songData.roba_durationmins,
      roba_durationsecs: songData.roba_durationsecs,
    });
    setArtists([]);
    setErrors({});
    setHasChanged(false);
    setOpenDialog(false);
  };

  const closeDialog = (): void => {
    setFormData({
      ...newSongRecording,
      roba_title: songData.roba_title,
      roba_durationmins: songData.roba_durationmins,
      roba_durationsecs: songData.roba_durationsecs,
    });
    setOpenDialog(false);
    setErrors({});
  };

  const getRecordings = async () => {
    setIsLoading(true);
    try {
      const response = await getSongRecordings(params.id as string);
      const recordings = response?.map((recording: any) => {
        const artists = recording.artists.map((artist: any) => ({
          roba_artistid: artist.roba_artistid,
          roba_appleid: artist.roba_appleid,
          roba_isni: artist.roba_isni,
          roba_name: artist.roba_name,
          roba_type: artist.roba_type,
          roba_spotifyid: artist.roba_spotifyid,
        }));
        artists.sort((a: ISongRecordingArtist, b: ISongRecordingArtist) =>
          a.roba_name.localeCompare(b.roba_name),
        );
        return {
          roba_recordingid: recording.roba_recordingid,
          roba_ismainrecording: recording.roba_ismainrecording,
          artists,
          roba_type: recording.roba_type,
          roba_type_commission: recording.roba_type_commission,
          roba_islibrary: recording.roba_islibrary,
          roba_title: recording.roba_title,
          roba_mixversion: recording.roba_mixversion,
          roba_isrc: recording.roba_isrc,
          roba_durationmins:
            recording.roba_durationmins < 10
              ? `0${recording.roba_durationmins}`
              : recording.roba_durationmins,
          roba_durationsecs:
            recording.roba_durationsecs < 10
              ? `0${recording.roba_durationsecs}`
              : recording.roba_durationsecs,
          roba_advisory: recording.roba_advisory,
          roba_title_language: recording.roba_title_language,
          roba_recordlabel: recording.roba_recordlabel,
          roba_recordlabel_gvlcode: recording.roba_recordlabel_gvlcode,
          roba_genre: recording.roba_genre ? recording.roba_genre.split(',') : [],
          roba_spotifyid: recording.roba_spotifyid,
          roba_album_type: recording.roba_album_type,
          roba_album: recording.roba_album,
          roba_album_version: recording.roba_album_version,
          roba_album_releasedon: recording.roba_album_releasedon,
          roba_album_barcode: recording.roba_album_barcode,
          roba_album_barcode_ean: recording.roba_album_barcode_ean,
          roba_album_cataloguenumber: recording.roba_album_cataloguenumber,
          roba_isinstrumental: recording.roba_isinstrumental,
          roba_lyrics_language: recording.roba_lyrics_language,
          roba_lyrics: recording.roba_lyrics,
          uploadedFileName: recording.uploadedFileName,
          roba_imageurl: recording.roba_imageurl,
        };
      });
      setRecordings(recordings);
    } catch {
      toast.error(t('Dashboard.Songs.RecordingsGetError'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRecordings();
  }, []);

  useEffect(() => {
    if (songData) {
      setFormData({
        ...newSongRecording,
        roba_title: songData.roba_title,
        roba_durationmins: songData.roba_durationmins,
        roba_durationsecs: songData.roba_durationsecs,
      });
    }
  }, [songData]);

  if (isLoading || !fieldOptions) {
    return (
      <CardContent>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
        </Box>
      </CardContent>
    );
  }

  return (
    <>
      <CardHeader title={t('Dashboard.Songs.Recordings')} />
      <Divider />
      <CardContent>
        <Scrollbar>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell width={200}>{t('Dashboard.Songs.Title')}</TableCell>
                <TableCell width={200}>{t('Dashboard.Songs.Version')}</TableCell>
                <TableCell width={200}>{t('Dashboard.Songs.ISRC')}</TableCell>
                <TableCell width={140}>{t('Dashboard.Songs.Artist')}</TableCell>
                <TableCell width={200}>{t('Dashboard.Songs.Album')}</TableCell>
                <TableCell width={160}>{t('Dashboard.Songs.ReleaseDateShort')}</TableCell>
                <TableCell width={160}>Audiofile</TableCell>
                <TableCell align="right">{t('Dashboard.Common.Edit')}</TableCell>
                <TableCell align="right">{t('Dashboard.Common.Delete')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordings.map((recording: ISongRecording, index: number) => (
                <TableRow hover key={index}>
                  <TableCell>{recording.roba_title}</TableCell>
                  <TableCell>{recording.roba_mixversion}</TableCell>
                  <TableCell>{recording.roba_isrc}</TableCell>
                  <TableCell>
                    {recording.artists
                      ?.map((artist: ISongRecordingArtist) => artist.roba_name)
                      .join(', ')}
                  </TableCell>
                  <TableCell>{recording.roba_album}</TableCell>
                  <TableCell>{getFormattedDate(recording.roba_album_releasedon)}</TableCell>
                  <TableCell>{recording.uploadedFileName}</TableCell>
                  <TableCell align="right">
                    <IconButton disabled={actionIsRunning}>
                      <EditIcon
                        onClick={() => handleEditRecording(recording)}
                        style={{ cursor: 'pointer' }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton disabled={actionIsRunning}>
                      <DeleteOutlineIcon
                        onClick={() => deleteRecording(recording.roba_recordingid as string)}
                        style={{ cursor: 'pointer' }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
        <Box sx={{ mt: 4 }}>
          <Button disabled={actionIsRunning} onClick={handleAddRecording} variant="outlined">
            {t('Dashboard.Songs.AddRecordingAndAudio')}
          </Button>
        </Box>
        <SongRecordingModal
          open={openDialog}
          mainRecording={mainRecording}
          formData={formData}
          fieldOptions={fieldOptions}
          artists={artists}
          errors={errors}
          actionIsRunning={actionIsRunning}
          actionIsRunningText={actionIsRunningText}
          uploadProgress={uploadProgress}
          onFormChange={handleChange}
          onSave={handleSubmit}
          onClose={handleCloseDialog}
          onDeleteRecordingFile={deleteRecordingFile}
          onSpotifySelect={handleSpotifySelect}
          onArtistsChange={handleArtistsChange}
        />
      </CardContent>
      <CardActions
        sx={{
          flexWrap: 'wrap',
          m: -1,
          position: 'relative',
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            mt: 1,
          }}
        >
          <Grid item md={6} xs={12}>
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning}
              onClick={() => onStepChange(steps.writers)}
            >
              {t('Dashboard.Common.Prev')}
            </Button>

            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning}
              onClick={() => onStepChange(steps.overview)}
            >
              {t('Dashboard.Common.Next')}
            </Button>
          </Grid>
          <Grid item md={6} xs={12} style={{ textAlign: 'right' }}>
            <Link to="/dashboard/songs" style={{ textDecoration: 'none' }}>
              <Button component="a" variant="outlined" disabled={actionIsRunning}>
                {t('Dashboard.Songs.Cancel')}
              </Button>
            </Link>
            {songData?.roba_songid && (
              <Button sx={{ m: 1 }} color="error" disabled={actionIsRunning} onClick={onDelete}>
                {t('Dashboard.Songs.DeleteSong')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </>
  );
};
