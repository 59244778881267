import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ListChecks as ListChecksIcon } from '@phosphor-icons/react/dist/ssr/ListChecks';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRight as ArrowRightIcon } from '@phosphor-icons/react/dist/ssr/ArrowRight';
import { ESongState, ISong, getSongStates } from '../../../interfaces/Song';
import { SongFormTab } from '../../forms/SongForm/SongForm';
import { getFormattedDate } from '../../../helper/DateHelper';

export interface IStartPageLastAddedSongsProps {
  songs: ISong[];
}

export const StartPageLastAddedSongs: React.FC<IStartPageLastAddedSongsProps> = ({ songs }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToSong = (song: ISong) => {
    if (song?.statuscode !== ESongState.Processed) {
      navigate(`/dashboard/songs/edit/${song.roba_songid}/${SongFormTab.Song}`);
    }
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              '--Avatar-size': '48px',
              bgcolor: 'var(--mui-palette-background-paper)',
              boxShadow: 'rgba(0, 0, 0, 0.08) 0px 3px 14px 0px',
              color: 'var(--mui-palette-text-primary)',
            }}
          >
            <ListChecksIcon fontSize="var(--icon-fontSize-lg)" />
          </Avatar>
        }
        title={t('Dashboard.RecentlyAddedWorks')}
      />
      <List
        disablePadding
        sx={{
          p: 1,
          '& .MuiListItemButton-root': { borderRadius: 1 },
          '& .MuiBadge-dot': {
            border: '2px solid var(--mui-palette-background-paper)',
            borderRadius: '50%',
            bottom: '5px',
            height: '12px',
            right: '5px',
            width: '12px',
          },
        }}
      >
        {songs.map((song: ISong) => {
          const state = getSongStates(t).find(
            (songState: { title: string; value: number }) => songState.value === song.statuscode,
          );
          return (
            <ListItem disablePadding key={song.roba_songid}>
              <ListItemButton
                onClick={() => navigateToSong(song)}
                style={{
                  cursor: song?.statuscode === ESongState.Processed ? 'default' : 'pointer',
                }}
              >
                <ListItemText
                  disableTypography
                  primary={<Typography noWrap>{song.roba_title}</Typography>}
                />
                <Typography color="text.secondary" sx={{ whiteSpace: 'nowrap' }} variant="caption">
                  {getFormattedDate(new Date(song.createdon as string))}
                </Typography>
                <div style={{ width: '100px', marginLeft: '10px' }}>
                  <Chip label={state?.title} color={state?.color as any} size="small" />
                </div>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <CardActions>
        <Link to="/dashboard/songs" style={{ textDecoration: 'none' }}>
          <Button color="secondary" endIcon={<ArrowRightIcon />} size="small">
            {t('Dashboard.GoToWorks')}
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};
