import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRight as ArrowRightIcon } from '@phosphor-icons/react/dist/ssr/ArrowRight';
import { Users as UsersIcon } from '@phosphor-icons/react/dist/ssr/Users';
import { IWriter } from '../../../interfaces/Writer';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';

export interface IStartPageLastAddedWritersProps {
  writers: IWriter[];
}

export const StartPageLastAddedWriters: React.FC<IStartPageLastAddedWritersProps> = ({
  writers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToWriter = (writerId: string) => {
    navigate(`/dashboard/writers/edit/${writerId}`);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              '--Avatar-size': '48px',
              bgcolor: 'var(--mui-palette-background-paper)',
              boxShadow: 'rgba(0, 0, 0, 0.08) 0px 3px 14px 0px',
              color: 'var(--mui-palette-text-primary)',
            }}
          >
            <UsersIcon fontSize="var(--icon-fontSize-lg)" />
          </Avatar>
        }
        title={t('Dashboard.RecentlyAddedWriters')}
      />
      <List
        disablePadding
        sx={{
          p: 1,
          '& .MuiListItemButton-root': { borderRadius: 1 },
          '& .MuiBadge-dot': {
            border: '2px solid var(--mui-palette-background-paper)',
            borderRadius: '50%',
            bottom: '5px',
            height: '12px',
            right: '5px',
            width: '12px',
          },
        }}
      >
        {writers.map((writer: IWriter) => (
          <ListItem disablePadding key={writer.roba_writerid}>
            <ListItemButton onClick={() => navigateToWriter(writer.roba_writerid as string)}>
              <ListItemText
                disableTypography
                primary={
                  <Typography noWrap>
                    {`${writer.roba_firstname ? `${writer.roba_firstname} ` : ''} ${
                      writer.roba_lastname
                    }`}
                    {writer.roba_ipinumber ? `, ${writer.roba_ipinumber}` : ''}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <CardActions>
        <Link to="/dashboard/writers" style={{ textDecoration: 'none' }}>
          <Button color="secondary" endIcon={<ArrowRightIcon />} size="small">
            {t('Dashboard.GoToWriters')}
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};
