import { Grid, Typography, TextField } from '@mui/material';
import React from 'react';
import { ISongRecording } from '../../../interfaces/Song';
import styles from './SongForm.module.scss';

export interface ISongRecordingModalCoverProps {
  formData: ISongRecording;
  errors: { [field: string]: string };
  actionIsRunning: boolean;
  onFormChange: (value: any, key: string) => void;
}

export const SongRecordingModalCover: React.FC<ISongRecordingModalCoverProps> = ({
  formData,
  errors,
  actionIsRunning,
  onFormChange,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Typography variant="h6">Cover / Artwork</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          style={{ display: 'none' }}
          fullWidth
          autoComplete="off"
          variant="filled"
          label="Image URL"
          name="roba_image"
          value={formData.roba_imageurl}
          helperText={errors.roba_imageurl}
          error={!!errors.roba_imageurl}
          disabled={actionIsRunning}
          onChange={(e: any) => onFormChange(e.target.value, 'roba_imageurl')}
        />
        {formData.roba_imageurl ? (
          <a
            href={`https://open.spotify.com/track/${formData.roba_spotifyid}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={formData.roba_imageurl} alt={formData.roba_title} className={styles.cover} />
          </a>
        ) : (
          <div className={styles.coverPlaceholder} />
        )}
      </Grid>
    </Grid>
  );
};
