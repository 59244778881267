import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Card,
  CardContent,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import {
  ESongState,
  ISong,
  ISongAlternativeTitle,
  ISongRecording,
  newSong,
  newSongRecording,
  robaGemaPerformanceDistributionDefault,
} from '../../../interfaces/Song';
import { useAuth } from '../../provider/AuthProvider';
import { getSongFormSongDataErrors } from './SongFormHelper';
import {
  createSong,
  createSongRecording,
  deleteSong,
  getSong,
  recordingFieldOptions,
  updateSong,
} from '../../../services/httpService';
import { SongFormRecordings } from './SongFormRecordings';
import { SongFormSongData } from './SongFormSongData';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';
import { ISpotifySearchSuggestion } from '../../lib/SpotifySearch';
import { ISelectOption } from '../../../interfaces/SelectOption';
import { DeleteDialog } from '../../lib/DeleteDialog';
import { SongFormOverview } from './SongFormOverview';
import { SongFormWriters } from './SongWriters/SongFormWriters';

export enum SongFormTab {
  'Song' = 'song',
  'Writers' = 'writers',
  'Recordings' = 'recordings',
}

export interface ISongFormStep {
  title: string;
  step: number;
  tab: string;
}
export interface ISongFormSteps {
  song: ISongFormStep;
  writers: ISongFormStep;
  recordings: ISongFormStep;
  overview: ISongFormStep;
}

export const SongForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { selectedUser } = useAuth();
  const isEditForm = useMemo(() => location.pathname.includes('edit'), [location, params]);

  const steps: ISongFormSteps = useMemo(
    () => ({
      song: { title: t('Dashboard.Songs.SongData'), step: 0, tab: 'song' },
      writers: { title: 'Writers & Splits', step: 1, tab: 'writers' },
      recordings: { title: 'Recordings', step: 2, tab: 'recordings' },
      overview: { title: t('Dashboard.Songs.Complete'), step: 3, tab: 'overview' },
    }),
    [t],
  );

  const [activeStep, setActiveStep] = useState<ISongFormStep>(steps.song);
  const [fieldOptions, setFieldOptions] = useState<
    { [field: string]: ISelectOption[] } | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<ISong>(newSong);
  const [titles, setTitles] = useState<ISongAlternativeTitle[]>([]);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [tmpRecording, setTmpRecording] = useState<ISongRecording | undefined>(undefined);
  const [createTmpRecording, setCreateTmpRecording] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);
  const [actionIsRunningText, setActionIsRunningText] = useState<string>(
    t('Dashboard.Songs.Saving'),
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const hasErrors = (): boolean => {
    const errors = getSongFormSongDataErrors(formData, titles, t);
    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleChange = (value: any, key: string) => {
    const nextFormdata = { ...formData, [key]: value };
    setFormData(nextFormdata);
    setHasChanged(true);
    if (value && errors[key]) {
      const nextErrors = { ...errors };
      delete nextErrors[key];
      setErrors(nextErrors);
    }
  };

  const handleSpotifySelect = (suggestion: ISpotifySearchSuggestion) => {
    setFormData({
      ...formData,
      roba_title: suggestion.title,
      roba_durationmins: suggestion.durationmins,
      roba_durationsecs: suggestion.durationsecs,
    });
    setHasChanged(true);
    const albumTypeMap: any = {
      album: '722040000',
      single: '722040001',
      compilation: '722040002',
    };
    const tmpRecording = { ...newSongRecording };
    tmpRecording.roba_title = suggestion.title;
    tmpRecording.roba_durationmins = suggestion.durationmins;
    tmpRecording.roba_durationsecs = suggestion.durationsecs;
    tmpRecording.roba_isrc = suggestion.isrc;
    tmpRecording.roba_album = suggestion.album;
    tmpRecording.roba_album_releasedon = suggestion.releaseDate;
    tmpRecording.roba_album_type =
      suggestion.albumType && albumTypeMap[suggestion.albumType]
        ? albumTypeMap[suggestion.albumType]
        : null;
    tmpRecording.roba_spotifyid = suggestion.id;
    tmpRecording.roba_ismainrecording = true;
    tmpRecording.roba_imageurl = suggestion.image;
    tmpRecording.artists = (suggestion.artists || []).map((artist: any) => ({
      roba_artistid: uuidv4(),
      roba_name: artist.name,
      roba_spotifyid: artist.id,
      added: true,
      new: true,
    }));
    setTmpRecording(tmpRecording);
  };

  const handleTitleChange = (titles: ISongAlternativeTitle[]) => {
    setTitles(titles);
    setHasChanged(true);
  };

  const navigateToTab = (songId: string, tab: string) => {
    navigate(`/dashboard/songs/edit/${songId}/${tab}`);
  };

  const handleStepChange = (step: ISongFormStep, songId: string) => {
    setActiveStep(step);
    navigateToTab(songId, step.tab);
  };

  const handleCreateSong = async (data: ISong) => {
    try {
      setActionIsRunning(true);
      const titlesToAdd = titles.filter((title: ISongAlternativeTitle) => !title.deleted);
      const songToCreate = await createSong(data, titlesToAdd);
      setFormData({ ...formData, roba_songid: songToCreate.roba_songid });
      toast.success(t('Dashboard.Songs.SongCreateSuccess'));
      if (tmpRecording && createTmpRecording) {
        tmpRecording.roba_songid = songToCreate.roba_songid as string;
        tmpRecording.artists = await createSongRecording(tmpRecording);
        setTmpRecording(undefined);
        toast.success(t('Dashboard.Songs.RecordingsCreateSuccess'));
      }
      handleStepChange(steps.writers, songToCreate.roba_songid);
      setHasChanged(false);
    } catch {
      toast.error(t('Dashboard.Songs.SongCreateError'));
    } finally {
      setActionIsRunning(false);
    }
  };

  const handleUpdateSong = async (data: ISong) => {
    try {
      setActionIsRunning(true);
      await updateSong(
        {
          ...data,
          statuscode: ESongState.Draft,
        },
        titles,
      );
      if (tmpRecording) {
        tmpRecording.roba_songid = formData.roba_songid as string;
        await createSongRecording(tmpRecording);
        setTmpRecording(undefined);
      }
      await getSongData();
      toast.success(t('Dashboard.Songs.SongUpdateSuccess'));
      navigateToTab(formData.roba_songid as string, SongFormTab.Writers);
      setHasChanged(false);
    } catch {
      toast.error(t('Dashboard.Songs.SongUpdateError'));
    } finally {
      setActionIsRunning(false);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!hasErrors()) {
      if (formData.roba_songid) {
        handleUpdateSong(formData);
      } else {
        handleCreateSong(formData);
      }
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteDialogOpen(false);
      setActionIsRunningText(t('Dashboard.Songs.Deleting'));
      setActionIsRunning(true);
      await deleteSong(formData.roba_songid as string);
      navigate(`/dashboard/songs`);
      toast.success(t('Dashboard.Songs.SongDeleteSuccess'));
    } catch (e) {
      toast.error(t('Dashboard.Songs.SongDeleteError'));
    } finally {
      setActionIsRunning(false);
    }
  };

  const getSongData = async () => {
    setIsLoading(true);
    try {
      const songData = await getSong(params.id as string);
      const song = songData?.song;
      const formData = {
        statuscode: song.statuscode,
        roba_songid: song.roba_songid,
        roba_title: song.roba_title,
        roba_durationmins:
          song.roba_durationmins < 10 ? `0${song.roba_durationmins}` : song.roba_durationmins,
        roba_durationsecs:
          song.roba_durationsecs < 10 ? `0${song.roba_durationsecs}` : song.roba_durationsecs,
        roba_hassamples: song.roba_hassamples,
        roba_sampleslicensed: song.roba_sampleslicensed,
        roba_has_text: song.roba_has_text,
        roba_editorapproval: song.roba_editorapproval,
        roba_ispublicdomain: song.roba_ispublicdomain,
        roba_publicdomainincludingtext: song.roba_publicdomainincludingtext,
        roba_gema_performance_distribution:
          song.roba_gema_performance_distribution || robaGemaPerformanceDistributionDefault,
        // _roba_agreementid_value: song._roba_agreementid_value,
        _roba_client_value: song._roba_client_value,
        uploadedFileName: song.uploadedFileName,
      };
      // Disable detail view for status processed and error
      if (
        formData.statuscode === ESongState.Processed ||
        formData.statuscode === ESongState.Error
      ) {
        navigate('/dashboard/songs');
        return;
      }
      setFormData(formData);
      const titles = songData?.titles?.value?.map((title: any) => ({
        roba_additionaltitleid: title.roba_additionaltitleid,
        roba_title: title.roba_title,
      }));
      setTitles(titles);
    } catch {
      toast.error(t('Dashboard.Songs.SongGetError'));
    } finally {
      setIsLoading(false);
    }
  };

  const getFieldOptions = async () => {
    const fieldOptions = await recordingFieldOptions();
    setFieldOptions(fieldOptions);
  };

  const init = async (): Promise<void> => {
    await getFieldOptions();
    if (isEditForm && params?.id) {
      getSongData();
    } else {
      setFormData({
        ...formData,
        // _roba_agreementid_value: selectedUser?.defaultAgreement as string,
        _roba_client_value: selectedUser?.id as string,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [selectedUser]);

  useEffect(() => {
    if (params.id && params.tab && params.tab !== activeStep.tab) {
      const step = (steps as any)[params.tab];
      if (step) {
        handleStepChange(step, params.id);
      }
    }
  }, [activeStep, params]);

  if (isLoading || !selectedUser) {
    return (
      <Box
        sx={{
          mt: 20,
          mb: 30,
          textAlign: 'center',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box style={{ marginBottom: '0px' }}>
        <Link to="/dashboard/songs" style={{ textDecoration: 'none' }}>
          <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
          <Typography
            variant="subtitle2"
            style={{ display: 'inline-block', position: 'relative', top: '-6px' }}
          >
            {t('Dashboard.Songs.Songs')}
          </Typography>
        </Link>
      </Box>
      <Box mt={3}>
        <Card>
          <Typography
            variant="h1"
            style={{
              textAlign: 'center',
              fontSize: '36px',
              textTransform: 'none',
              marginTop: '32px',
            }}
          >
            {t('Dashboard.Sidebar.WorkDeclaration')}
          </Typography>
          {formData?.roba_title && (
            <Typography
              variant="h2"
              style={{
                textAlign: 'center',
                fontSize: '24px',
                textTransform: 'none',
                marginTop: '12px',
              }}
            >
              {formData?.roba_title}
            </Typography>
          )}
          <CardContent>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep.step} alternativeLabel>
                {Object.keys(steps).map((key: string) => (
                  <Step key={(steps as any)[key].title}>
                    <StepLabel>{(steps as any)[key].title}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </CardContent>
          {activeStep?.step === steps.song.step && (
            <SongFormSongData
              formData={formData}
              tmpRecording={tmpRecording}
              createTmpRecording={createTmpRecording}
              titles={titles}
              actionIsRunning={actionIsRunning}
              actionIsRunningText={actionIsRunningText}
              errors={errors}
              hasChanged={hasChanged}
              steps={steps}
              handleChange={handleChange}
              onTitlesChange={handleTitleChange}
              onErrorsChange={(errors: { [field: string]: string }) => setErrors(errors)}
              hasErrors={hasErrors}
              onDelete={() => setDeleteDialogOpen(true)}
              onSubmit={handleSubmit}
              onStepChange={(step: ISongFormStep) =>
                handleStepChange(step, formData?.roba_songid as string)
              }
              onSpotifySelect={handleSpotifySelect}
              onCreateTmpRecording={(createTmpRecording: boolean) =>
                setCreateTmpRecording(createTmpRecording)
              }
            />
          )}
          {activeStep?.step === steps.writers.step && (
            <SongFormWriters
              songData={formData}
              steps={steps}
              onSongDataChange={(data: ISong) => setFormData(data)}
              onDelete={() => setDeleteDialogOpen(true)}
              onStepChange={(step: ISongFormStep) =>
                handleStepChange(step, formData?.roba_songid as string)
              }
            />
          )}
          {activeStep?.step === steps.recordings.step && (
            <SongFormRecordings
              songData={formData}
              fieldOptions={fieldOptions}
              steps={steps}
              onDelete={() => setDeleteDialogOpen(true)}
              onStepChange={(step: ISongFormStep) =>
                handleStepChange(step, formData?.roba_songid as string)
              }
            />
          )}
          {activeStep?.step === steps.overview.step && (
            <SongFormOverview
              songData={formData}
              fieldOptions={fieldOptions}
              steps={steps}
              titles={titles}
              onDelete={() => setDeleteDialogOpen(true)}
              onStepChange={(step: ISongFormStep) =>
                handleStepChange(step, formData?.roba_songid as string)
              }
            />
          )}
        </Card>
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        headline={t('Dashboard.Songs.ConfirmDeleteSong')}
        onDelete={handleDelete}
        onClose={() => setDeleteDialogOpen(false)}
      />
    </Container>
  );
};
