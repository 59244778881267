import {
  Grid,
  Typography,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Box,
  Button,
  Dialog,
  IconButton,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
  ISongRecordingArtist,
  newSongRecordingArtist,
  songRecordingArtistRequiredFields,
} from '../../../interfaces/Song';
import { X as XIcon } from '../../icons/x';
import { validateRequiredFields } from '../../../helper/ValidationHelper';
import { ISelectOption } from '../../../interfaces/SelectOption';

export interface ISongRecordingModalArtistsProps {
  actionIsRunning: boolean;
  artists: ISongRecordingArtist[];
  fieldOptions: { [field: string]: ISelectOption[] } | undefined;
  formErrors: any;
  onArtistsChange: (artists: ISongRecordingArtist[]) => void;
}

export const SongRecordingModalArtists: React.FC<ISongRecordingModalArtistsProps> = ({
  artists,
  actionIsRunning,
  fieldOptions,
  formErrors,
  onArtistsChange,
}) => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState<string>('de');

  useEffect(() => {
    if (i18n.language === 'de-DE') {
      setLocale('de');
    } else {
      setLocale('en');
    }
  }, [i18n.language]);

  const [openArtistsModal, setOpenArtistsModal] = useState<boolean>(false);
  const [editArtist, setEditArtist] = useState<ISongRecordingArtist>(newSongRecordingArtist);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});

  const hasErrors = (): boolean => {
    const errors = validateRequiredFields(songRecordingArtistRequiredFields, editArtist, t);
    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleChange = (value: any, key: string) => {
    setEditArtist((editArtist: ISongRecordingArtist) => ({ ...editArtist, [key]: value }));
    if (value && errors[key]) {
      setErrors((errors: { [field: string]: string }) => {
        const nextErrors = { ...errors };
        delete nextErrors[key];
        return nextErrors;
      });
    }
  };

  const handleAddArtist = () => {
    const newArtist = newSongRecordingArtist;
    newArtist.roba_artistid = uuidv4();
    setEditArtist(newArtist);
    setOpenArtistsModal(true);
  };

  const handleEditArtist = (artist: ISongRecordingArtist) => {
    setEditArtist(artist);
    setOpenArtistsModal(true);
  };

  const handleDeleteArtist = (artistId: string) => {
    const nextArtists = artists.map((artist: ISongRecordingArtist) =>
      artist.roba_artistid === artistId ? { ...artist, deleted: true } : artist,
    );
    onArtistsChange(nextArtists);
  };

  const handleSave = () => {
    if (!hasErrors()) {
      let nextArtists;
      if (editArtist.added && !editArtist.new) {
        nextArtists = [...artists, { ...editArtist, added: false }];
      } else {
        nextArtists = artists.map((artist: ISongRecordingArtist) =>
          artist.roba_artistid === editArtist.roba_artistid
            ? { ...editArtist, edited: true }
            : artist,
        );
      }
      onArtistsChange(nextArtists);
      handleClose();
    }
  };

  const handleClose = () => {
    setOpenArtistsModal(false);
  };

  const getArtistRoleTranslation = (roleId: string) => {
    const option: any = fieldOptions?.roba_artist_type?.find(
      (option: ISelectOption) => option.value === parseInt(roleId),
    );
    return option ? option.labels[locale] : '';
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        mt: 3,
      }}
    >
      <Grid item md={12} xs={12}>
        <Typography variant="h6">Artist information</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.Performer')}
              </TableCell>
              <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.PerformerRole')}
              </TableCell>
              <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.SpotifyArtistID')}
              </TableCell>
              <TableCell
                style={{ minWidth: '50px', maxWidth: '50px', overflow: 'hidden' }}
                align="right"
              >
                {t('Dashboard.Common.Edit')}
              </TableCell>
              <TableCell
                style={{ minWidth: '50px', maxWidth: '50px', overflow: 'hidden' }}
                align="right"
              >
                {t('Dashboard.Common.Delete')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="artists-table">
            {artists.map((artist: ISongRecordingArtist) => (
              <TableRow hover style={{ display: artist.deleted ? 'none' : 'table-row' }}>
                <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
                  {artist.roba_name}
                </TableCell>
                <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
                  {artist.roba_type ? getArtistRoleTranslation(artist.roba_type) : ''}
                </TableCell>
                <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
                  {artist.roba_spotifyid}
                </TableCell>
                <TableCell
                  style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}
                  align="right"
                >
                  <IconButton disabled={actionIsRunning}>
                    <EditIcon
                      onClick={() => handleEditArtist(artist)}
                      style={{ cursor: 'pointer' }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell
                  style={{ minWidth: '50px', maxWidth: '50px', overflow: 'hidden' }}
                  align="right"
                >
                  <IconButton
                    disabled={actionIsRunning}
                    onClick={() => handleDeleteArtist(artist.roba_artistid as string)}
                  >
                    <DeleteOutlineIcon style={{ cursor: 'pointer' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow hover style={{ display: formErrors?.artists ? 'table-row' : 'none' }}>
              <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
                {formErrors?.artists?.roba_name && (
                  <FormHelperText error>{t('MasterDataForm.MandatoryField')}</FormHelperText>
                )}
              </TableCell>
              <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
                {formErrors?.artists?.roba_type && (
                  <FormHelperText error>{t('MasterDataForm.MandatoryField')}</FormHelperText>
                )}
              </TableCell>
              <TableCell
                style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}
                align="right"
              />
              <TableCell
                style={{ minWidth: '50px', maxWidth: '50px', overflow: 'hidden' }}
                align="right"
              />
            </TableRow>
          </TableBody>
        </Table>
        <Box sx={{ mt: 1 }}>
          <Button disabled={actionIsRunning} variant="outlined" onClick={handleAddArtist}>
            {t('Dashboard.Songs.AddPerformer')}
          </Button>
        </Box>
      </Grid>

      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setOpenArtistsModal(false)}
        open={!!openArtistsModal}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            display: 'flex',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}
        >
          <Typography variant="h6">
            {editArtist.added
              ? t('Dashboard.Songs.AddPerformer')
              : t('Dashboard.Songs.EditPerformer')}
          </Typography>
          <IconButton color="inherit" onClick={() => setOpenArtistsModal(false)}>
            <XIcon fontSize="small" />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            p: 5,
          }}
        >
          <Grid container spacing={3}>
            <Grid item md={9} xs={12}>
              <TextField
                fullWidth
                autoComplete="off"
                variant="filled"
                required
                label={t('Dashboard.Songs.Performer')}
                name="roba_name"
                value={editArtist?.roba_name}
                helperText={errors.roba_name}
                error={!!errors.roba_name}
                disabled={actionIsRunning}
                onChange={(e: any) => handleChange(e.target.value, 'roba_name')}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <FormControl fullWidth variant="filled">
                <InputLabel>{t('Dashboard.Songs.PerformerRole')}</InputLabel>
                <Select
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.PerformerRole')}
                  name="roba_type"
                  value={editArtist.roba_type}
                  error={!!errors.roba_type}
                  disabled={actionIsRunning}
                  onChange={(e: any) => handleChange(e.target.value, 'roba_type')}
                >
                  {!!editArtist.roba_type && <MenuItem value="">-</MenuItem>}
                  {fieldOptions?.roba_artist_type?.map((fieldOption: ISelectOption) => (
                    <MenuItem key={fieldOption.value} value={fieldOption.value}>
                      {fieldOption.labels[i18n.language.includes('en') ? 'en' : 'de']}
                    </MenuItem>
                  ))}
                </Select>
                {errors.roba_type && <FormHelperText error>{errors.roba_type}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                fullWidth
                autoComplete="off"
                variant="filled"
                label={t('Dashboard.Songs.SpotifyArtistID')}
                name="roba_spotifyid"
                value={editArtist.roba_spotifyid}
                helperText={errors.roba_spotifyid}
                error={!!errors.roba_spotifyid}
                disabled={actionIsRunning}
                onChange={(e: any) => handleChange(e.target.value, 'roba_spotifyid')}
              />
            </Grid>
            {/*
            <Grid item md={9} xs={12}>
              <TextField
                fullWidth
                autoComplete="off"
                variant="filled"
                label={t('Dashboard.Songs.AppleMusicArtistID')}
                name="roba_appleid"
                value={editArtist.roba_appleid}
                helperText={errors.roba_appleid}
                error={!!errors.roba_appleid}
                disabled={actionIsRunning}
                onChange={(e: any) => handleChange(e.target.value, 'roba_appleid')}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                fullWidth
                autoComplete="off"
                variant="filled"
                label={t('Dashboard.Songs.ArtistISNIID')}
                name="roba_isni"
                value={editArtist.roba_isni}
                helperText={errors.roba_isni}
                error={!!errors.roba_isni}
                disabled={actionIsRunning}
                onChange={(e: any) => handleChange(e.target.value, 'roba_isni')}
              />
            </Grid>
                  */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            sx={{ m: 1 }}
            variant="contained"
            disabled={actionIsRunning}
            onClick={handleSave}
          >
            {t('Dashboard.Common.Save')}
          </Button>
          <Button
            type="submit"
            sx={{ m: 1 }}
            variant="outlined"
            disabled={actionIsRunning}
            onClick={handleClose}
          >
            {t('Dashboard.Common.Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
