import React from 'react';
import {
  Alert,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import _ from 'lodash';
import { InfoOutlined } from '@mui/icons-material';
import {
  ISong,
  ISongAlternativeTitle,
  ISongRecording,
  newSongAlternativeTitle,
} from '../../../interfaces/Song';
import { ISongFormStep, ISongFormSteps } from './SongForm';
import { ISpotifySearchSuggestion, SpotifySearch } from '../../lib/SpotifySearch';

export interface ISongFormSongDataProps {
  formData: ISong;
  tmpRecording: ISongRecording | undefined;
  createTmpRecording: boolean;
  titles: ISongAlternativeTitle[];
  actionIsRunning: boolean;
  actionIsRunningText: string;
  errors: { [field: string]: string };
  hasChanged: boolean;
  handleChange: any;
  steps: ISongFormSteps;
  onTitlesChange: (titles: ISongAlternativeTitle[]) => void;
  onErrorsChange: (errors: { [field: string]: string }) => void;
  hasErrors: () => boolean;
  onDelete: () => void;
  onSubmit: () => void;
  onStepChange: (step: ISongFormStep) => void;
  onSpotifySelect: (suggestion: ISpotifySearchSuggestion) => void;
  onCreateTmpRecording: (createTmpRecording: boolean) => void;
}

export const SongFormSongData: React.FC<ISongFormSongDataProps> = ({
  formData,
  tmpRecording,
  createTmpRecording,
  titles,
  actionIsRunning,
  actionIsRunningText,
  errors,
  hasChanged,
  handleChange,
  steps,
  onTitlesChange,
  onErrorsChange,
  hasErrors,
  onDelete,
  onSubmit,
  onStepChange,
  onSpotifySelect,
  onCreateTmpRecording,
}) => {
  const { t } = useTranslation();

  const handleNext = () => {
    if (!formData.roba_songid || hasChanged) {
      onSubmit();
      return;
    }
    onStepChange(steps.writers);
  };

  const addTitle = () => {
    if (!hasErrors()) {
      const nextTitles = [...titles, newSongAlternativeTitle];
      onTitlesChange(nextTitles);
    }
  };

  const handleTitleChange = (value: any, index: number, toDelete = false) => {
    const nextTitles = titles.map((title: ISongAlternativeTitle, i: number) => {
      if (i === index) {
        return {
          ...title,
          roba_title: value,
          edited: true,
          deleted: toDelete,
        };
      }
      return title;
    });
    onTitlesChange(nextTitles);
    if (value && titles.length - 1 === index) {
      const nextErrors = { ...errors };
      delete nextErrors.titles;
      onErrorsChange(nextErrors);
    }
  };

  return (
    <>
      <CardHeader title={t('Dashboard.Songs.SongData')} />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              autoComplete="off"
              variant="filled"
              label={t('Dashboard.Songs.Title')}
              name="roba_title"
              required
              value={formData.roba_title}
              disabled={actionIsRunning}
              helperText={errors.roba_title}
              error={!!errors.roba_title}
              onChange={(e: any) => handleChange(e.target.value, 'roba_title')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              autoComplete="off"
              type="number"
              inputProps={{
                min: 0,
              }}
              sx={{
                width: '80px',
              }}
              variant="filled"
              label={t('Dashboard.Songs.RuntimeMinutes')}
              name="roba_durationmins"
              value={formData.roba_durationmins}
              disabled={actionIsRunning}
              helperText={errors.roba_durationmins}
              error={!!errors.roba_durationmins}
              onChange={(e: any) => {
                if (e.target.value?.length === 4) {
                  return;
                }
                if (parseInt(e.target.value) < 0) {
                  handleChange(0, 'roba_durationmins');
                  return;
                }
                handleChange(e.target.value, 'roba_durationmins');
              }}
              onBlur={(e: any) => {
                if (e.target.value?.length === 1) {
                  handleChange(`0${e.target.value}`, 'roba_durationmins');
                }
              }}
            />
            <span style={{ margin: '10px', position: 'relative', top: '20px' }}>:</span>
            <TextField
              autoComplete="off"
              type="number"
              inputProps={{
                min: 0,
              }}
              sx={{
                width: '100px',
              }}
              variant="filled"
              label={t('Dashboard.Songs.RuntimeSeconds')}
              name="roba_durationsecs"
              value={formData.roba_durationsecs}
              disabled={actionIsRunning}
              helperText={errors.roba_durationsecs}
              error={!!errors.roba_durationsecs}
              onChange={(e: any) => {
                if (e.target.value?.length === 3) {
                  return;
                }
                if (parseInt(e.target.value) < 0) {
                  handleChange(0, 'roba_durationsecs');
                  return;
                }
                if (parseInt(e.target.value) >= 60) {
                  handleChange(59, 'roba_durationsecs');
                  return;
                }
                handleChange(e.target.value, 'roba_durationsecs');
              }}
              onBlur={(e: any) => {
                if (e.target.value?.length === 1) {
                  handleChange(`0${e.target.value}`, 'roba_durationsecs');
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{
            mt: 1,
          }}
        >
          <Grid item md={6} xs={12}>
            <SpotifySearch
              disabled={actionIsRunning}
              label={t('Dashboard.Songs.ImportFromSpotify')}
              onSelect={onSpotifySelect}
            />
          </Grid>
          {tmpRecording && (
            <Grid item md={12} xs={12}>
              <Alert
                severity="info"
                action={
                  <Switch
                    checked={createTmpRecording}
                    onChange={(_, checked: boolean) => onCreateTmpRecording(checked)}
                  />
                }
              >
                {t('Dashboard.Songs.CreateRecordingAutomatically').replace(
                  '{{Recording}}',
                  tmpRecording.roba_title,
                )}
              </Alert>
            </Grid>
          )}
          {/*
        <Grid item md={6} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="roba_has_text" checked={formData.roba_has_text} />}
              label="Beeinhaltet Text"
              onChange={(e: any) => handleChange(e.target.checked, 'roba_has_text')}
            />
            {errors.roba_has_text && (
              <FormHelperText error>{errors.roba_has_text}</FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="roba_hassamples" checked={formData.roba_hassamples} />}
              label={t('Dashboard.Songs.IncludesSamples')}
              onChange={(e: any) => handleChange(e.target.checked, 'roba_hassamples')}
            />
            {errors.roba_hassamples && (
              <FormHelperText error>{errors.roba_hassamples}</FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="roba_sampleslicensed"
                  checked={formData.roba_sampleslicensed}
                />
              }
              label={t('Dashboard.Songs.RightsSamplesAvailable')}
              onChange={(e: any) => handleChange(e.target.checked, 'roba_sampleslicensed')}
            />
            {errors.roba_sampleslicensed && (
              <FormHelperText error>{errors.roba_sampleslicensed}</FormHelperText>
            )}
          </FormGroup>
        </Grid>
        */}
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <h3 style={{ fontSize: '1.0909rem', marginTop: 0 }}>
          {t('Dashboard.Songs.AlternateTitles')}
          <Tooltip
            title={<p style={{ fontSize: '16px' }}>{t('Dashboard.Songs.AlternateTitlesInfo')}</p>}
          >
            <IconButton>
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </h3>
        {titles.map((title: ISongAlternativeTitle, index: number) => (
          <TextField
            fullWidth
            autoComplete="off"
            style={{ marginBottom: '10px', display: title.deleted ? 'none' : 'block' }}
            variant="filled"
            label={t('Dashboard.Songs.EnterAlternateTitles')}
            required
            value={title.roba_title}
            helperText={titles.length - 1 === index && errors.titles ? errors.titles : ''}
            error={!!(titles.length - 1 === index && errors.titles)}
            disabled={actionIsRunning}
            onChange={(e: any) => handleTitleChange(e.target.value, index)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <DeleteOutlineIcon
                    onClick={() => handleTitleChange(title.roba_title, index, true)}
                    style={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
          />
        ))}
        <Button
          style={{ marginTop: '8px' }}
          disabled={actionIsRunning}
          onClick={addTitle}
          variant="outlined"
        >
          {t('Dashboard.Songs.AddAlternateTitles')}
        </Button>
      </CardContent>
      <CardActions
        sx={{
          flexWrap: 'wrap',
          m: -1,
          position: 'relative',
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            mt: 1,
          }}
        >
          <Grid item md={6} xs={12}>
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning}
              onClick={() => handleNext()}
            >
              {t('Dashboard.Songs.SaveAndNext')}
            </Button>
          </Grid>
          <Grid item md={6} xs={12} style={{ textAlign: 'right' }}>
            <Link to="/dashboard/songs" style={{ textDecoration: 'none' }}>
              <Button component="a" variant="outlined" disabled={actionIsRunning}>
                {t('Dashboard.Songs.Cancel')}
              </Button>
            </Link>
            {formData?.roba_songid && (
              <Button sx={{ m: 1 }} color="error" disabled={actionIsRunning} onClick={onDelete}>
                {t('Dashboard.Songs.DeleteSong')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardActions>

      {actionIsRunning && (
        <CardContent style={{ paddingTop: '0' }}>
          <CircularProgress size={24} />
          <Typography
            style={{
              display: 'inline-block',
              marginLeft: '12px',
              position: 'relative',
              top: '-6px',
            }}
          >
            {actionIsRunningText}
          </Typography>
        </CardContent>
      )}
    </>
  );
};
