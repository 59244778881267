export interface ILoginFormData {
  roba_accountname: string;
  roba_hash: string;
}

export const newLoginFormData = {
  roba_accountname: '',
  roba_hash: '',
};

export const loginFormRequiredFields = ['roba_accountname', 'roba_hash'];
