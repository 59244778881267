import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { ListChecks as ListChecksIcon } from '@phosphor-icons/react/dist/ssr/ListChecks';
import { Users as UsersIcon } from '@phosphor-icons/react/dist/ssr/Users';
import { Warning as WarningIcon } from '@phosphor-icons/react/dist/ssr/Warning';
import dayjs from 'dayjs';
import { ArrowRight as ArrowRightIcon } from '@phosphor-icons/react/dist/ssr/ArrowRight';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import QuizIcon from '@mui/icons-material/Quiz';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../provider/AuthProvider';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';
import styles from './StartPage.module.scss';
import { ESongState, ISong } from '../../../interfaces/Song';
import { getSocialPosts, getSongs, getWriterList } from '../../../services/httpService';
import { StartPageLastAddedSongs } from './StartPageLastAddedSongs';
import { StartPageLastAddedWriters } from './StartPageLastAddedWriters';
import { Summary } from '../../lib/Summary';
import { HelperWidget } from '../../lib/HelperWidget';
import { PostCard } from '../../lib/PostCard';
import { IWriter } from '../../../interfaces/Writer';
import { IAgreement } from '../../../interfaces/MyData';
import { Post } from '../../../interfaces/Post';

export const StartPage: React.FC = () => {
  const { t } = useTranslation();
  const { selectedUser, myData } = useAuth();
  const navigate = useNavigate();

  const [songs, setSongs] = useState<ISong[]>([]);
  const [writers, setWriters] = useState<IWriter[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingWriters, setIsLoadingWriters] = useState<boolean>(true);

  const fetchSongs = async () => {
    setIsLoading(true);
    try {
      const res = await getSongs(selectedUser?.id as string);
      const output = JSON.parse(res.output);
      const songs = output?.value ? output?.value : [];
      setSongs(songs);
    } catch (e) {
      toast.error(t('Dashboard.Songs.SongGetError'));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchWriters = async () => {
    setIsLoadingWriters(true);
    try {
      const agreementIds = myData?.agreements.map(
        (agreement: IAgreement) => agreement.roba_agreementid,
      );
      const writers: any = await getWriterList(agreementIds as string[]);
      setWriters(writers);
    } catch (e) {
      toast.error(t('Dashboard.Writers.WriterGetError'));
    } finally {
      setIsLoadingWriters(false);
    }
  };

  const fetchInstagramPosts = async () => {
    try {
      const posts = await getSocialPosts();
      setPosts(posts.data.slice(0, 4));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchInstagramPosts();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetchSongs();
    }
    if (selectedUser && myData?.agreementIpiNumbers?.length) {
      fetchWriters();
    }
  }, [selectedUser, myData]);

  if (!selectedUser || isLoading || isLoadingWriters) {
    return (
      <Box
        sx={{
          mt: 20,
          mb: 30,
          textAlign: 'center',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" className={styles.startPage}>
      <Box>
        <Typography
          variant="h1"
          style={{
            maxWidth: '500px',
            marginBottom: '32px',
            fontSize: '36px',
            textTransform: 'none',
          }}
        >
          {t('Dashboard.Welcome')}, {myData?.firstname}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Summary
            amount={songs.filter((song: ISong) => song.statuscode === ESongState.Submitted).length}
            icon={ListChecksIcon}
            title={t('Dashboard.CompletedWorks')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Summary
            amount={songs.filter((song: ISong) => song.statuscode !== ESongState.Submitted).length}
            icon={WarningIcon}
            title={t('Dashboard.IncompletedWorks')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Summary
            amount={writers.length}
            icon={UsersIcon}
            title={t('Dashboard.ControlledWriters')}
          />
        </Grid>
        {/*
        <Grid item md={8} xs={12}>
          <AppUsage
            data={[
              { name: 'Jan', v1: 36, v2: 19 },
              { name: 'Feb', v1: 45, v2: 23 },
              { name: 'Mar', v1: 26, v2: 12 },
              { name: 'Apr', v1: 39, v2: 20 },
              { name: 'May', v1: 26, v2: 12 },
              { name: 'Jun', v1: 42, v2: 31 },
              { name: 'Jul', v1: 38, v2: 19 },
              { name: 'Aug', v1: 39, v2: 20 },
              { name: 'Sep', v1: 37, v2: 18 },
              { name: 'Oct', v1: 41, v2: 22 },
              { name: 'Nov', v1: 45, v2: 24 },
              { name: 'Dec', v1: 23, v2: 17 },
            ]}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Subscriptions
            subscriptions={[
              {
                id: 'supabase',
                title: 'Supabase',
                icon: '/assets/company-avatar-5.png',
                costs: '$599',
                billingCycle: 'year',
                status: 'paid',
              },
              {
                id: 'vercel',
                title: 'Vercel',
                icon: '/assets/company-avatar-4.png',
                costs: '$20',
                billingCycle: 'month',
                status: 'expiring',
              },
              {
                id: 'auth0',
                title: 'Auth0',
                icon: '/assets/company-avatar-3.png',
                costs: '$20-80',
                billingCycle: 'month',
                status: 'canceled',
              },
              {
                id: 'google_cloud',
                title: 'Google Cloud',
                icon: '/assets/company-avatar-2.png',
                costs: '$100-200',
                billingCycle: 'month',
                status: 'paid',
              },
            ]}
          />
        </Grid>
          */}
        <Grid item md={6} xs={12}>
          <StartPageLastAddedSongs songs={songs.slice(0, 10)} />
        </Grid>
        <Grid item md={6} xs={12}>
          <StartPageLastAddedWriters writers={writers.slice(0, 10)} />
        </Grid>
        {!!posts.length &&
          posts.map(post => (
            <Grid key={post.id} item md={3} xs={12}>
              <PostCard post={post} />
            </Grid>
          ))}
        {/*
        <Grid item md={6} xs={12}>
          <Events
            events={[
              {
                id: 'EV-004',
                title: 'Meeting with partners',
                description: '17:00 to 18:00',
                createdAt: dayjs().add(1, 'day').toDate(),
              },
              {
                id: 'EV-003',
                title: 'Interview with Jonas',
                description: '15:30 to 16:45',
                createdAt: dayjs().add(4, 'day').toDate(),
              },
              {
                id: 'EV-002',
                title: "Doctor's appointment",
                description: '12:30 to 15:30',
                createdAt: dayjs().add(4, 'day').toDate(),
              },
              {
                id: 'EV-001',
                title: 'Weekly meeting',
                description: '09:00 to 09:30',
                createdAt: dayjs().add(7, 'day').toDate(),
              },
            ]}
          />
        </Grid>
          */}
        <Grid item md={4} xs={12}>
          <HelperWidget
            action={
              <Button
                color="secondary"
                endIcon={<ArrowRightIcon />}
                size="small"
                onClick={() => navigate('/dashboard/forms')}
              >
                {t('Dashboard.Sidebar.Forms')}
              </Button>
            }
            description={t('Dashboard.FormsInfo')}
            icon={<InsertDriveFileOutlinedIcon />}
            label={t('Dashboard.Sidebar.Forms')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <HelperWidget
            action={
              <Button
                color="secondary"
                endIcon={<ArrowRightIcon />}
                size="small"
                onClick={() => navigate('/dashboard/help/contact')}
              >
                {t('Dashboard.Sidebar.HelpCenter')}
              </Button>
            }
            description={t('Dashboard.HelpInfo')}
            icon={<EmailIcon />}
            label={t('Dashboard.ContactForm')}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <HelperWidget
            action={
              <Button
                color="secondary"
                endIcon={<ArrowRightIcon />}
                size="small"
                onClick={() => navigate('/dashboard/help/faq')}
              >
                {t('Dashboard.Sidebar.FAQ')}
              </Button>
            }
            description={t('Dashboard.FAQInfo')}
            icon={<QuizIcon />}
            label={t('Dashboard.Sidebar.FAQ')}
          />
        </Grid>
      </Grid>
      {/*
      <StartPageSongs songsCount={itemsCount} lastAddedSong={songs.length ? songs[0] : undefined} />
      <StartPageNews />
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <StartPageLastAddedSongs songs={songs} />
        </Grid>
        <Grid item md={6} xs={12}>
          <StartPageLastAddedWriters />
        </Grid>
      </Grid>
        */}
    </Container>
  );
};
