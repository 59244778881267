import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Card,
  InputAdornment,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import QueueIcon from '@mui/icons-material/Queue';
import styles from './SongList.module.scss';
import { Search as SearchIcon } from '../icons/search';
import { Scrollbar } from '../template/scrollbar';
import { ESongState, getSongStates, newSongRecording } from '../../interfaces/Song';
import { useAuth } from '../provider/AuthProvider';
import {
  copySong,
  createSongRecording,
  deleteSong,
  getSongs,
  getWriters,
  recordingFieldOptions,
  uploadWorks,
} from '../../services/httpService';
import loadingSpinner from '../../assets/images/loadingSpinner.svg';
import { SongFormTab } from '../forms/SongForm/SongForm';
import { ISortingOption, useList } from '../../hooks/useList';
import { getFormattedDate } from '../../helper/DateHelper';
import { IListColumn } from '../../interfaces/ListColumn';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { ChevronLeft as ChevronLeftIcon } from '../icons/chevron-left';
import { SongListDetails } from './SongListDetails';
import { IWriter } from '../../interfaces/Writer';
import { ISelectOption } from '../../interfaces/SelectOption';
import { DeleteDialog } from '../lib/DeleteDialog';
import { CopySongDialog } from '../lib/CopySongDialog';
import { ISpotifySearchSuggestion } from '../lib/SpotifySearch';
import { MultipleWorksDialog } from '../lib/MultipleWorksDialog';

const getSortOptions = (t: any): ISortingOption[] => [
  {
    label: t('Dashboard.Lists.CreationDateLatest'),
    value: 'createdon_desc',
  },
  {
    label: t('Dashboard.Lists.CreationDateOldest'),
    value: 'createdon_asc',
  },
  {
    label: t('Dashboard.Lists.TitleAscending'),
    value: 'roba_title_asc',
  },
  {
    label: t('Dashboard.Lists.TitelDescending'),
    value: 'roba_title_desc',
  },
];

const getColumns = (t: any): IListColumn[] => [
  {
    key: 'title',
    title: t('Dashboard.Songs.Title'),
    disabled: true,
  },
  {
    key: 'authors',
    title: t('Dashboard.Songs.SongWriter'),
  },
  {
    key: 'duration',
    title: t('Dashboard.Songs.Duration'),
  },
  {
    key: 'creationDate',
    title: t('Dashboard.Lists.CreationDate'),
  },
];

export const SongList = () => {
  const { t } = useTranslation();
  const { selectedUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [writers, setWriters] = useState<IWriter[] | undefined>(undefined);
  const [fieldOptions, setFieldOptions] = useState<
    { [field: string]: ISelectOption[] } | undefined
  >(undefined);
  const [songs, setSongs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string>('');
  const [agreementFilter, setAgreementFilter] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [expandedSongs, setExpandedSongs] = useState<string[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [songToDelete, setSongToDelete] = useState<string | undefined>(undefined);
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);
  const [songToCopy, setSongToCopy] = useState<string | undefined>(undefined);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const [multiWorksDialogOpen, setMultiWorksDialogOpen] = useState<boolean>(false);

  const sortOptions = useMemo(() => getSortOptions(t), [t]);
  const columns = useMemo(() => getColumns(t), [t]);
  const agreements = useMemo(() => {
    if (!songs.length) {
      return [];
    }
    const agreements = (songs || []).reduce((agreements: any, song: any) => {
      song.agreements.forEach((a: any) => {
        if (
          a.roba_agreementid !== 'without' &&
          !agreements.find((agreement: any) => agreement.roba_agreementid === a.roba_agreementid)
        ) {
          agreements.push(a);
        }
      });
      return agreements;
    }, []);
    agreements.sort((a: any, b: any) => a.roba_name.localeCompare(b.roba_name));
    agreements.push({
      roba_agreementid: 'without',
      roba_name: t('Dashboard.Songs.WithoutAgreement'),
    });
    return agreements;
  }, [songs]);

  const {
    search,
    itemsPerPage,
    page,
    sorting,
    itemsCount,
    setSearch,
    setItemsPerPage,
    setPage,
    setSorting,
    setItemsCount,
  } = useList(sortOptions[0]);

  const paginatedSongs = useMemo(() => {
    let filteredItems = [...songs];

    if (search) {
      filteredItems = filteredItems.filter(song =>
        song.roba_title?.toLowerCase().includes(search.toLowerCase()),
      );
    }

    filteredItems = filteredItems.filter(song =>
      agreementFilter.some((agreement: string) =>
        song.agreements.find((a: any) => a.roba_agreementid === agreement),
      ),
    );

    filteredItems.sort((a, b) => {
      if (sorting === 'roba_title_asc') {
        return (a.roba_title || '').localeCompare(b.roba_title || '');
      }
      if (sorting === 'roba_title_desc') {
        return (b.roba_title || '').localeCompare(a.roba_title || '');
      }
      if (sorting === 'createdon_asc' || sorting === 'createdon_desc') {
        const dateA = a.createdon ? new Date(a.createdon).getTime() : 0;
        const dateB = b.createdon ? new Date(b.createdon).getTime() : 0;
        return sorting === 'createdon_asc' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });

    setItemsCount(filteredItems.length);

    return filteredItems.slice(page * itemsPerPage, (page + 1) * itemsPerPage);
  }, [songs, page, itemsPerPage, sorting, search, agreementFilter]);

  const fetchSongs = async () => {
    setIsLoading(true);
    try {
      const res = await getSongs(selectedUser?.id as string);
      const output = JSON.parse(res.output);
      let songs = output?.value ? output?.value : [];
      songs = songs.map((song: any) => ({
        ...song,
        agreements: song.agreements.length
          ? song.agreements
          : [
              {
                roba_agreementid: 'without',
              },
            ],
      }));
      setSongs(songs);
      setItemsCount(songs.length);
    } catch (e) {
      toast.error(t('Dashboard.Songs.SongGetError'));
    } finally {
      setIsLoading(false);
    }
  };

  const debounceSearchChange = useCallback(
    debounce((search: string) => {
      setSearch(search);
      setPage(0);
    }, 500),
    [],
  );

  const handleSearchChange = (search: string) => {
    setSearchInput(search);
    debounceSearchChange(search);
  };

  const handleSelectedColumnsChange = (value: string[]) => {
    let nextValue;
    if (value.length) {
      nextValue = [...value];
    } else {
      nextValue = columns.map((column: IListColumn) => column.key);
    }
    setSelectedColumns(nextValue);
    localStorage.setItem('ROBAPortal_SongListColumns', nextValue.join(','));
  };

  const handleExpandedSongsChange = (songId: string) => {
    let nextExpandedSongs;
    if (!expandedSongs.includes(songId)) {
      nextExpandedSongs = [...expandedSongs, songId];
    } else {
      nextExpandedSongs = expandedSongs.filter((sId: string) => sId !== songId);
    }
    setExpandedSongs(nextExpandedSongs);
  };

  const _getWriters = async () => {
    const writers = await getWriters();
    setWriters(writers);
  };

  const getFieldOptions = async () => {
    const fieldOptions = await recordingFieldOptions();
    setFieldOptions(fieldOptions);
  };

  const handleDelete = async () => {
    setActionIsRunning(true);
    try {
      if (songToDelete) {
        await deleteSong(songToDelete);
        fetchSongs();
        toast.success(t('Dashboard.Songs.SongDeleteSuccess'));
      }
    } catch (e) {
      toast.error(t('Dashboard.Songs.SongDeleteError'));
    } finally {
      setSongToDelete(undefined);
      setActionIsRunning(false);
    }
  };

  const handleAgreementsFilterChange = (value: string[]) => {
    let nextAgreementFilters = value;
    if (value.includes('all')) {
      nextAgreementFilters = agreements.map((agreement: any) => agreement.roba_agreementid);
    }
    setAgreementFilter(nextAgreementFilters);
  };

  const handleCopy = (songId: string) => {
    setSongToCopy(songId);
    setCopyDialogOpen(true);
  };

  const copy = async (newTitle: string, spotifySelected: ISpotifySearchSuggestion | undefined) => {
    try {
      setActionIsRunning(true);
      const copiedSong = await copySong(
        selectedUser?.id as string,
        songToCopy as string,
        newTitle || (spotifySelected?.title as string),
      );
      if (spotifySelected) {
        const albumTypeMap: any = {
          album: '722040000',
          single: '722040001',
          compilation: '722040002',
        };
        const recording = { ...newSongRecording };
        recording.roba_title = spotifySelected.title;
        recording.roba_durationmins = spotifySelected.durationmins;
        recording.roba_durationsecs = spotifySelected.durationsecs;
        recording.roba_isrc = spotifySelected.isrc;
        recording.roba_album = spotifySelected.album;
        recording.roba_album_releasedon = spotifySelected.releaseDate;
        recording.roba_album_type =
          spotifySelected.albumType && albumTypeMap[spotifySelected.albumType]
            ? albumTypeMap[spotifySelected.albumType]
            : null;
        recording.roba_spotifyid = spotifySelected.id;
        recording.roba_ismainrecording = true;
        recording.roba_imageurl = spotifySelected.image;
        recording.artists = (spotifySelected.artists || []).map((artist: any) => ({
          roba_artistid: uuidv4(),
          roba_name: artist.name,
          roba_spotifyid: artist.id,
          added: true,
        }));
        recording.roba_songid = copiedSong.data.roba_songid as string;
        recording.artists = await createSongRecording(recording);
      }
      toast.success(t('Dashboard.Songs.CopySucces'));
      setSongToCopy(undefined);
      setCopyDialogOpen(false);
      navigate(`/dashboard/songs/edit/${copiedSong.data.roba_songid}/${SongFormTab.Song}`);
    } catch {
      toast.error(t('Dashboard.Songs.CopyError'));
    } finally {
      setActionIsRunning(false);
    }
  };

  const uploadMultipleWorks = async (file: File) => {
    try {
      setActionIsRunning(true);
      await uploadWorks({
        userId: selectedUser?.id as string,
        file,
      });
      toast.success(t('Dashboard.Songs.UploadSucces'));
      setMultiWorksDialogOpen(false);
    } catch {
      toast.error(t('Dashboard.Songs.UploadError'));
    } finally {
      setActionIsRunning(false);
    }
  };

  useEffect(() => {
    let initSelectedColumns;
    const columnsFromLS = localStorage.getItem('ROBAPortal_SongListColumns');
    if (columnsFromLS) {
      const split = localStorage.getItem('ROBAPortal_SongListColumns');
      initSelectedColumns = columns.filter((column: IListColumn) => split?.includes(column.key));
    } else {
      initSelectedColumns = [...columns];
    }
    setSelectedColumns(initSelectedColumns.map((column: IListColumn) => column.key));

    const urlSearch = searchParams.get('search') || '';
    const urlPage = parseInt(searchParams.get('page') || '0', 10);
    const urlSorting = searchParams.get('sorting') || sortOptions[0].value;
    const itemsPerPage = parseInt(searchParams.get('itemsPerPage') || '10', 10);
    setSearch(urlSearch);
    setPage(urlPage);
    setSorting(urlSorting);
    setSearchInput(urlSearch);
    setItemsPerPage(itemsPerPage);
  }, []);

  useEffect(() => {
    if (agreements && agreements.length) {
      setAgreementFilter(agreements.map((agreement: any) => agreement.roba_agreementid));
    }
  }, [agreements]);

  useEffect(() => {
    if (selectedUser) {
      _getWriters();
      getFieldOptions();
      fetchSongs();
    }
  }, [selectedUser]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (search) params.append('search', search);
    if (page !== 0) params.append('page', page.toString());
    if (sorting !== sortOptions[0].value) params.append('sorting', sorting);
    if (itemsPerPage !== 10) params.append('itemsPerPage', itemsPerPage.toString());
    if (agreements && agreements.length && agreements.length !== agreementFilter.length) {
      params.append('agreements', agreementFilter.join(';'));
    }
    navigate(`?${params.toString()}`, { replace: true });
  }, [search, page, sorting, itemsPerPage, agreements, agreementFilter]);

  useEffect(() => {
    setDeleteDialogOpen(!!songToDelete);
  }, [songToDelete]);

  if (!selectedUser || !writers || !fieldOptions) {
    return (
      <Box
        sx={{
          mt: 20,
          mb: 30,
          textAlign: 'center',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <div className={styles.songList}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 3,
        }}
      >
        <Box
          sx={{
            m: -1,
            mb: 1,
          }}
        >
          <Link to="/dashboard/songs/new" style={{ textDecoration: 'none' }}>
            <Button
              startIcon={<AddIcon fontSize="small" />}
              sx={{ m: 1 }}
              variant="contained"
              href="/dashboard/songs/new"
            >
              {t('Dashboard.Songs.CreateSong')}
            </Button>
          </Link>
          <Button
            startIcon={<QueueIcon fontSize="small" />}
            sx={{ m: 1 }}
            variant="contained"
            onClick={() => setMultiWorksDialogOpen(true)}
          >
            {t('Dashboard.Songs.SubmitMultipleWorks')}
          </Button>
          <Link to="/dashboard/writers" style={{ textDecoration: 'none' }}>
            <Button sx={{ m: 1 }} variant="contained" href="/dashboard/writers">
              {t('Dashboard.Writers.Writers')}
            </Button>
          </Link>
        </Box>
        <Card>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: -1,
              p: 2,
            }}
          >
            <Box
              sx={{
                m: 1,
                maxWidth: '100%',
                width: 500,
              }}
            >
              <FormControl fullWidth variant="filled">
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Dashboard.Songs.Search')}
                  value={searchInput}
                  onChange={(e: any) => handleSearchChange(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                m: 1,
                width: 240,
              }}
            >
              <FormControl fullWidth variant="filled">
                <InputLabel>{t('Dashboard.Songs.FilterByAgreement')}</InputLabel>
                <Select
                  multiple
                  value={agreementFilter}
                  onChange={(e: any) => handleAgreementsFilterChange(e.target.value)}
                  renderValue={(agreementFilter: string[]) =>
                    agreements
                      .filter((agreement: any) =>
                        agreementFilter.includes(agreement.roba_agreementid),
                      )
                      .map((agreement: any) => agreement.roba_name)
                      .join(', ')
                  }
                >
                  {agreementFilter.length !== agreements.length && (
                    <MenuItem key="all" value="all">
                      <Checkbox checked={agreementFilter.length === agreements.length} />
                      <ListItemText>{t('Dashboard.Common.All')}</ListItemText>
                    </MenuItem>
                  )}
                  {agreements.map((agreement: any) => (
                    <MenuItem key={agreement.roba_agreementid} value={agreement.roba_agreementid}>
                      <Checkbox checked={agreementFilter.includes(agreement.roba_agreementid)} />
                      <ListItemText>{agreement.roba_name}</ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                m: 1,
                width: 240,
              }}
            >
              <FormControl fullWidth variant="filled">
                <InputLabel>{t('Dashboard.Songs.SortBy')}</InputLabel>
                <Select
                  name="sort"
                  value={sorting}
                  onChange={(e: any) => {
                    setSorting(e.target.value);
                    setPage(0);
                  }}
                >
                  {sortOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                m: 1,
                width: 240,
              }}
            >
              <FormControl fullWidth variant="filled">
                <InputLabel>{t('Dashboard.Lists.Columns')}</InputLabel>
                <Select
                  multiple
                  value={selectedColumns}
                  onChange={(e: any) => handleSelectedColumnsChange(e.target.value)}
                  renderValue={(selectedColumnns: string[]) =>
                    columns
                      .filter((column: IListColumn) => selectedColumnns.includes(column.key))
                      .map((column: IListColumn) => column.title)
                      .join(', ')
                  }
                >
                  {getColumns(t)?.map((column: IListColumn) => (
                    <MenuItem key={column.key} value={column.key} disabled={column.disabled}>
                      <Checkbox checked={selectedColumns.includes(column.key)} />
                      <ListItemText>{column.title}</ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {isLoading && (
            <Box
              sx={{
                mt: 20,
                mb: 30,
                textAlign: 'center',
              }}
            >
              <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
            </Box>
          )}
          {!isLoading && (
            <>
              <Scrollbar>
                <Table sx={{ minWidth: '100%' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Dashboard.Songs.Title')}</TableCell>
                      {selectedColumns.includes('authors') && (
                        <TableCell>{t('Dashboard.Songs.SongWriter')}</TableCell>
                      )}
                      {selectedColumns.includes('duration') && (
                        <TableCell>{t('Dashboard.Songs.Duration')}</TableCell>
                      )}
                      {selectedColumns.includes('creationDate') && (
                        <TableCell>{t('Dashboard.Lists.CreationDate')}</TableCell>
                      )}
                      <TableCell>Status</TableCell>
                      <TableCell align="right">{t('Dashboard.Songs.Copy')}</TableCell>
                      <TableCell align="right">{t('Dashboard.Songs.Edit')}</TableCell>
                      <TableCell align="right">{t('Dashboard.Common.Delete')}</TableCell>
                      <TableCell align="right">Details</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedSongs.map((song: any, index: number) => {
                      const open = expandedSongs.includes(song.roba_songid as string);
                      const state = getSongStates(t).find(
                        (songState: { title: string; value: number }) =>
                          songState.value === song.statuscode,
                      );
                      return (
                        <Fragment key={index}>
                          <TableRow
                            hover
                            key={song.roba_songid}
                            className={styles.row}
                            style={{ opacity: !expandedSongs.length || open ? '1' : '0.6' }}
                          >
                            <TableCell>{song.roba_title}</TableCell>
                            {selectedColumns.includes('authors') && (
                              <TableCell>
                                {song.writers
                                  .map((writer: any) => writer.roba_name.trim())
                                  .join(', ')}
                              </TableCell>
                            )}
                            {selectedColumns.includes('duration') && (
                              <TableCell>
                                {parseInt(song.roba_durationmins) < 10
                                  ? `0${song.roba_durationmins}`
                                  : song.roba_durationmins}
                                :
                                {parseInt(song.roba_durationsecs) < 10
                                  ? `0${song.roba_durationsecs}`
                                  : song.roba_durationsecs}
                              </TableCell>
                            )}
                            {selectedColumns.includes('creationDate') && (
                              <TableCell>
                                {getFormattedDate(new Date(song.createdon as string))}
                              </TableCell>
                            )}
                            <TableCell>
                              <Chip label={state?.title} color={state?.color as any} />
                            </TableCell>
                            <TableCell align="right">
                              {song?.statuscode !== ESongState.Error && (
                                <IconButton>
                                  <ContentCopyIcon onClick={() => handleCopy(song.roba_songid)} />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Link
                                to={`/dashboard/songs/edit/${song.roba_songid}/${SongFormTab.Song}`}
                                style={{ textDecoration: 'none' }}
                              >
                                {song?.statuscode !== ESongState.Processed &&
                                  song?.statuscode !== ESongState.Error && (
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  )}
                              </Link>
                            </TableCell>
                            <TableCell align="right">
                              {song?.statuscode !== ESongState.Processed &&
                                song?.statuscode !== ESongState.Error && (
                                  <IconButton>
                                    <DeleteIcon onClick={() => setSongToDelete(song.roba_songid)} />
                                  </IconButton>
                                )}
                            </TableCell>
                            <TableCell align="right">
                              {open ? (
                                <ChevronLeftIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleExpandedSongsChange(song.roba_songid as string)
                                  }
                                />
                              ) : (
                                <ChevronDownIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleExpandedSongsChange(song.roba_songid as string)
                                  }
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          {open && (
                            <SongListDetails
                              songId={song.roba_songid as string}
                              writers={writers}
                              fieldOptions={fieldOptions}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </Scrollbar>
              <TablePagination
                component="div"
                count={itemsCount}
                onPageChange={(_, page: number) => {
                  setPage(page);
                  setExpandedSongs([]);
                }}
                onRowsPerPageChange={(e: any) => {
                  setItemsPerPage(e.target.value);
                  setPage(0);
                }}
                page={page}
                rowsPerPage={itemsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
              />
            </>
          )}
        </Card>
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        headline={t('Dashboard.Songs.ConfirmDeleteSong')}
        actionIsRunning={actionIsRunning}
        onDelete={handleDelete}
        onClose={() => setSongToDelete(undefined)}
      />
      <CopySongDialog
        open={copyDialogOpen}
        actionIsRunning={actionIsRunning}
        onCopy={copy}
        onClose={() => setCopyDialogOpen(false)}
      />
      <MultipleWorksDialog
        open={multiWorksDialogOpen}
        actionIsRunning={actionIsRunning}
        onUpload={uploadMultipleWorks}
        onClose={() => setMultiWorksDialogOpen(false)}
      />
    </div>
  );
};
