import { useState } from 'react';

export interface ISortingOption {
  label: string;
  value: string;
}

const defaults = {
  search: '',
  itemsPerPage: 10,
  page: 0,
  itemsCount: 10,
};

export const useList = (defaultSorting: ISortingOption) => {
  const [search, setSearch] = useState<string>(defaults.search);
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaults.itemsPerPage);
  const [page, setPage] = useState<number>(defaults.page);
  const [sorting, setSorting] = useState<string>(defaultSorting.value);
  const [itemsCount, setItemsCount] = useState<number>(defaults.itemsCount);
  const [pagingLinks, setPagingLinks] = useState<string[]>(['']);

  return {
    search,
    itemsPerPage,
    page,
    sorting,
    itemsCount,
    pagingLinks,
    setSearch,
    setItemsPerPage,
    setPage,
    setSorting,
    setItemsCount,
    setPagingLinks,
  };
};
