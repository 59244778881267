import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';
import { useAuth } from '../../provider/AuthProvider';
import { validateRequiredFields } from '../../../helper/ValidationHelper';
import { createIncident } from '../../../services/httpService';
import { Dropzone } from '../../lib/Dropzone';

export interface IHelpFormData {
  casetypecode: string;
  title: string;
  description: string;
  file: File | undefined;
}

const typeOptions = [
  {
    value: '722040001',
    text: 'WorkDeclaration',
  },
  {
    value: '722040002',
    text: 'AR',
  },
  {
    value: '722040003',
    text: 'Royalties',
  },
  {
    value: '722040004',
    text: 'Synch',
  },
  {
    value: '722040005',
    text: 'Reklamationen',
  },
];

export const HelpContactForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedUser } = useAuth();

  const [formData, setFormData] = useState<IHelpFormData>({
    casetypecode: '',
    title: '',
    description: '',
    file: undefined,
  });
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);

  const handleChange = (value: any, key: string) => {
    const nextFormdata = { ...formData, [key]: value };
    setFormData(nextFormdata);
    if (value && errors[key]) {
      const nextErrors = { ...errors };
      delete nextErrors[key];
      setErrors(nextErrors);
    }
  };

  const handleFileChange = (files: File[]) => {
    setFormData({ ...formData, file: files[0] });
  };

  const handleFileReject = () => {
    toast.error(t('Dashboard.HelpCenter.MaxUploadSizeError'));
  };

  const hasErrors = (): boolean => {
    const helpFormRequiredFields = ['casetypecode', 'title', 'description'];
    const errors = validateRequiredFields(helpFormRequiredFields, formData, t);
    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!hasErrors()) {
      try {
        setActionIsRunning(true);
        const data = {
          user: selectedUser?.id,
          ...formData,
        };
        await createIncident(data);
        toast.success(t('Dashboard.HelpCenter.HelpCreateSuccess'));
        navigate(`/dashboard/start`);
      } catch (e: any) {
        toast.error(t('Dashboard.HelpCenter.HelpCreateError'));
      } finally {
        setActionIsRunning(false);
      }
    }
  };

  const handleClose = () => {
    navigate(`/dashboard/start`);
  };

  if (!selectedUser || !formData) {
    return (
      <Box
        sx={{
          mt: 20,
          mb: 30,
          textAlign: 'center',
          minHeight: '70vh',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '70vh' }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} sx={{ position: 'relative' }}>
        <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
          <Box>
            <Typography
              variant="h1"
              style={{
                maxWidth: '500px',
                marginBottom: '32px',
                fontSize: '36px',
                textTransform: 'none',
              }}
            >
              {t('Dashboard.ContactForm')}
            </Typography>
          </Box>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel>{t('Dashboard.HelpCenter.Topic')}</InputLabel>
                    <Select
                      variant="filled"
                      label={t('Dashboard.HelpCenter.Topic')}
                      value={formData.casetypecode}
                      error={!!errors.casetypecode}
                      disabled={actionIsRunning}
                      onChange={(e: any) => handleChange(e.target.value, 'casetypecode')}
                    >
                      {typeOptions?.map((typeOption: { value: string; text: string }) => (
                        <MenuItem key={typeOption.value} value={typeOption.value}>
                          {t(`Dashboard.HelpCenter.${typeOption.text}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.casetypecode && (
                      <FormHelperText error>{errors.casetypecode}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} />
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    variant="filled"
                    inputProps={{ maxLength: 200 }}
                    label={t('Dashboard.HelpCenter.Subject')}
                    name="title"
                    value={formData.title}
                    helperText={errors.title}
                    error={!!errors.title}
                    disabled={actionIsRunning}
                    onChange={(e: any) => handleChange(e.target.value, 'title')}
                  />
                </Grid>
                <Grid item md={6} xs={12} />
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    inputProps={{ maxLength: 1048000 }}
                    autoComplete="off"
                    variant="filled"
                    label={t('Dashboard.HelpCenter.Message')}
                    name="description"
                    value={formData.description}
                    helperText={errors.description}
                    error={!!errors.description}
                    disabled={actionIsRunning}
                    onChange={(e: any) => handleChange(e.target.value, 'description')}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  {formData.file && (
                    <Typography style={{ marginBottom: '6px', fontWeight: 'bold' }}>
                      {t('Dashboard.Songs.SelectedFile')}: {formData.file?.name}
                    </Typography>
                  )}
                  <Dropzone
                    maxSize={5242880}
                    disabled={actionIsRunning}
                    onChange={handleFileChange}
                    onFileReject={handleFileReject}
                  />
                  <p>{t('Dashboard.HelpCenter.MaxUploadSize')}</p>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button variant="contained" disabled={actionIsRunning} onClick={handleSubmit}>
                {t('Dashboard.HelpCenter.Send')}
              </Button>
              <Button color="secondary" disabled={actionIsRunning} onClick={handleClose}>
                {t('Dashboard.Common.Cancel')}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Stack>
    </Box>
  );
};
