import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import QuizIcon from '@mui/icons-material/Quiz';

const getNavItems = (t: any) => [
  {
    key: 'personal',
    title: t('Dashboard.Sidebar.HelpCenter'),
    items: [
      {
        key: 'faq',
        title: t('Dashboard.Sidebar.FAQ'),
        href: '/dashboard/help/faq',
        icon: <QuizIcon fontSize="small" />,
      },
      {
        key: 'contact',
        title: t('Dashboard.ContactForm'),
        href: '/dashboard/help/contact',
        icon: <EmailIcon fontSize="small" />,
      },
    ],
  },
];

export const HelpSideNav = (): React.JSX.Element => {
  const { t } = useTranslation();
  const navItems = React.useMemo(() => getNavItems(t), [t]);

  return (
    <div>
      <Stack
        spacing={3}
        sx={{
          flex: '0 0 auto',
          flexDirection: { xs: 'column-reverse', md: 'column' },
          position: { md: 'sticky' },
          top: '64px',
          width: { xs: '100%', md: '240px' },
        }}
      >
        <Stack component="ul" spacing={3} sx={{ listStyle: 'none', m: 0, p: 0 }}>
          {navItems.map(group => (
            <Stack component="li" key={group.key} spacing={2}>
              {group.title ? (
                <div>
                  <Typography
                    sx={{
                      color: 'rgb(102, 112, 133)',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    {group.title}
                  </Typography>
                </div>
              ) : null}
              <Stack component="ul" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
                {group.items.map(item => (
                  <NavItem {...item} key={item.key} id={item.key} />
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </div>
  );
};

const NavItem = ({ id, href, icon, title }: any): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const active = location.pathname.includes(id);

  return (
    <Box component="li" sx={{ userSelect: 'none' }}>
      <Box
        onClick={() => navigate(href)}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          color: 'rgb(102, 112, 133)',
          cursor: 'pointer',
          display: 'flex',
          flex: '0 0 auto',
          gap: 1,
          p: '6px 16px',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          ...(active && {
            bgcolor: 'rgba(0, 0, 0, 0.04)',
            color: 'rgb(33, 38, 54)',
          }),
          '&:hover': {
            ...(!active && {
              bgcolor: 'rgba(0, 0, 0, 0.04)',
              color: 'rgb(33, 38, 54)',
            }),
          },
        }}
        tabIndex={0}
      >
        {icon ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              flex: '0 0 auto',
            }}
          >
            {icon}
          </Box>
        ) : null}
        <Box sx={{ flex: '1 1 auto' }}>
          <Typography
            component="span"
            sx={{ color: 'inherit', fontSize: '14px', fontWeight: 'bold', lineHeight: '28px' }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
