import { Box, Button, FormControl, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ILoginFormData, newLoginFormData } from '../../../interfaces/LoginFormData';
import { authenticate } from '../../../services/httpService';
import { useAuth } from '../../provider/AuthProvider';
import { getLoginFormErrors } from './LoginFormHelper';
import logo from '../../../assets/images/logo-hellblau.png';
import Logo_CD from '../../../assets/images/Logo_CD.png';
import Logo_DMV from '../../../assets/images/Logo_DMV.png';
import Logo_IMPF from '../../../assets/images/Logo_IMPF.png';
import Logo_FPC from '../../../assets/images/Logo_FPC.png';
import Logo_MERLIN from '../../../assets/images/Logo_MERLIN.png';
import Logo_VERSO from '../../../assets/images/Logo_VERSO.png';
import Logo_VUT from '../../../assets/images/Logo_VUT.png';
import { LanguageButton } from '../../template/dashboard-navbar';

export const LoginForm = () => {
  const { t } = useTranslation();
  const { setUserId } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ILoginFormData>(newLoginFormData);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);

  const hasErrors = (): boolean => {
    const errors = getLoginFormErrors(formData, t);
    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleChange = (value: any, key: string) => {
    const nextFormdata = { ...formData, [key]: value };
    setFormData(nextFormdata);
    if (value && errors[key]) {
      const nextErrors = { ...errors };
      delete nextErrors[key];
      setErrors(nextErrors);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!hasErrors() && setUserId) {
      try {
        setActionIsRunning(true);
        const user = await authenticate(formData);
        toast.success(t('LoginForm.Success'));
        setUserId(user.userId);
        navigate('/dashboard/start');
      } catch (e: any) {
        let errorMessage;
        if (e?.response?.data?.error === 'NO_USER') {
          errorMessage = t('LoginForm.ErrorUser');
        } else if (e?.response?.data?.error === 'WRONG_PASSWORD') {
          errorMessage = t('LoginForm.ErrorPassword');
        } else {
          errorMessage = t('LoginForm.ErrorLogin');
        }
        toast.error(errorMessage);
      } finally {
        setActionIsRunning(false);
      }
    }
  };

  /*
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '70vh',
        p: 3,
        mt: 10,
      }}
    >
      <Container maxWidth="sm">
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: 400,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography variant="h4">{t('LoginForm.Login')}</Typography>
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <form onSubmit={event => event.preventDefault()}>
                <FormControl fullWidth>
                  <TextField
                    variant="filled"
                    label={t('LoginForm.User')}
                    helperText={errors.roba_accountname}
                    error={!!errors.roba_accountname}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_accountname')}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                >
                  <TextField
                    variant="filled"
                    label={t('LoginForm.Password')}
                    type="password"
                    helperText={errors.roba_hash}
                    error={!!errors.roba_hash}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_hash')}
                  />
                </FormControl>
                <Box sx={{ mt: 2 }}>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={actionIsRunning}
                    onClick={handleSubmit}
                  >
                    {t('LoginForm.Login')}
                  </Button>
                </Box>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
  */
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', lg: '1fr 800px' },
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgb(25, 65, 80)',
          display: { xs: 'none', lg: 'flex' },
          flexDirection: 'column',
          color: 'white',
          p: 3,
        }}
      >
        <Stack spacing={4} sx={{ maxWidth: '700px' }}>
          <Stack spacing={1}>
            <img
              src={logo}
              alt="ROBA Music Publishing"
              style={{ width: '160px', marginBottom: '40px' }}
            />
            <Typography variant="h4">{t('LoginForm.Welcome')}</Typography>
            <Typography>{t('LoginForm.Text')}</Typography>
          </Stack>
          <Typography variant="h5" style={{ fontSize: '18px', marginBottom: '-16px' }}>
            {t('LoginForm.MemberOf')}
          </Typography>
          <Stack
            direction="row"
            spacing={3}
            sx={{
              alignItems: 'center',
              color: 'var(--mui-palette-neutral-500)',
              flexWrap: 'wrap',
            }}
          >
            <img src={Logo_IMPF} alt="Logo IMPF" style={{ width: '100px' }} />
            <img src={Logo_DMV} alt="Logo DMV" style={{ width: '100px' }} />
            <img src={Logo_VUT} alt="Logo VUT" style={{ width: '100px' }} />
            <img src={Logo_MERLIN} alt="Logo MERLIN" style={{ width: '100px' }} />
          </Stack>
          <Typography
            variant="h5"
            style={{ marginTop: '60px', fontSize: '18px', marginBottom: '-16px' }}
          >
            {t('LoginForm.SupporterOf')}
          </Typography>
          <Stack
            direction="row"
            spacing={3}
            sx={{ alignItems: 'center', color: 'var(--mui-palette-neutral-500)', flexWrap: 'wrap' }}
          >
            <img src={Logo_FPC} alt="Logo FPC" style={{ width: '100px' }} />
            <img src={Logo_VERSO} alt="Logo Verso" style={{ width: '100px' }} />
            <img src={Logo_CD} alt="Logo CD" style={{ width: '100px' }} />
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ boxShadow: 'var(--mui-shadows-8)', display: 'flex', flexDirection: 'column' }}>
        <div style={{ paddingTop: '1%', width: '98%', textAlign: 'right' }}>
          <LanguageButton />
        </div>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            justifyContent: 'center',
            p: 3,
          }}
        >
          <Box sx={{ maxWidth: '420px', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography variant="h4">{t('LoginForm.Login')}</Typography>
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <form onSubmit={event => event.preventDefault()}>
                <FormControl fullWidth>
                  <TextField
                    variant="filled"
                    label={t('LoginForm.User')}
                    helperText={errors.roba_accountname}
                    error={!!errors.roba_accountname}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_accountname')}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    mt: 2,
                  }}
                >
                  <TextField
                    variant="filled"
                    label={t('LoginForm.Password')}
                    type="password"
                    helperText={errors.roba_hash}
                    error={!!errors.roba_hash}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_hash')}
                  />
                </FormControl>
                <Box sx={{ mt: 2 }}>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={actionIsRunning}
                    onClick={handleSubmit}
                  >
                    {t('LoginForm.Login')}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
