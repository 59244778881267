import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Slider,
  Tooltip,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@mui/icons-material';
import { robaGemaPerformanceDistributionDefault } from '../../../../interfaces/Song';

export interface ISongFormWritersARSplitProps {
  arValue: number;
  customArValueConfirmed: boolean;
  actionIsRunning: boolean;
  errors: { [field: string]: any };
  onArValueChange: (arValue: number) => void;
  onCustomArValueConfirm: (customArValueConfirmed: boolean) => void;
}

export const SongFormWritersARSplit: React.FC<ISongFormWritersARSplitProps> = ({
  arValue,
  customArValueConfirmed,
  actionIsRunning,
  errors,
  onArValueChange,
  onCustomArValueConfirm,
}) => {
  const { t } = useTranslation();
  const [arTypeValue, setARTypeValue] = useState<number | undefined>(undefined);

  const options = useMemo(
    () => [
      { value: 1, text: t('Dashboard.Songs.EqualShares'), split: 0.5 },
      { value: 2, text: t('Dashboard.Songs.BasicDivision'), split: 0.64 },
      { value: 3, text: t('Dashboard.Songs.FreeNegotiability'), split: 0.5 },
    ],
    [t],
  );

  const handleARTypeChange = (value: number) => {
    const split = options.find((option: any) => option.value === value)?.split;
    onArValueChange(split as number);
    setARTypeValue(value);
  };

  const handleSliderChange = (_: any, value: number | number[]) => {
    let nextValue = value as number;
    if (value <= 35.2) {
      nextValue = 35.2;
    }
    if (value >= 80.2) {
      nextValue = 80.2;
    }
    const formattedValue = parseFloat(nextValue.toFixed(3));
    onArValueChange((formattedValue as number) / 100);
  };

  const handleARValueChange = (value: number) => {
    const formattedValue = parseFloat(value.toFixed(3));
    onArValueChange(formattedValue);
  };

  const getARTypeValue = (arValue: number): number => {
    if (arValue === 0.5) {
      return 1;
    }
    if (arValue === 0.64) {
      return 2;
    }
    return 3;
  };

  useEffect(() => {
    if (arValue) {
      const initARTypeValue = getARTypeValue(arValue);
      setARTypeValue(initARTypeValue);
    }
  }, [arValue]);

  return (
    <div>
      {arValue !== undefined && (
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t('Dashboard.Songs.SplitAR')}
              </Typography>
              <FormControl fullWidth variant="filled">
                <InputLabel required>{t('Dashboard.Songs.SplitAR')}</InputLabel>
                <Select
                  key={arTypeValue}
                  required
                  variant="filled"
                  label={t('Dashboard.Songs.SplitAR')}
                  value={arTypeValue}
                  disabled={actionIsRunning}
                  onChange={(e: any) => handleARTypeChange(e.target.value)}
                >
                  {options.map((option: any) => (
                    <MenuItem value={option.value}>{option.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box sx={{ mt: 5, pl: 4, pr: 4 }}>
                <Slider
                  value={arValue * 100}
                  step={0.1}
                  valueLabelDisplay="off"
                  disabled={actionIsRunning}
                  disableSwap
                  marks={[
                    {
                      value: 0,
                      label: <div />,
                    },
                    {
                      value: 100,
                      label: <div />,
                    },
                  ]}
                  onChange={handleSliderChange}
                />
              </Box>
              <TextField
                fullWidth
                style={{ width: '100px' }}
                label="Musikanteil %"
                autoComplete="off"
                type="number"
                variant="filled"
                value={parseFloat((arValue * 100).toFixed(2))}
                disabled={actionIsRunning}
                InputProps={{
                  inputProps: { min: 35.2, max: 80.2, step: 0.1 },
                }}
                helperText={
                  parseFloat((arValue * 100).toFixed(2)) < 35.2
                    ? 'Der Mindestwert für den Musikanteil beträgt 35,2%'
                    : ''
                }
                error={parseFloat((arValue * 100).toFixed(2)) < 35.2}
                onChange={(e: any) => handleARValueChange((e.target.value as number) / 100)}
              />
              <TextField
                fullWidth
                style={{ width: '100px', float: 'right' }}
                label="Textanteil %"
                autoComplete="off"
                type="number"
                variant="filled"
                value={parseFloat((100 - arValue * 100).toFixed(2))}
                disabled={actionIsRunning}
                InputProps={{
                  inputProps: { min: 19.8, max: 64.8, step: 0.1 },
                }}
                helperText={
                  parseFloat((100 - arValue * 100).toFixed(2)) < 19.8
                    ? 'Der Mindestwert für den Textanteil beträgt 19.8%'
                    : ''
                }
                error={parseFloat((100 - arValue * 100).toFixed(2)) < 19.8}
                onChange={(e: any) => handleARValueChange((100 - (e.target.value as number)) / 100)}
              />
            </Grid>
            {arValue !== robaGemaPerformanceDistributionDefault && (
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customArValueConfirmed}
                        style={{
                          color: errors.CustomArValueConfirmed ? 'rgb(209, 67, 67)' : '#00B3D4',
                        }}
                      />
                    }
                    label={t('Dashboard.Songs.ARCheckbox')}
                    style={{
                      color: errors.CustomArValueConfirmed ? 'rgb(209, 67, 67)' : 'rgb(25, 65, 80)',
                    }}
                    disabled={actionIsRunning}
                    onChange={(e: any) => onCustomArValueConfirm(e.target.checked)}
                  />
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </div>
  );
};
