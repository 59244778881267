import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface ISongFormWritersPublicDomainIncludingTextProps {
  publicDomainIncludingText: boolean;
  actionIsRunning: boolean;
  onPublicDomainIncludingTextChange: (ispublicdomain: boolean) => void;
}

export const SongFormWritersPublicDomainIncludingText: React.FC<
  ISongFormWritersPublicDomainIncludingTextProps
> = ({ publicDomainIncludingText, actionIsRunning, onPublicDomainIncludingTextChange }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 4 }}>
      <FormControlLabel
        control={<Checkbox checked={publicDomainIncludingText} />}
        label={t('Dashboard.Songs.PublicDomainIncludingText')}
        disabled={actionIsRunning}
        onChange={(e: any) => onPublicDomainIncludingTextChange(e.target.checked)}
      />
    </Box>
  );
};
