import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Post } from '../../interfaces/Post';

export interface PostCardProps {
  post: Post;
}

export const PostCard: React.FC<PostCardProps> = ({ post }: PostCardProps): React.JSX.Element => {
  const { i18n } = useTranslation();
  const lines = post.caption.split('\n');
  const title = lines[0]?.trim() || '';
  const description = lines.slice(1).join('\n').trim();

  return (
    <Card>
      <CardMedia
        component={Link}
        href={post.permalink}
        target="_blank"
        image={post.media_url}
        sx={{ height: '280px' }}
      />
      <CardContent>
        <Stack spacing={2}>
          {/*
          <div>
            <Chip label={post.category} />
          </div>
          */}
          <div>
            <Link color="text.primary" href={post.permalink} target="_blank" variant="h5">
              {title}
            </Link>
          </div>
          <Typography
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              height: '48px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              // Fallback für ältere Browser oder nicht-WebKit-Browser
              '@media (max-width: 600px)': {
                WebkitLineClamp: 1, // Optional: Anpassung für mobile Geräte
              },
            }}
            variant="body1"
          >
            {description}
          </Typography>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ alignItems: 'center', flex: '1 1 auto', flexWrap: 'wrap' }}
            >
              <Typography sx={{ flex: '1 1 auto' }} variant="subtitle2">
                {dayjs(post.timestamp).format(
                  i18n.language.includes('en') ? 'MMM D, YYYY' : 'DD.MM.YYYY',
                )}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
