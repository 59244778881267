import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getURLSearchParam } from '../../../helper/LocationHelper';
import { IRegisterFormData, newRegisterFormData } from '../../../interfaces/RegisterFormData';
import { getUser, getWeblogin, registerUser } from '../../../services/httpService';
import { getRegisterFormErrors } from './RegisterFormHelper';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';

export const RegisterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [tokenValid, setTokenValid] = useState<boolean>(false);
  const [formData, setFormData] = useState<IRegisterFormData>(newRegisterFormData);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});

  const getTokenResult = async (token: string) => {
    let tokenResult;
    try {
      tokenResult = await getWeblogin(token);
    } catch {
      toast.error(t('RegisterForm.ErrorLoading'));
      return;
    } finally {
      setLoading(false);
    }
    if (tokenResult.length === 1) {
      const now = new Date().getTime();
      const tokenExpireOn = new Date(tokenResult[0].roba_tokenexpireon).getTime();
      if (now < tokenExpireOn) {
        setTokenValid(true);
        setFormData({
          ...formData,
          roba_webloginid: tokenResult[0].roba_webloginid,
          roba_accountname: tokenResult[0].roba_accountname,
        });
      } else {
        toast.error(t('RegisterForm.ErrorTokenExpired'));
      }
    } else {
      toast.error(t('RegisterForm.ErrorNoDataFound'));
    }
  };

  const hasErrors = (): boolean => {
    const errors = getRegisterFormErrors(formData, t);
    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const userExists = async (): Promise<boolean | undefined> => {
    try {
      const userResult = await getUser(formData.roba_accountname);
      return !!userResult.length;
    } catch {
      toast.error(t('RegisterForm.ErrorCheckingAccountName'));
      return undefined;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!hasErrors()) {
      const users = await userExists();
      if (users === true) {
        toast.error(t('RegisterForm.ErrorUserAlreadyExists'));
        return;
      }
      if (users === false) {
        try {
          await registerUser(formData);
          toast.success(t('RegisterForm.Success'));
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        } catch {
          toast.error(t('RegisterForm.ErrorRegistration'));
        }
      }
    }
  };

  const handleChange = (value: any, key: string) => {
    const nextFormdata = { ...formData, [key]: value };
    setFormData(nextFormdata);
    if (value && errors[key]) {
      const nextErrors = { ...errors };
      delete nextErrors[key];
      setErrors(nextErrors);
    }
  };

  useEffect(() => {
    const token = getURLSearchParam('token');
    if (token) {
      getTokenResult(token);
    } else {
      toast.error(t('RegisterForm.ErrorNoToken'));
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '70vh',
        p: 3,
        mt: 10,
      }}
    >
      {loading && (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
        </Box>
      )}

      {!loading && tokenValid && (
        <Container maxWidth="sm">
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 400,
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography variant="h4">{t('RegisterForm.Register')}</Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <form onSubmit={event => event.preventDefault()}>
                  <FormControl fullWidth>
                    <TextField
                      variant="filled"
                      label={t('RegisterForm.User')}
                      value={formData.roba_accountname}
                      helperText={errors.roba_accountname}
                      error={!!errors.roba_accountname}
                      onChange={(e: any) => handleChange(e.target.value, 'roba_accountname')}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 2,
                    }}
                  >
                    <TextField
                      variant="filled"
                      label={t('RegisterForm.Password')}
                      type="password"
                      value={formData.roba_hash}
                      helperText={errors.roba_hash}
                      error={!!errors.roba_hash}
                      onChange={(e: any) => handleChange(e.target.value, 'roba_hash')}
                    />
                  </FormControl>
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.checked_terms}
                            onChange={(e: any) => handleChange(e.target.checked, 'checked_terms')}
                          />
                        }
                        label={t('RegisterForm.TermsAndConditions')}
                      />
                    </FormGroup>
                    {errors.checked_terms && (
                      <FormHelperText error>{t('RegisterForm.ConfirmTerms')}</FormHelperText>
                    )}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      {t('RegisterForm.Register')}
                    </Button>
                  </Box>
                </form>
              </Box>
              {/*
            <Divider sx={{ my: 3 }} />
            <Link color="textSecondary" href="#" variant="body2">
              Having an account
            </Link>
                */}
            </CardContent>
          </Card>
        </Container>
      )}
    </Box>
  );
};
