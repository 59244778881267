import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { Camera as CameraIcon } from '@phosphor-icons/react/dist/ssr/Camera';
import { User as UserIcon } from '@phosphor-icons/react/dist/ssr/User';
import { useNavigate } from 'react-router-dom';
import loadingSpinner from '../../../../assets/images/loadingSpinner.svg';
import { SideNav } from '../SideNav';
import { useAuth } from '../../../provider/AuthProvider';
import { IMyData } from '../../../../interfaces/MyData';
import { IAccountFormData } from '../../../../interfaces/AccountFormData';
import { validateRequiredFields } from '../../../../helper/ValidationHelper';
import { updateContact } from '../../../../services/httpService';

export const Account: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedUser, myData, getContactData } = useAuth();

  const [formData, setFormData] = useState<Partial<IMyData> | undefined>(undefined);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);

  const handleChange = (value: any, key: string) => {
    const nextFormdata = { ...formData, [key]: value };
    setFormData(nextFormdata);
    if (value && errors[key]) {
      const nextErrors = { ...errors };
      delete nextErrors[key];
      setErrors(nextErrors);
    }
  };

  const hasErrors = (): boolean => {
    const accountRequiredFields = ['firstname', 'lastname', 'emailaddress1'];
    const errors = validateRequiredFields(accountRequiredFields, formData, t);
    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!hasErrors()) {
      try {
        setActionIsRunning(true);
        const data = {
          user: selectedUser?.id,
          ...formData,
        };
        await updateContact(data);
        if (getContactData) {
          await getContactData();
        }
        toast.success(t('Dashboard.Settings.Account.ContactUpdateSuccess'));
        navigate(`/dashboard/start`);
      } catch (e: any) {
        toast.error(t('Dashboard.Settings.Accounts.ContactUpdateError'));
      } finally {
        setActionIsRunning(false);
      }
    }
  };

  const handleClose = () => {
    navigate(`/dashboard/start`);
  };

  useEffect(() => {
    if (selectedUser && myData) {
      setFormData({
        firstname: myData.firstname,
        lastname: myData.lastname,
        emailaddress1: myData.emailaddress1,
      });
    }
  }, [selectedUser, myData]);

  if (!selectedUser || !formData) {
    return (
      <Box
        sx={{
          mt: 20,
          mb: 30,
          textAlign: 'center',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          maxWidth: 'var(--Content-maxWidth)',
          m: 'var(--Content-margin)',
          width: 'var(--Content-width)',
        }}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} sx={{ position: 'relative' }}>
          {/* <SideNav /> */}
          <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
            <Box>
              <Typography
                variant="h1"
                style={{
                  maxWidth: '500px',
                  marginBottom: '32px',
                  fontSize: '36px',
                  textTransform: 'none',
                }}
              >
                Account
              </Typography>
            </Box>
            <Card>
              <CardHeader
                avatar={
                  <Avatar>
                    <UserIcon fontSize="24px" />
                  </Avatar>
                }
                title={t('Dashboard.Settings.Account.PersonalData')}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      variant="filled"
                      label={t('Dashboard.Settings.Account.FirstName')}
                      name="firstname"
                      value={formData.firstname}
                      helperText={errors.firstname}
                      error={!!errors.firstname}
                      disabled={actionIsRunning}
                      onChange={(e: any) => handleChange(e.target.value, 'firstname')}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      variant="filled"
                      label={t('Dashboard.Settings.Account.LastName')}
                      name="lastname"
                      value={formData.lastname}
                      helperText={errors.lastname}
                      error={!!errors.lastname}
                      disabled={actionIsRunning}
                      onChange={(e: any) => handleChange(e.target.value, 'lastname')}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      variant="filled"
                      label={t('Dashboard.Settings.Account.EMail')}
                      name="emailaddress1"
                      value={formData.emailaddress1}
                      helperText={errors.emailaddress1}
                      error={!!errors.emailaddress1}
                      disabled={actionIsRunning}
                      onChange={(e: any) => handleChange(e.target.value, 'emailaddress1')}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button variant="contained" onClick={handleSubmit}>
                  {t('Dashboard.Common.Save')}
                </Button>
                <Button color="secondary" onClick={handleClose}>
                  {t('Dashboard.Common.Cancel')}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
