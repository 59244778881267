import React from 'react';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ISongWriter } from '../../../../interfaces/Song';

export interface ISongFormWritersCommandBarProps {
  songWriters: ISongWriter[];
  actionIsRunning: boolean;
  onAddSongWriter: (songWriterId?: string | undefined) => void;
  onSplitSharesEqually: () => void;
  onShowCreateWriterModal: (showCreateWriterModal: boolean) => void;
}

export const SongFormWritersCommandBar: React.FC<ISongFormWritersCommandBarProps> = ({
  songWriters,
  actionIsRunning,
  onAddSongWriter,
  onSplitSharesEqually,
  onShowCreateWriterModal,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 4 }}>
      <Button
        disabled={actionIsRunning}
        onClick={(_: any) => onAddSongWriter()}
        variant="outlined"
        style={{ marginRight: '8px' }}
      >
        {t('Dashboard.Songs.AddWriter')}
      </Button>
      {/* }
      <Button
        disabled={actionIsRunning}
        onClick={() => {
          onShowCreateWriterModal(true);
        }}
        variant="outlined"
        style={{ marginRight: '24px' }}
      >
        {t('Dashboard.Songs.CreateWriter')}
      </Button>
      */}
      <Button
        disabled={!songWriters.length || actionIsRunning}
        onClick={onSplitSharesEqually}
        variant="outlined"
        style={{ marginRight: '8px' }}
      >
        {t('Dashboard.Songs.SplitShares')}
      </Button>
    </Box>
  );
};
