import React from 'react';
import { Box, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import deFlag from '../../assets/images/de_flag.svg';
import enFlag from '../../assets/images/uk_flag.svg';

interface LanguagePopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

type Language = 'en-US' | 'de-DE';

type LanguageOptions = {
  [key in Language]: {
    icon: string;
    label: string;
  };
};

const languageOptions: LanguageOptions = {
  'en-US': {
    icon: enFlag,
    label: 'English',
  },
  'de-DE': {
    icon: deFlag,
    label: 'German',
  },
};

export const LanguagePopover: React.FC<LanguagePopoverProps> = props => {
  const { t, i18n } = useTranslation();
  const { anchorEl, onClose, open, ...other } = props;

  const handleChange = async (language: Language): Promise<void> => {
    onClose?.();
    await i18n.changeLanguage(language);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 240 } }}
      transitionDuration={0}
      {...other}
    >
      {(Object.keys(languageOptions) as Language[]).map(language => (
        <MenuItem onClick={() => handleChange(language)} key={language}>
          <ListItemIcon>
            <Box
              sx={{
                display: 'flex',
                height: 20,
                width: 20,
                '& img': {
                  width: '100%',
                },
              }}
            >
              <img alt={languageOptions[language].label} src={languageOptions[language].icon} />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="subtitle2">{t(languageOptions[language].label)}</Typography>
            }
          />
        </MenuItem>
      ))}
    </Popover>
  );
};
