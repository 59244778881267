import axios, { AxiosResponse } from 'axios';
import { IAccountFormData } from '../interfaces/AccountFormData';
import { ILoginFormData } from '../interfaces/LoginFormData';
import { IRegisterFormData } from '../interfaces/RegisterFormData';
import { ISelectOption } from '../interfaces/SelectOption';
import { ISong, ISongAlternativeTitle } from '../interfaces/Song';
import { IWriter } from '../interfaces/Writer';
import { IMyData } from '../interfaces/MyData';
import { IFormDocument } from '../interfaces/FormDocument';

// const baseUrl = 'http://localhost:5000/api';
// const baseUrl = 'https://robadataverseconnector.azurewebsites.net/api';
const baseUrl = 'https://my.roba.com/api';

// move
const mapBoxKey =
  'pk.eyJ1Ijoicm9iYXBvcnRhbCIsImEiOiJjbGFnaDdqazYwamh5M3VtcHV5YnhsbzhmIn0.JNGAAKocIipnwCiFHdV4bQ';

axios.defaults.withCredentials = true;

export const createAccount = (accountData: IAccountFormData) => {
  const data = {
    ...accountData,
    roba_action: 722040000,
  };
  return axios.post(`${baseUrl}/account/create`, data);
};

export const getFieldOptions = (endpoint: string): Promise<ISelectOption[]> => {
  return axios.get(`${baseUrl}/fieldOptions/${endpoint}`).then((res: AxiosResponse) => {
    return res.data.GlobalOptionSet.Options.map((fieldOption: any) => ({
      value: fieldOption.Value,
      labels: {
        de: fieldOption.Label.LocalizedLabels[0].Label,
        en: fieldOption.Label.LocalizedLabels[1]
          ? fieldOption.Label.LocalizedLabels[1].Label
          : fieldOption.Label.LocalizedLabels[0].Label,
      },
    }));
  });
};

export const recordingFieldOptions = (): Promise<{ [field: string]: ISelectOption[] }> => {
  return axios
    .get(`${baseUrl}/fieldOptions/recordingFieldOptions`)
    .then((res: AxiosResponse) => res.data);
};

export const getPlaces = (address: string): Promise<any> => {
  return axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${mapBoxKey}&proximity=50,9&language=de&types=address&limit=5`,
    {
      withCredentials: false,
    },
  );
};

export const getWeblogin = (token: string): Promise<any[]> => {
  return axios
    .get(`${baseUrl}/auth/token?token=${token}`)
    .then((res: AxiosResponse) => res.data.value);
};

export const getUser = (user: string): Promise<any[]> => {
  return axios
    .get(`${baseUrl}/auth/user?user=${user}`)
    .then((res: AxiosResponse) => res.data.value);
};

export const checkToken = (): Promise<any> => {
  return axios.get(`${baseUrl}/auth/checkToken`).then((res: AxiosResponse) => res.data);
};

export const logout = (): Promise<any> => {
  return axios.get(`${baseUrl}/auth/logout`).then((res: AxiosResponse) => res.data);
};

export const authenticate = (loginData: ILoginFormData): Promise<any> => {
  return axios
    .post(`${baseUrl}/auth/authenticate`, loginData)
    .then((res: AxiosResponse) => res.data);
};

export const registerUser = (registerData: IRegisterFormData): Promise<any> => {
  return axios
    .post(`${baseUrl}/auth/register`, registerData)
    .then((res: AxiosResponse) => res.data);
};

/* My data */
export const getMyData = (userId: string): Promise<IMyData> => {
  return axios.get(`${baseUrl}/me?user=${userId}`).then((res: AxiosResponse) => res.data);
};
export const getP = (user: string): Promise<IMyData> => {
  return axios.get(`${baseUrl}/me?user=${user}`).then((res: AxiosResponse) => res.data);
};

export const updateDefaultAgreement = (data: any): Promise<any> => {
  return axios.patch(`${baseUrl}/me/updateDefaultAgreement`, data);
};

export const updateContact = (data: any): Promise<any> => {
  return axios.patch(`${baseUrl}/me/updateContact`, data);
};

/* Writer */
export const getWriters = (/* agreementId: string */): Promise<any[]> => {
  return axios.get(`${baseUrl}/writer`).then((res: AxiosResponse) => res.data.value);
};

export const getWriterList = (agreementIpiNumbers: string[]): Promise<any[]> => {
  const data = {
    agreementIpiNumbers: JSON.stringify(agreementIpiNumbers),
  };
  return axios.post(`${baseUrl}/writer/list`, data).then((res: AxiosResponse) => res.data);
};

export const searchPseudonyms = (search: string): Promise<any> => {
  return axios
    .get(`${baseUrl}/writer/searchPseudonyms?search=${search}`)
    .then((res: AxiosResponse) => res.data);
};

export const getWriter = (writerId: string): Promise<any> => {
  return axios.get(`${baseUrl}/writer/byid?id=${writerId}`).then((res: AxiosResponse) => res.data);
};

export const _isSongWriter = (writerId: string): Promise<any> => {
  return axios
    .get(`${baseUrl}/writer/isSongWriter?id=${writerId}`)
    .then((res: AxiosResponse) => res.data.value);
};

export const getPseudonyms = (writerId: string): Promise<any> => {
  return axios
    .get(`${baseUrl}/writer/pseudonyms?id=${writerId}`)
    .then((res: AxiosResponse) => res.data.value);
};

export const createWriter = (writerData: IWriter): Promise<any> => {
  return axios.post(`${baseUrl}/writer/create`, writerData).then((res: AxiosResponse) => res.data);
};

export const createCachedWriter = (ipi: string, isPublisherSearch: boolean): Promise<any> => {
  return axios
    .post(`${baseUrl}/writer/createCached`, { ipi, isPublisherSearch })
    .then((res: AxiosResponse) => res.data);
};

export const updateWriter = (writerData: IWriter): Promise<any> => {
  return axios.patch(`${baseUrl}/writer/update`, writerData);
};

export const deleteWriter = (writerId: string): Promise<any> => {
  return axios.post(`${baseUrl}/writer/delete`, { roba_writerid: writerId });
};

export const searchWriter = (ipi: string): Promise<any> => {
  return axios.get(`${baseUrl}/writer/search?ipi=${ipi}`).then((res: AxiosResponse) => res.data);
};

/* Publisher */
export const getPublisherFromCache = (publisherName: string): Promise<any> => {
  return axios
    .get(`${baseUrl}/publisher/getPublisherFromCache?publisherName=${publisherName}`)
    .then((res: AxiosResponse) => res.data);
};

export const searchPublisher = (ipi: string): Promise<any> => {
  return axios.get(`${baseUrl}/publisher/search?ipi=${ipi}`).then((res: AxiosResponse) => res.data);
};

/* Songs */
export const getSongs = (user: string): Promise<any> => {
  return axios.get(`${baseUrl}/song?user=${user}`).then((res: AxiosResponse) => res.data);
};

export const getSong = (songId: string): Promise<any> => {
  return axios.get(`${baseUrl}/song/byid?id=${songId}`).then((res: AxiosResponse) => res.data);
};

export const downloadFile = (entitiyId: string, folder: string): Promise<any> => {
  return axios.get(`${baseUrl}/song/downloadFile?entitiyId=${entitiyId}&folder=${folder}`, {
    responseType: 'blob',
  });
};

export const createSong = (song: ISong, titles: ISongAlternativeTitle[]): Promise<any> => {
  const data = {
    song,
    titles,
  };
  return axios.post(`${baseUrl}/song/create`, data).then((res: AxiosResponse) => res.data);
};

export const updateSong = (song: ISong, titles: ISongAlternativeTitle[]): Promise<any> => {
  const data = {
    song,
    titles,
  };
  return axios.patch(`${baseUrl}/song/update`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const copySong = (userId: string, songId: string, newTitle: string): Promise<any> => {
  return axios.post(`${baseUrl}/song/copy`, { userId, songId, newTitle });
};

export const deleteSong = (songId: string): Promise<any> => {
  return axios.post(`${baseUrl}/song/delete`, { roba_songid: songId });
};

export const deleteSongFile = (songId: string): Promise<any> => {
  return axios.post(`${baseUrl}/song/deleteSongFile`, { songId });
};

export const downloadSongFile = (songId: string): Promise<any> => {
  return axios.get(`${baseUrl}/song/downloadSongFile?songId=${songId}`, {
    responseType: 'blob',
  });
};

export const deleteSongAdditionalTitle = (titleId: string): Promise<any> => {
  return axios.post(`${baseUrl}/song/deleteAdditonalTitle`, { roba_additionaltitleid: titleId });
};

export const uploadRecording = (): Promise<any> => {
  return axios(`${baseUrl}/song/uploadRecording`);
};

export const uploadWorks = (data: any): Promise<any> => {
  return axios
    .post(`${baseUrl}/song/uploadMultipleWorks`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res: AxiosResponse) => res.data);
};

/* Song alternative titles */
export const getSongTitles = (songId: string): Promise<any> => {
  return axios.get(`${baseUrl}/song/titles?id=${songId}`).then((res: AxiosResponse) => res.data);
};

export const createSongTitle = (data: {
  roba_title: string;
  roba_songid: string;
}): Promise<any> => {
  return axios.post(`${baseUrl}/song/createTitle`, data).then((res: AxiosResponse) => res.data);
};

export const updateSongTitle = (data: {
  roba_title: string;
  roba_songid: string;
}): Promise<any> => {
  return axios.patch(`${baseUrl}/song/updateTitle`, data).then((res: AxiosResponse) => res.data);
};

export const deleteSongTitle = (titleId: string): Promise<any> => {
  return axios.post(`${baseUrl}/song/deleteTitle`, { roba_additionaltitleid: titleId });
};

/* Song writers */
export const getSongWriters = (songId: string): Promise<any> => {
  return axios
    .get(`${baseUrl}/song/songWriters?id=${songId}`)
    .then((res: AxiosResponse) => res.data);
};

export const saveSongWriters = (data: any): Promise<any> => {
  return axios.post(`${baseUrl}/song/saveSongWriters`, data).then((res: AxiosResponse) => res.data);
};

/* Song recordings */
export const getSongRecordings = (songId: string): Promise<any> => {
  return axios
    .get(`${baseUrl}/song/recordings?id=${songId}`)
    .then((res: AxiosResponse) => res.data);
};

export const createSongRecording = (
  data: any,
  onUploadProgress?: (progressEvent: any) => void,
): Promise<any> => {
  return axios
    .post(`${baseUrl}/song/createRecording`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    .then((res: AxiosResponse) => res.data);
};

export const updateSongRecording = (
  data: any,
  onUploadProgress?: (progressEvent: any) => void,
): Promise<any> => {
  return axios
    .patch(`${baseUrl}/song/updateRecording`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    .then((res: AxiosResponse) => res.data);
};

export const deleteSongRecording = (recordingId: string): Promise<any> => {
  return axios.post(`${baseUrl}/song/deleteRecording`, { roba_recordingid: recordingId });
};

export const deleteSongRecordingFile = (recordingId: string): Promise<any> => {
  return axios.post(`${baseUrl}/song/deleteRecordingFile`, { roba_recordingid: recordingId });
};

/* Spotify */
export const searchSong = (search: string, spotifyId: string): Promise<any[]> => {
  return axios
    .get(`${baseUrl}/spotify/searchSong?search=${search}&spotifyId=${spotifyId}`)
    .then((res: any) => res.data.tracks.items);
};

export const getSpotifyAlbumData = (albumId: string): Promise<any[]> => {
  return axios
    .get(`${baseUrl}/spotify/getAlbumData?albumId=${albumId}`)
    .then((res: any) => res.data);
};

// Message to Roba
export const createMessageToRoba = (data: any): Promise<any> => {
  return axios
    .post(`${baseUrl}/song/createMessageToRoba`, data)
    .then((res: AxiosResponse) => res.data);
};

/* Docs */
export const getDocs = (): Promise<any> => {
  return axios.get(`${baseUrl}/docs`).then((res: AxiosResponse) => res.data);
};

export const downloadDoc = (doc: IFormDocument): Promise<any> => {
  return axios.post(
    `${baseUrl}/docs/download`,
    { name: doc.name, serverRelativeUrl: doc.serverRelativeUrl },
    {
      responseType: 'blob',
    },
  );
};

/* Help */
export const createIncident = (data: any): Promise<any> => {
  return axios
    .post(`${baseUrl}/help/create`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res: AxiosResponse) => res.data);
};

/* Social Media */
export const getSocialPosts = (): Promise<any> => {
  return axios(`${baseUrl}/social/posts`).then((res: AxiosResponse) => res.data);
};
