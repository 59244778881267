import React, { ReactNode, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import type { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import SpeakerOutlinedIcon from '@mui/icons-material/SpeakerOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import EmailIcon from '@mui/icons-material/Email';
import { Selector as SelectorIcon } from '../icons/selector';
import { Scrollbar } from './scrollbar';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { OrganizationPopover } from './organization-popover';
import logo from '../../assets/images/logo-hellblau.png';

interface DashboardSidebarProps {
  onClose?: () => void;
  open?: boolean;
  collapsedSidebar: boolean;
  onCallapsedSidebarChange: (collapsedSidebar: boolean) => void;
}

interface Item {
  title: string;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  iconBig?: ReactNode;
  path?: string;
  disabled?: boolean;
}

interface Section {
  title: string;
  items: Item[];
}

const getSections = (t: any): Section[] => [
  {
    title: '',
    items: [
      {
        title: t('Dashboard.Sidebar.Dashboard'),
        path: '/dashboard/start',
        icon: <DashboardOutlinedIcon fontSize="small" />,
        iconBig: <DashboardOutlinedIcon fontSize="large" />,
      },
      {
        title: t('Dashboard.Sidebar.WorkDeclaration'),
        path: '/dashboard/songs',
        icon: <QueueMusicIcon fontSize="small" />,
        iconBig: <QueueMusicIcon fontSize="large" />,
      },
      {
        title: t('Dashboard.Sidebar.Tracking'),
        path: '/dashboard/songs',
        icon: <TroubleshootIcon fontSize="small" />,
        iconBig: <TroubleshootIcon fontSize="large" />,
        disabled: true,
      },
      {
        title: t('Dashboard.Sidebar.Royalties'),
        path: 'https://royalties.roba.com',
        icon: <LeaderboardOutlinedIcon fontSize="small" />,
        iconBig: <LeaderboardOutlinedIcon fontSize="large" />,
      },
      {
        title: t('Dashboard.Sidebar.Analytics'),
        path: '/dashboard/songs',
        icon: <BubbleChartOutlinedIcon fontSize="small" />,
        iconBig: <BubbleChartOutlinedIcon fontSize="large" />,
        disabled: true,
      },
      {
        title: t('Dashboard.Sidebar.Studio'),
        path: 'https://outlook.office365.com/book/ROBASongwritingStudiosbauteil3@roba.com/',
        icon: <SpeakerOutlinedIcon fontSize="small" />,
        iconBig: <SpeakerOutlinedIcon fontSize="large" />,
      },
      {
        title: t('Dashboard.Sidebar.Opportunities'),
        path: '/dashboard/songs',
        icon: <TvOutlinedIcon fontSize="small" />,
        iconBig: <TvOutlinedIcon fontSize="large" />,
        disabled: true,
      },
      {
        title: t('Dashboard.Sidebar.Forms'),
        path: '/dashboard/forms',
        icon: <InsertDriveFileOutlinedIcon fontSize="small" />,
        iconBig: <InsertDriveFileOutlinedIcon fontSize="large" />,
        disabled: false,
      },
      /*
      {
        title: t('Dashboard.Sidebar.FAQ'),
        path: '/dashboard/faq',
        icon: <QuizIcon fontSize="small" />,
        iconBig: <QuizIcon fontSize="large" />,
        disabled: false,
      },
      */
      {
        title: t('Dashboard.Sidebar.HelpCenter'),
        path: '/dashboard/help/faq',
        icon: <EmailIcon fontSize="small" />,
        iconBig: <EmailIcon fontSize="large" />,
        disabled: false,
      },
    ],
  },
];

export const DashboardSidebar: React.FC<DashboardSidebarProps> = ({
  open,
  collapsedSidebar,
  onClose,
  onCallapsedSidebarChange,
}) => {
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const sections = useMemo(() => getSections(t), [t]);
  const organizationsRef = useRef<HTMLButtonElement | null>(null);
  const [openOrganizationsPopover, setOpenOrganizationsPopover] = useState<boolean>(false);

  const handlePathChange = () => {
    /*
    if (!router.isReady) {
      return;
    }
    */

    if (open) {
      onClose?.();
    }
  };

  /*
  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, router.asPath]
  );
  */

  const handleOpenOrganizationsPopover = (): void => {
    setOpenOrganizationsPopover(true);
  };

  const handleCloseOrganizationsPopover = (): void => {
    setOpenOrganizationsPopover(false);
  };

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div>
            <Box sx={{ p: 3 }} display="flex" justifyContent="center" alignItems="center">
              <Link to="/dashboard/start">
                <img
                  src={logo}
                  alt="ROBA Music Publishing"
                  style={{ maxWidth: collapsedSidebar ? '100%' : '80%' }}
                />
              </Link>
            </Box>
          </div>
          <Divider
            sx={{
              borderColor: '#2D3748', // dark divider
              my: 3,
            }}
          />
          <div>
            <Box sx={{ px: 2 }}>
              <Box
                onClick={() => console.log('Music Publishing')}
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 3,
                  py: '11px',
                  borderRadius: 1,
                }}
              >
                <div>
                  <Typography color="inherit" variant="subtitle1">
                    Music Publishing
                  </Typography>
                  {/*
                  <Typography color="neutral.400" variant="body2">
                    {t('Your tier')} : Premium
                  </Typography>
                  */}
                </div>
                <SelectorIcon
                  sx={{
                    color: 'neutral.500',
                    width: 14,
                    height: 14,
                  }}
                />
              </Box>
            </Box>
          </div>
          <Divider
            sx={{
              borderColor: '#2D3748', // dark divider
              my: 3,
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            {sections.map(section => (
              <DashboardSidebarSection
                key={section.title}
                collapsedSidebar={collapsedSidebar}
                path="http..."
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2,
                  },
                }}
                {...section}
              />
            ))}
          </Box>
        </Box>
      </Scrollbar>
      <OrganizationPopover
        anchorEl={organizationsRef.current}
        onClose={handleCloseOrganizationsPopover}
        open={openOrganizationsPopover}
      />
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: theme => (theme.palette.mode === 'dark' ? 1 : 0),
            color: '#FFFFFF',
            width: collapsedSidebar ? 100 : 240,
            overflow: 'visible',
          },
        }}
        variant="permanent"
      >
        {content}

        {collapsedSidebar ? (
          <ArrowForwardIcon
            fontSize="small"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '76px',
              cursor: 'pointer',
              borderRadius: '100%',
              backgroundColor: 'neutral.900',
              padding: '10px',
            }}
            onClick={() => onCallapsedSidebarChange(false)}
          />
        ) : (
          <ArrowBackIcon
            fontSize="small"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '216px',
              cursor: 'pointer',
              borderRadius: '100%',
              backgroundColor: 'neutral.900',
              padding: '10px',
            }}
            onClick={() => onCallapsedSidebarChange(true)}
          />
        )}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: theme => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
