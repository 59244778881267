import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface IDeleteDialogProps {
  open: boolean;
  headline: string;
  actionIsRunning?: boolean;
  onDelete: () => void;
  onClose: () => void;
}

export const DeleteDialog: React.FC<IDeleteDialogProps> = ({
  open,
  headline,
  actionIsRunning,
  onDelete,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{headline}</DialogTitle>
      <DialogActions>
        <Button onClick={onDelete} autoFocus disabled={actionIsRunning} variant="contained">
          {t('Dashboard.Common.Yes')}
        </Button>
        <Button onClick={onClose} disabled={actionIsRunning}>
          {t('Dashboard.Common.Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
