import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { MinusOutlined as MinusOutlinedIcon } from '../icons/minus-outlined';

const sections = [
  {
    title: 'Menu',
    links: [
      {
        title: 'Browse Components',
        href: '#',
      },
      {
        title: 'Documentation',
        href: '#',
      },
    ],
  },
  {
    title: 'Placeholders',
    links: [
      {
        title: 'Terms & Conditions',
        href: '#',
      },
      {
        title: 'License',
        href: '#',
      },
      {
        title: 'Contact',
        href: '#',
      },
    ],
  },
  {
    title: 'Social',
    links: [
      {
        title: 'Instagram',
        href: '#',
      },
      {
        title: 'LinkedIn',
        href: '#',
      },
    ],
  },
];

export const Footer: React.FC = props => {
  const { t, i18n } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        borderTopColor: 'divider',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        pb: 3,
        pt: {
          md: 3,
          xs: 3,
        },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid
            item
            md={3}
            sm={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              order: {
                md: 1,
                xs: 4,
              },
            }}
            xs={12}
          >
            <Typography color="textSecondary" sx={{ mt: 1 }} variant="caption">
              © {new Date().getFullYear()} ROBA Music Publishing
            </Typography>
            <Link
              color="textSecondary"
              underline="none"
              variant="subtitle2"
              href={
                i18n.language.includes('en')
                  ? 'https://www.roba.com/imprint-2/'
                  : 'https://www.roba.com/de/impressum/'
              }
            >
              {t('Imprint')}
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
