export interface IRegisterFormData {
  roba_webloginid: string | null;
  roba_accountname: string;
  roba_hash: string;
  checked_terms: boolean;
}

export const newRegisterFormData = {
  roba_webloginid: null,
  roba_accountname: '',
  roba_hash: '',
  checked_terms: false,
};

export const registerFormRequiredFields = ['roba_accountname', 'roba_hash'];
