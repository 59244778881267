import React from 'react';
import { FormHelperText, TableCell, TableRow } from '@mui/material';
import {
  ISongWriter,
  songWriterAdaptorRoleValue,
  songWriterArrangerRoleValue,
  songWriterRoleAuthorValue,
  songWriterRoleComposerAuthorValue,
  songWriterRoleComposerValue,
} from '../../../../interfaces/Song';
import { getSongWritersTotalShare } from '../SongFormHelper';

export interface ISongFormWritersTotalSharesRowProps {
  songWriters: ISongWriter[];
  errors: { [field: string]: string };
}

export const SongFormWritersTotalSharesRow: React.FC<ISongFormWritersTotalSharesRowProps> = ({
  songWriters,
  errors,
}) => {
  const getTotalSharesSumByRole = (roles: number[], shareProp: string): JSX.Element => {
    if (
      !songWriters
        .filter((songWriter: ISongWriter) => !songWriter.deleted)
        .some((songWriter: ISongWriter) => roles.includes(songWriter.roba_role as number))
    ) {
      return <p>0</p>;
    }

    const sharesSum = songWriters
      .filter((songWriter: ISongWriter) => !songWriter.deleted)
      .reduce((sum: number, songWriter: ISongWriter) => {
        const shareText = (songWriter as any)[shareProp]
          ? parseFloat((songWriter as any)[shareProp] as string)
          : 0;
        return sum + shareText;
      }, 0);
    const equals100 = sharesSum === 100;
    return <p style={{ color: equals100 ? 'green' : 'red' }}>{sharesSum} %</p>;
  };

  const getTotalSharesSum = (): JSX.Element => {
    const totalSharesSum = songWriters
      .filter((songWriter: ISongWriter) => !songWriter.deleted)
      .reduce((sum: number, songWriter: ISongWriter) => {
        const shareTotal = getSongWritersTotalShare(songWriter, songWriters);
        return sum + shareTotal;
      }, 0);
    const equals100 = totalSharesSum.toFixed(2) === '100.00';
    return <p style={{ color: equals100 ? 'green' : 'red' }}>{totalSharesSum.toFixed(2)}</p>;
  };

  return (
    <TableRow>
      <TableCell />
      <TableCell />
      <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
        {getTotalSharesSumByRole(
          [songWriterRoleComposerValue, songWriterRoleComposerAuthorValue],
          'roba_share',
        )}
        {errors.roba_total_share_composition && (
          <FormHelperText error>{errors.roba_total_share_composition}</FormHelperText>
        )}
      </TableCell>
      <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
        {getTotalSharesSumByRole(
          [songWriterRoleAuthorValue, songWriterRoleComposerAuthorValue],
          'roba_share_text',
        )}
        {errors.roba_total_share_text && (
          <FormHelperText error>{errors.roba_total_share_text}</FormHelperText>
        )}
      </TableCell>
      {songWriters.some(
        (songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue,
      ) && (
        <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
          {getTotalSharesSumByRole([songWriterArrangerRoleValue], 'roba_share_editor')}
          {errors.roba_total_share_editor && (
            <FormHelperText error>{errors.roba_total_share_editor}</FormHelperText>
          )}
        </TableCell>
      )}
      {songWriters.some(
        (songWriter: ISongWriter) => songWriter.roba_role === songWriterAdaptorRoleValue,
      ) && (
        <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
          {getTotalSharesSumByRole([songWriterAdaptorRoleValue], 'roba_share_editor_text')}
          {errors.roba_total_share_text_editor && (
            <FormHelperText error>{errors.roba_total_share_text_editor}</FormHelperText>
          )}
        </TableCell>
      )}
      {/*
      <TableCell>{getTotalSharesSum()}</TableCell>
          */}
      <TableCell />
      <TableCell />
      <TableCell align="right" />
    </TableRow>
  );
};
