import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { X as XIcon } from '../icons/x';
import { createMessageToRoba } from '../../services/httpService';

export interface IMessageToRobaModalProps {
  open: boolean;
  songId: string;
  onClose: () => void;
}

export const MessageToRobaModal: React.FC<IMessageToRobaModalProps> = ({
  songId,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<{ roba_songId: string; roba_message: string }>({
    roba_songId: songId,
    roba_message: '',
  });
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);

  const hasErrors = (): { [field: string]: string } => {
    const errors: any = {};
    if (!formData.roba_message) {
      errors.roba_message = t('Dashboard.Songs.PleaseProvideMessage');
    }
    if (formData.roba_message.length > 850) {
      errors.roba_message = t('Dashboard.Songs.MessageMaxLengthError');
    }
    setErrors(errors);
    return errors;
  };

  const handleSendMessage = async () => {
    const errors = hasErrors();
    if (Object.keys(errors).length) {
      return;
    }
    setActionIsRunning(true);
    try {
      await createMessageToRoba(formData);
      toast.success(t('Dashboard.Songs.MessageSuccess'));
      onClose();
    } catch (e: any) {
      toast.error(t('Dashboard.Songs.MessageError'));
    } finally {
      setActionIsRunning(false);
    }
  };

  useEffect(() => {
    if (songId) {
      setFormData({ ...formData, roba_message: '' });
    }
  }, [songId]);

  useEffect(() => {
    if (open) {
      setFormData({ ...formData, roba_message: '' });
    }
  }, [open]);

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={!!open}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">{t('Dashboard.Songs.MessageToRoba')}</Typography>
        <IconButton color="inherit" onClick={onClose}>
          <XIcon fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          p: 2,
        }}
      >
        <TextField
          fullWidth
          autoComplete="off"
          variant="filled"
          label={t('Dashboard.Songs.Title')}
          name="roba_message"
          id="roba_message"
          inputProps={{ maxLength: 850 }}
          required
          multiline
          rows={8}
          value={formData.roba_message}
          helperText={errors.roba_message}
          error={!!errors.roba_message}
          disabled={actionIsRunning}
          onChange={(e: any) => setFormData({ ...formData, roba_message: e.target.value })}
        />
        <p>
          {t('Dashboard.Songs.MessageToRobaMaxChars').replace(
            '{{chars}}',
            formData.roba_message.length.toString(),
          )}
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          sx={{ m: 1 }}
          variant="contained"
          onClick={handleSendMessage}
          disabled={actionIsRunning}
        >
          {t('Dashboard.Songs.Send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
