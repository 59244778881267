import React from 'react';
import { Grid } from '@mui/material';

export interface IOverviewDisplayItemProps {
  label: string;
  value: string;
  paddingTop?: number;
}

export const OverviewDisplayItem: React.FC<IOverviewDisplayItemProps> = ({
  label,
  value,
  paddingTop,
}) => {
  return (
    <Grid
      container
      spacing={3}
      style={{
        paddingTop: paddingTop ? `${paddingTop}px` : '12px',
        fontSize: '14px',
      }}
    >
      <Grid item md={4} xs={12} style={{ fontWeight: 600 }}>
        {label}:
      </Grid>
      <Grid item md={8} xs={12}>
        {value}
      </Grid>
    </Grid>
  );
};
