import React from 'react';
import { AppBar, Box, Button, Container, IconButton, Link, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '../icons/menu';
import logo from '../../assets/images/logo.png';
import { LanguageButton } from './dashboard-navbar';

interface MainNavbarProps {
  onOpenSidebar?: () => void;
}

export const MainNavbar: React.FC<MainNavbarProps> = props => {
  const { onOpenSidebar } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        borderBottomColor: 'divider',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        color: 'text.secondary',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ minHeight: 64 }}>
          <img src={logo} alt="ROBA Music Publishing" />
          <Box sx={{ flexGrow: 1 }} />
          {/* Mobile Nav Icon
          <IconButton
            color="inherit"
            onClick={onOpenSidebar}
            sx={{
              display: {
                md: 'none',
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          */}
          <Box
            sx={{
              alignItems: 'center',
              display: {
                md: 'flex',
              },
            }}
          >
            <LanguageButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
