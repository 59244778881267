import React, { useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  Divider,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { ChevronLeft as ChevronLeftIcon } from '../../icons/chevron-left';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import {
  ESongState,
  ISong,
  ISongAlternativeTitle,
  ISongRecording,
  ISongRecordingArtist,
  ISongWriter,
  ISongWriterPublisher,
  getSongWriterRoles,
  songWriterAdaptorRoleValue,
  songWriterArrangerRoleValue,
} from '../../../interfaces/Song';
import styles from './SongFormOverviewDetails.module.scss';
import { OverviewDisplayItem } from '../OverviewDisplayItem';
import { Scrollbar } from '../../template/scrollbar';
import { IWriter } from '../../../interfaces/Writer';
import { useAuth } from '../../provider/AuthProvider';
import { getFormattedDate } from '../../../helper/DateHelper';
import { ISelectOption } from '../../../interfaces/SelectOption';
import { SongFormTab } from '../../forms/SongForm/SongForm';
import { IAgreementIPINumber } from '../../../interfaces/MyData';

export interface ISongFormOverviewDetailsProps {
  songData: ISong;
  actionIsRunning: boolean;
  fieldOptions: { [field: string]: ISelectOption[] } | undefined;
  titles: ISongAlternativeTitle[];
  writers: IWriter[];
  songWriters: ISongWriter[];
  recordings: ISongRecording[];
  disabled?: boolean;
}

export const SongFormOverviewDetails: React.FC<ISongFormOverviewDetailsProps> = ({
  songData,
  actionIsRunning,
  fieldOptions,
  titles,
  writers,
  songWriters,
  recordings,
  disabled,
}) => {
  const { t, i18n } = useTranslation();
  const { myData } = useAuth();

  const [openedRecordingRows, setOpenedRecordingRows] = useState<string[]>([]);

  const getSelectValue = (options: ISelectOption[], value: string): string => {
    const option = options.find((option: ISelectOption) => option.value === parseInt(value));
    if (!option) {
      return '';
    }
    return option.labels[i18n.language.includes('en') ? 'en' : 'de'];
  };

  const getSongWriterName = (songWriter: ISongWriter) => {
    const writer = writers.find(
      (writer: IWriter) => writer.roba_writerid === songWriter.roba_writer,
    );
    return `${writer?.roba_firstname || ''} ${writer?.roba_lastname}`;
  };

  const getDurationSecs = (durationsecs: string) => {
    if (!durationsecs) {
      return '';
    }
    if (parseInt(durationsecs) < 10) {
      return `0${durationsecs}`;
    }
    return durationsecs;
  };

  const toggleRecordingRow = (recordingId: string) => {
    let nextOpenedRecordingRows;
    if (openedRecordingRows.includes(recordingId)) {
      nextOpenedRecordingRows = openedRecordingRows.filter((rId: string) => rId !== recordingId);
    } else {
      nextOpenedRecordingRows = [...openedRecordingRows, recordingId];
    }
    setOpenedRecordingRows(nextOpenedRecordingRows);
  };

  const getISRCValue = (value: string): string => {
    if (!value) {
      return '';
    }
    if (value.length < 3) {
      return value;
    }
    const firstSeg = value.substring(0, 2);
    const secondSeg = value.substring(2, 5);
    const thirdSeg = value.substring(5, 7);
    const fourthSeg = value.substring(7, 12);
    if (value.length < 6) {
      return `${firstSeg}-${secondSeg}`;
    }
    if (value.length < 8) {
      return `${firstSeg}-${secondSeg}-${thirdSeg}`;
    }
    return `${firstSeg}-${secondSeg}-${thirdSeg}-${fourthSeg}`;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography
            variant="h2"
            style={{
              fontSize: '16px',
              textTransform: 'none',
              marginBottom: '2px',
            }}
          >
            {t('Dashboard.Songs.SongData')}
          </Typography>
          <Grid item md={6} xs={12}>
            <OverviewDisplayItem label="ID" value={songData?.roba_songid as string} />
          </Grid>
          <Grid item md={6} xs={12}>
            <OverviewDisplayItem label={t('Dashboard.Songs.Title')} value={songData?.roba_title} />
          </Grid>
          <Grid item md={6} xs={12} />
          <Grid item md={6} xs={12}>
            <OverviewDisplayItem
              label={t('Dashboard.Songs.Duration')}
              value={`${songData.roba_durationmins || ''}:${getDurationSecs(
                songData.roba_durationsecs,
              )}`}
            />
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            variant="h2"
            style={{
              fontSize: '16px',
              textTransform: 'none',
              marginBottom: '2px',
            }}
          >
            {t('Dashboard.Songs.AlternateTitles')}
          </Typography>
          {titles
            .filter((title: ISongAlternativeTitle) => !title.deleted)
            .map((title: ISongAlternativeTitle) => (
              <Grid item xs={12} style={{ paddingTop: '12px', fontSize: '14px' }}>
                {title.roba_title}
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Typography
        variant="h2"
        style={{
          fontSize: '16px',
          textTransform: 'none',
          marginTop: '48px',
          marginBottom: '12px',
        }}
      >
        {t('Dashboard.Songs.WriterSplits')}
        <Link
          to={`/dashboard/songs/edit/${songData.roba_songid}/${SongFormTab.Writers}`}
          style={{ textDecoration: 'none', marginLeft: '6px', position: 'relative', top: '-4px' }}
        >
          {songData?.statuscode !== ESongState.Processed && (
            <IconButton disabled={actionIsRunning}>
              <EditIcon />
            </IconButton>
          )}
        </Link>
      </Typography>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow
              style={{
                borderLeft: '1px solid #F3F4F6',
                borderRight: '1px solid #F3F4F6',
              }}
            >
              <TableCell style={{ minWidth: '160px', maxWidth: '160px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.WriterName')}
              </TableCell>
              <TableCell style={{ minWidth: '110px', maxWidth: '110px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.Role')}
              </TableCell>
              <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.ShareComposition')}
              </TableCell>
              <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.ShareText')}
              </TableCell>
              {songWriters.some(
                (songWriter: ISongWriter) =>
                  !songWriter.deleted && songWriter.roba_role === songWriterArrangerRoleValue,
              ) && (
                <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
                  {t('Dashboard.Songs.ShareEditor')}
                </TableCell>
              )}
              {songWriters.some(
                (songWriter: ISongWriter) =>
                  !songWriter.deleted && songWriter.roba_role === songWriterAdaptorRoleValue,
              ) && (
                <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
                  {t('Dashboard.Songs.ShareTextEditor')}
                </TableCell>
              )}
              <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                Controlled
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {songWriters.map((songWriter: ISongWriter, index: number) => (
              <>
                <TableRow
                  key={index}
                  style={{
                    borderLeft: '1px solid #F3F4F6',
                    borderRight: '1px solid #F3F4F6',
                  }}
                >
                  <TableCell
                    style={{
                      minWidth: '160px',
                      maxWidth: '160px',
                      overflow: 'hidden',
                      borderBottom: songWriter.publisher.length ? 'none' : '1px solid #F3F4F6',
                    }}
                  >
                    {getSongWriterName(songWriter)}
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: '110px',
                      maxWidth: '110px',
                      overflow: 'hidden',
                      borderBottom: songWriter.publisher.length ? 'none' : '1px solid #F3F4F6',
                    }}
                  >
                    {
                      getSongWriterRoles(t).find(
                        (songWriterRole: { title: string; value: number }) =>
                          songWriterRole.value === songWriter.roba_role,
                      )?.title
                    }
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: '80px',
                      maxWidth: '80px',
                      overflow: 'hidden',
                      borderBottom: songWriter.publisher.length ? 'none' : '1px solid #F3F4F6',
                    }}
                  >
                    {songWriter.roba_share} %
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: '80px',
                      maxWidth: '80px',
                      overflow: 'hidden',
                      borderBottom: songWriter.publisher.length ? 'none' : '1px solid #F3F4F6',
                    }}
                  >
                    {songWriter.roba_share_text} %
                  </TableCell>
                  {songWriters.some(
                    (songWriter: ISongWriter) =>
                      !songWriter.deleted && songWriter.roba_role === songWriterArrangerRoleValue,
                  ) && (
                    <TableCell
                      style={{
                        minWidth: '80px',
                        maxWidth: '80px',
                        overflow: 'hidden',
                        borderBottom: songWriter.publisher.length ? 'none' : '1px solid #F3F4F6',
                      }}
                    >
                      {songWriter.roba_share_editor} %
                    </TableCell>
                  )}
                  {songWriters.some(
                    (songWriter: ISongWriter) =>
                      !songWriter.deleted && songWriter.roba_role === songWriterAdaptorRoleValue,
                  ) && (
                    <TableCell
                      style={{
                        minWidth: '80px',
                        maxWidth: '80px',
                        overflow: 'hidden',
                        borderBottom: songWriter.publisher.length ? 'none' : '1px solid #F3F4F6',
                      }}
                    >
                      {songWriter.roba_share_editor_text} %
                    </TableCell>
                  )}
                  <TableCell
                    style={{
                      minWidth: '80px',
                      maxWidth: '80px',
                      overflow: 'hidden',
                      borderBottom: songWriter.publisher.length ? 'none' : '1px solid #F3F4F6',
                    }}
                  >
                    {songWriter.roba_iscollecting
                      ? t('Dashboard.Common.Yes')
                      : t('Dashboard.Common.No')}
                  </TableCell>
                </TableRow>
                {!!songWriter.publisher.length && (
                  <TableRow
                    style={{
                      borderLeft: '1px solid #F3F4F6',
                      borderRight: '1px solid #F3F4F6',
                    }}
                  >
                    <TableCell colSpan={7}>
                      <CardContent style={{ padding: '0px' }}>
                        <Box>
                          <Grid container>
                            <Divider sx={{ my: 2 }} />
                            <Table sx={{ minWidth: 700 }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      minWidth: '200px',
                                      maxWidth: '200px',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {t('Dashboard.Songs.Publisher')}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      minWidth: '200px',
                                      maxWidth: '200px',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {t('Dashboard.Writers.IPINumber')}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      minWidth: '200px',
                                      maxWidth: '200px',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    Share
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {songWriter.publisher.map(
                                  (sWPublisher: ISongWriterPublisher, indexSWPublisher: number) => (
                                    <TableRow key={indexSWPublisher}>
                                      <TableCell
                                        style={{
                                          borderBottom:
                                            songWriter.publisher.length === indexSWPublisher + 1
                                              ? 'none'
                                              : '1px solid #F3F4F6',
                                        }}
                                      >
                                        {sWPublisher.roba_unmanagedpublishername
                                          ? sWPublisher.roba_unmanagedpublishername
                                          : myData?.agreementIpiNumbers?.find(
                                              (agreementIpiNumber: IAgreementIPINumber) =>
                                                agreementIpiNumber.roba_ipinummerid ===
                                                sWPublisher.roba_agreementipiid,
                                            )?.roba_ipiname}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          borderBottom:
                                            songWriter.publisher.length === indexSWPublisher + 1
                                              ? 'none'
                                              : '1px solid #F3F4F6',
                                        }}
                                      >
                                        {sWPublisher.roba_unmanagedpublisheripinumber
                                          ? sWPublisher.roba_unmanagedpublisheripinumber
                                          : myData?.agreementIpiNumbers?.find(
                                              (agreementIpiNumber: IAgreementIPINumber) =>
                                                agreementIpiNumber.roba_ipinummerid ===
                                                sWPublisher.roba_agreementipiid,
                                            )?.roba_ipinummer}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          borderBottom:
                                            songWriter.publisher.length === indexSWPublisher + 1
                                              ? 'none'
                                              : '1px solid #F3F4F6',
                                        }}
                                      >
                                        {sWPublisher.roba_share} %
                                      </TableCell>
                                    </TableRow>
                                  ),
                                )}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Box>
                      </CardContent>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      <Typography
        variant="h2"
        style={{
          fontSize: '16px',
          textTransform: 'none',
          marginTop: '36px',
          marginBottom: '12px',
        }}
      >
        {t('Dashboard.Songs.Recordings')}
        <Link
          to={`/dashboard/songs/edit/${songData.roba_songid}/${SongFormTab.Recordings}`}
          style={{ textDecoration: 'none', marginLeft: '6px', position: 'relative', top: '-4px' }}
        >
          {songData?.statuscode !== ESongState.Processed && (
            <IconButton disabled={actionIsRunning}>
              <EditIcon />
            </IconButton>
          )}
        </Link>
      </Typography>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: '160px', maxWidth: '160px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.Title')}
              </TableCell>
              <TableCell style={{ minWidth: '110px', maxWidth: '110px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.Version')}
              </TableCell>
              <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.ISRC')}
              </TableCell>
              <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.Artist')}
              </TableCell>
              <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.Album')}
              </TableCell>
              <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                {t('Dashboard.Songs.ReleaseDateShort')}
              </TableCell>
              <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recordings.map((recording: ISongRecording, index: number) => (
              <>
                <TableRow key={index}>
                  <TableCell style={{ minWidth: '160px', maxWidth: '160px', overflow: 'hidden' }}>
                    {recording.roba_title}
                  </TableCell>
                  <TableCell style={{ minWidth: '110px', maxWidth: '110px', overflow: 'hidden' }}>
                    {recording.roba_mixversion}
                  </TableCell>
                  <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                    {recording.roba_isrc}
                  </TableCell>
                  <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                    {recording.artists
                      ?.map((artist: ISongRecordingArtist) => artist.roba_name)
                      .join(', ')}
                  </TableCell>
                  <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                    {recording.roba_album}
                  </TableCell>
                  <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                    {getFormattedDate(recording.roba_album_releasedon)}
                  </TableCell>
                  <TableCell style={{ minWidth: '80px', maxWidth: '80px', overflow: 'hidden' }}>
                    <Button
                      disabled={actionIsRunning}
                      onClick={() => toggleRecordingRow(recording.roba_recordingid as string)}
                    >
                      {openedRecordingRows.includes(recording.roba_recordingid as string) ? (
                        <ChevronDownIcon fontSize="small" />
                      ) : (
                        <ChevronLeftIcon fontSize="small" />
                      )}
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
                {openedRecordingRows.includes(recording.roba_recordingid as string) && (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      sx={{
                        p: 0,
                        position: 'relative',
                        '&:after': {
                          position: 'absolute',
                          content: '" "',
                          top: 0,
                          left: 0,
                          backgroundColor: 'rgb(243, 244, 246)',
                          width: 2,
                          height: 'calc(100% + 1px)',
                        },
                      }}
                    >
                      <CardContent style={{ padding: '16px 24px' }}>
                        <Box>
                          <Grid container>
                            <Grid item md={12} xs={12}>
                              <Typography
                                variant="h3"
                                style={{
                                  fontSize: '16px',
                                  textTransform: 'none',
                                  marginTop: '16px',
                                  marginBottom: '0px',
                                }}
                              >
                                Asset information
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label={t('Dashboard.Songs.MainRecording')}
                                value={
                                  recording?.roba_ismainrecording
                                    ? t('Dashboard.Common.Yes')
                                    : t('Dashboard.Common.No')
                                }
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label="Production Music / Library Music"
                                value={
                                  recording?.roba_islibrary
                                    ? t('Dashboard.Common.Yes')
                                    : t('Dashboard.Common.No')
                                }
                              />
                            </Grid>
                            <Grid item md={4} xs={12} />
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label="Type of Recording"
                                value={getSelectValue(
                                  fieldOptions?.roba_type as ISelectOption[],
                                  recording?.roba_type,
                                )}
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label="Ist das Recording eine Auftragskomposition"
                                value={getSelectValue(
                                  fieldOptions?.roba_type_commission as ISelectOption[],
                                  recording?.roba_type_commission as string,
                                )}
                              />
                            </Grid>
                            <Grid item md={4} xs={12} />
                            <Grid item md={12} xs={12}>
                              <Typography
                                variant="h3"
                                style={{
                                  fontSize: '16px',
                                  textTransform: 'none',
                                  marginTop: '16px',
                                  marginBottom: '0px',
                                }}
                              >
                                Recording information
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label={t('Dashboard.Songs.Title')}
                                value={recording?.roba_title}
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label={t('Dashboard.Songs.Version')}
                                value={recording?.roba_mixversion}
                              />
                            </Grid>
                            <Grid item md={4} xs={12} />
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label={t('Dashboard.Songs.ISRC')}
                                value={getISRCValue(recording.roba_isrc)}
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label={t('Dashboard.Songs.Duration')}
                                value={`${recording?.roba_durationmins}:${recording?.roba_durationsecs}`}
                              />
                            </Grid>
                            <Grid item md={4} xs={12} />
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label={t('Dashboard.Songs.TitleLanguage')}
                                value={getSelectValue(
                                  fieldOptions?.roba_title_language as ISelectOption[],
                                  recording?.roba_title_language as string,
                                )}
                              />
                            </Grid>
                            <Grid item md={4} xs={12} />
                            {recording.roba_imageurl && (
                              <>
                                <Grid item md={12} xs={12}>
                                  <Typography
                                    variant="h3"
                                    style={{
                                      fontSize: '16px',
                                      textTransform: 'none',
                                      marginTop: '16px',
                                      marginBottom: '16px',
                                    }}
                                  >
                                    Cover / Artwork
                                  </Typography>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <a
                                    href={recording.roba_imageurl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={recording.roba_imageurl}
                                      alt={recording.roba_title}
                                      className={styles.cover}
                                    />
                                  </a>
                                </Grid>
                              </>
                            )}
                            <Grid item md={12} xs={12}>
                              <Typography
                                variant="h3"
                                style={{
                                  fontSize: '16px',
                                  textTransform: 'none',
                                  marginTop: '16px',
                                  marginBottom: '16px',
                                }}
                              >
                                Artist information
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Table sx={{ minWidth: 700 }} style={{ maxWidth: '1200px' }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        minWidth: '120px',
                                        maxWidth: '120px',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {t('Dashboard.Songs.Performer')}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        minWidth: '120px',
                                        maxWidth: '120px',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {t('Dashboard.Songs.PerformerRole')}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        minWidth: '100px',
                                        maxWidth: '100px',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {t('Dashboard.Songs.SpotifyArtistID')}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {(recording?.artists || []).map(
                                    (srArtist: ISongRecordingArtist, indexsrArtist: number) => (
                                      <TableRow key={indexsrArtist}>
                                        <TableCell
                                          style={{
                                            minWidth: '120px',
                                            maxWidth: '120px',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {srArtist.roba_name}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            minWidth: '100px',
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {getSelectValue(
                                            fieldOptions?.roba_artist_type as ISelectOption[],
                                            srArtist.roba_type as string,
                                          )}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            minWidth: '100px',
                                            maxWidth: '100px',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {srArtist.roba_spotifyid}
                                        </TableCell>
                                      </TableRow>
                                    ),
                                  )}
                                </TableBody>
                              </Table>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Typography
                                variant="h3"
                                style={{
                                  fontSize: '16px',
                                  textTransform: 'none',
                                  marginTop: '16px',
                                  marginBottom: '16px',
                                }}
                              >
                                Lyric information
                              </Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label={t('Dashboard.Songs.RecordingIsInstrumental')}
                                value={
                                  recording?.roba_isinstrumental
                                    ? t('Dashboard.Common.Yes')
                                    : t('Dashboard.Common.No')
                                }
                              />
                            </Grid>
                            <Grid item md={8} xs={12} />
                            <Grid item md={4} xs={12}>
                              <OverviewDisplayItem
                                label={t('Dashboard.Songs.LanguageOfLyrics')}
                                value={getSelectValue(
                                  fieldOptions?.roba_lyrics_language as ISelectOption[],
                                  recording?.roba_lyrics_language,
                                )}
                              />
                            </Grid>
                            <Grid item md={8} xs={12} />
                            <Grid item md={4} xs={12} style={{ paddingTop: '12px' }}>
                              <span style={{ fontWeight: 600 }}>
                                {t('Dashboard.Songs.Lyrics')}:
                              </span>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: recording?.roba_lyrics?.replace(/\r/g, '<br />'),
                                }}
                              />
                            </Grid>
                            <Grid item md={8} xs={12} />
                          </Grid>
                        </Box>
                      </CardContent>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </>
  );
};
