import React from 'react';
import { Container } from '@mui/material';
import { MainLayout } from '../template/main-layout';
import { RegisterForm } from '../forms/RegisterForm/RegisterForm';

export const RegisterPage: React.FC = () => (
  <MainLayout>
    <Container maxWidth="lg">
      <RegisterForm />
    </Container>
  </MainLayout>
);

export default RegisterPage;
