import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { DashboardLayout } from '../template/dashboard-layout';

export const Dashboard: React.FC = () => (
  <DashboardLayout>
    <Box
      component="main"
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1,
        py: 8,
      }}
    >
      <Outlet />
    </Box>
  </DashboardLayout>
);

export default Dashboard;
