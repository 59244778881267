import { validateRequiredFields } from '../../../helper/ValidationHelper';
import {
  IRegisterFormData,
  registerFormRequiredFields,
} from '../../../interfaces/RegisterFormData';

export const getRegisterFormErrors = (formData: IRegisterFormData, t: any) => {
  const errors = validateRequiredFields(registerFormRequiredFields, formData, t);

  if (formData.roba_accountname && formData.roba_accountname.length < 4) {
    errors.roba_accountname = t('RegisterForm.ValidationUser');
  }

  if (formData.roba_hash && formData.roba_hash.length < 8) {
    errors.roba_hash = t('RegisterForm.ValidationPassword');
  }

  if (!formData.checked_terms) {
    errors.checked_terms = t('RegisterForm.ConfirmTerms');
  }

  return errors;
};
