import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
  ISongWriter,
  songWriterAdaptorRoleValue,
  songWriterRoleAuthorValue,
  songWriterRoleComposerValue,
  getSongWriterRoles,
  ISongWriterPublisher,
  songWriterArrangerRoleValue,
} from '../../../../interfaces/Song';
import { IWriter } from '../../../../interfaces/Writer';
import { ChevronDown as ChevronDownIcon } from '../../../icons/chevron-down';
import { ChevronLeft as ChevronLeftIcon } from '../../../icons/chevron-left';
import { SongFormWritersAutocomplete } from './SongFormWritersAutocomplete';

export interface ISongFormWritersTableRowProps {
  open: boolean;
  songWriter: ISongWriter;
  songWriters: ISongWriter[];
  writers: IWriter[];
  actionIsRunning: boolean;
  errors: any;
  onSongWriterChange: (value: any, key: string, songWriterId: string) => void;
  onIsCollectingChange: (isCollecting: boolean, songWriterId: string) => void;
  onOpenSongWriter: (songWriterId: string) => void;
  onDeleteSongWriter: (songWriterId: string) => void;
  onEditWriter: (writerId: string) => void;
  onShowCreateWriterModal: (showCreateWriterModal: boolean, songWriterId: string) => void;
}

export const SongFormWritersTableRow: React.FC<ISongFormWritersTableRowProps> = ({
  open,
  songWriter,
  songWriters,
  writers,
  actionIsRunning,
  errors,
  onSongWriterChange,
  onIsCollectingChange,
  onOpenSongWriter,
  onDeleteSongWriter,
  onEditWriter,
  onShowCreateWriterModal,
}) => {
  const { t } = useTranslation();

  const getControlledValue = (isCollecting: boolean | null): number | null => {
    if (isCollecting === null) {
      return null;
    }
    return isCollecting ? 1 : 0;
  };

  const getPublisherError = () => {
    return (
      !!errors?.roba_iscollecting_publisher ||
      !!errors?.roba_swPublisher_publisher ||
      !!errors?.roba_swPublisher_share ||
      !!errors?.roba_total_share_publisher ||
      !!errors?.roba_swPublisher_name ||
      !!errors?.roba_swPublisher_ipi
    );
  };

  const getTablePublisherButtonInner = (songWriter: ISongWriter): any => {
    const publisher = songWriter.publisher.filter(
      (swPublisher: ISongWriterPublisher) => !swPublisher.deleted,
    );

    if (!publisher.length) {
      return t('Dashboard.Songs.AddPublisher');
    }

    const sumSharesPublisher = songWriter.publisher
      .filter((swPublisher: ISongWriterPublisher) => !swPublisher.deleted)
      .reduce((sumSharesPublisher: number, swPublisher: ISongWriterPublisher) => {
        return (
          sumSharesPublisher + (swPublisher.roba_share ? parseFloat(swPublisher.roba_share) : 0)
        );
      }, 0);

    if (
      publisher.some((p: ISongWriterPublisher) => !p.isThirdParty && !p.roba_agreementipiid) ||
      publisher.some(
        (p: ISongWriterPublisher) => p.isThirdParty && !p.roba_unmanagedpublishername,
      ) ||
      publisher.some((p: ISongWriterPublisher) => !p.roba_share) ||
      sumSharesPublisher !== 100
    ) {
      return t('Dashboard.Songs.Publisher');
    }

    return (
      <>
        {t('Dashboard.Songs.Publisher')}
        <CheckCircleOutlineIcon style={{ marginLeft: '6px' }} />
      </>
    );
  };

  const getIscollectingMissingFieldsError = (): string => {
    if (errors?.roba_iscollecting_missingFields) {
      const writer = writers.find(
        (writer: IWriter) => writer.roba_writerid === songWriter.roba_writer,
      );
      if (writer) {
        const hasValidAddress =
          !!writer?.roba_street1 &&
          !!writer?.roba_postalcode &&
          !!writer?.roba_city &&
          !!writer?.roba_country;
        if (!writer?.roba_email || !hasValidAddress) {
          return errors?.roba_iscollecting_missingFields;
        }
      }
    }
    return '';
  };

  return (
    <TableRow hover style={{ display: songWriter.deleted ? 'none' : 'table-row' }}>
      <TableCell style={{ minWidth: '330px', maxWidth: '330px', overflow: 'hidden' }}>
        <Box display="flex" alignItems="center">
          <SongFormWritersAutocomplete
            songWriter={songWriter}
            songWriters={songWriters}
            writers={writers}
            actionIsRunning={actionIsRunning}
            errors={errors}
            onSongWriterChange={onSongWriterChange}
          />
          {songWriter?.roba_writer ? (
            <IconButton
              style={{
                marginLeft: '4px',
                color: getIscollectingMissingFieldsError()
                  ? 'rgb(209, 67, 67)'
                  : 'rgb(107, 114, 128)',
              }}
              onClick={() => onEditWriter(songWriter?.roba_writer as string)}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <Button
              disabled={actionIsRunning}
              onClick={() => {
                onShowCreateWriterModal(true, songWriter.roba_songwritersid);
              }}
              variant="outlined"
              style={{ marginLeft: '16px' }}
            >
              {t('Dashboard.Songs.CreateWriter')}
            </Button>
          )}
        </Box>
      </TableCell>
      <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
        <FormControl fullWidth variant="filled">
          <InputLabel required>{t('Dashboard.Songs.SelectRole')}</InputLabel>
          <Select
            required
            variant="filled"
            label={t('Dashboard.Songs.SelectRole')}
            value={songWriter.roba_role}
            disabled={actionIsRunning}
            error={!!errors?.roba_role}
            onChange={(e: any) =>
              onSongWriterChange(e.target.value, 'roba_role', songWriter.roba_songwritersid)
            }
          >
            <MenuItem />
            {getSongWriterRoles(t).map((songWriterRole: { title: string; value: number }) => (
              <MenuItem key={songWriterRole.value} value={songWriterRole.value}>
                {songWriterRole.title}
              </MenuItem>
            ))}
          </Select>
          {errors?.roba_role && <FormHelperText error>{errors?.roba_role}</FormHelperText>}
        </FormControl>
      </TableCell>
      <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
        <TextField
          fullWidth
          required
          autoComplete="off"
          type="number"
          variant="filled"
          placeholder={t('Dashboard.Songs.Share')}
          value={songWriter.roba_share === null ? 0 : songWriter.roba_share}
          InputProps={{
            inputProps: { min: 1, max: 100, step: 1 },
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          disabled={
            actionIsRunning ||
            !songWriter.roba_role ||
            [
              songWriterRoleAuthorValue,
              songWriterArrangerRoleValue,
              songWriterAdaptorRoleValue,
            ].includes(songWriter.roba_role)
          }
          onChange={(e: any) => {
            let nextValue = Number.isNaN(e.target.value) ? 0 : e.target.value;
            if (nextValue < 0) {
              nextValue = 0;
            }
            if (nextValue > 100) {
              nextValue = 100;
            }
            onSongWriterChange(nextValue, 'roba_share', songWriter.roba_songwritersid);
          }}
          onFocus={() => {
            if (!songWriter.roba_share) {
              onSongWriterChange('', 'roba_share', songWriter.roba_songwritersid);
            }
          }}
          onBlur={() => {
            if (!songWriter.roba_share) {
              onSongWriterChange(null, 'roba_share', songWriter.roba_songwritersid);
            }
          }}
        />
      </TableCell>
      <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
        <TextField
          fullWidth
          required
          autoComplete="off"
          type="number"
          variant="filled"
          placeholder={t('Dashboard.Songs.Share')}
          value={songWriter.roba_share_text === null ? 0 : songWriter.roba_share_text}
          InputProps={{
            inputProps: { min: 1, max: 100, step: 1 },
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          disabled={
            actionIsRunning ||
            !songWriter.roba_role ||
            [
              songWriterRoleComposerValue,
              songWriterArrangerRoleValue,
              songWriterAdaptorRoleValue,
            ].includes(songWriter.roba_role)
          }
          onChange={(e: any) => {
            let nextValue = Number.isNaN(e.target.value) ? 0 : e.target.value;
            if (nextValue < 0) {
              nextValue = 0;
            }
            if (nextValue > 100) {
              nextValue = 100;
            }
            onSongWriterChange(nextValue, 'roba_share_text', songWriter.roba_songwritersid);
          }}
          onFocus={() => {
            if (!songWriter.roba_share_text) {
              onSongWriterChange('', 'roba_share_text', songWriter.roba_songwritersid);
            }
          }}
          onBlur={() => {
            if (!songWriter.roba_share_text) {
              onSongWriterChange(null, 'roba_share_text', songWriter.roba_songwritersid);
            }
          }}
        />
      </TableCell>
      {songWriters.some(
        (songWriter: ISongWriter) =>
          !songWriter.deleted && songWriter.roba_role === songWriterArrangerRoleValue,
      ) && (
        <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
          <TextField
            fullWidth
            required
            autoComplete="off"
            type="number"
            variant="filled"
            placeholder={t('Dashboard.Songs.Share')}
            value={songWriter.roba_share_editor === null ? 0 : songWriter.roba_share_editor}
            InputProps={{
              inputProps: { min: 1, max: 100, step: 1 },
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            disabled={
              actionIsRunning ||
              !songWriter.roba_role ||
              songWriter.roba_role !== songWriterArrangerRoleValue
            }
            onChange={(e: any) => {
              let nextValue = Number.isNaN(e.target.value) ? 0 : e.target.value;
              if (nextValue < 0) {
                nextValue = 0;
              }
              if (nextValue > 100) {
                nextValue = 100;
              }
              onSongWriterChange(nextValue, 'roba_share_editor', songWriter.roba_songwritersid);
            }}
            onFocus={() => {
              if (!songWriter.roba_share_editor) {
                onSongWriterChange('', 'roba_share_editor', songWriter.roba_songwritersid);
              }
            }}
            onBlur={() => {
              if (!songWriter.roba_share_editor) {
                onSongWriterChange(null, 'roba_share_editor', songWriter.roba_songwritersid);
              }
            }}
          />
        </TableCell>
      )}
      {songWriters.some(
        (songWriter: ISongWriter) =>
          !songWriter.deleted && songWriter.roba_role === songWriterAdaptorRoleValue,
      ) && (
        <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
          <TextField
            fullWidth
            required
            autoComplete="off"
            type="number"
            variant="filled"
            placeholder={t('Dashboard.Songs.Share')}
            value={
              songWriter.roba_share_editor_text === null ? 0 : songWriter.roba_share_editor_text
            }
            InputProps={{
              inputProps: { min: 1, max: 100, step: 1 },
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            disabled={
              actionIsRunning ||
              !songWriter.roba_role ||
              songWriter.roba_role !== songWriterAdaptorRoleValue
            }
            onChange={(e: any) => {
              let nextValue = Number.isNaN(e.target.value) ? 0 : e.target.value;
              if (nextValue < 0) {
                nextValue = 0;
              }
              if (nextValue > 100) {
                nextValue = 100;
              }
              onSongWriterChange(
                nextValue,
                'roba_share_editor_text',
                songWriter.roba_songwritersid,
              );
            }}
            onFocus={() => {
              if (!songWriter.roba_share_editor_text) {
                onSongWriterChange('', 'roba_share_editor_text', songWriter.roba_songwritersid);
              }
            }}
            onBlur={() => {
              if (!songWriter.roba_share_editor_text) {
                onSongWriterChange(null, 'roba_share_editor_text', songWriter.roba_songwritersid);
              }
            }}
          />
        </TableCell>
      )}
      {/*
      <TableCell style={{ minWidth: '30px', maxWidth: '30px', overflow: 'hidden' }}>
        {getSongWritersTotalShare(songWriter, songWriters).toFixed(2).toString()}
      </TableCell>
          */}
      <TableCell style={{ minWidth: '100px', maxWidth: '100px', overflow: 'hidden' }}>
        <FormControl fullWidth variant="filled">
          <InputLabel required>{t('Dashboard.Common.Select')}</InputLabel>
          <Select
            required
            variant="filled"
            label={t('Dashboard.Common.Select')}
            value={getControlledValue(songWriter.roba_iscollecting)}
            disabled={actionIsRunning}
            error={!!errors?.roba_iscollecting}
            onChange={(e: any) => {
              onIsCollectingChange(e.target.value === 1, songWriter.roba_songwritersid);
            }}
          >
            <MenuItem />
            <MenuItem value={1}>{t('Dashboard.Common.Yes')}</MenuItem>
            <MenuItem value={0}>{t('Dashboard.Common.No')}</MenuItem>
          </Select>
          {errors?.roba_iscollecting && (
            <FormHelperText error>{errors?.roba_iscollecting}</FormHelperText>
          )}
        </FormControl>
      </TableCell>
      <TableCell style={{ minWidth: '90px', maxWidth: '90px', overflow: 'hidden' }}>
        {songWriter.roba_iscollecting && (
          <Button
            disabled={actionIsRunning}
            onClick={() => onOpenSongWriter(songWriter.roba_songwritersid)}
            color={getPublisherError() ? 'error' : 'secondary'}
          >
            {open ? <ChevronDownIcon fontSize="small" /> : <ChevronLeftIcon fontSize="small" />}
            {getTablePublisherButtonInner(songWriter)}
          </Button>
        )}
      </TableCell>
      <TableCell style={{ minWidth: '50px', maxWidth: '50px', overflow: 'hidden' }} align="right">
        <IconButton disabled={actionIsRunning}>
          <DeleteOutlineIcon
            onClick={() => onDeleteSongWriter(songWriter.roba_songwritersid)}
            style={{ cursor: 'pointer' }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
