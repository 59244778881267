import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import styles from './App.module.scss';
import Dashboard from './components/pages/Dashboard';
import StartPage from './components/pages/StartPage';
import { createTheme } from './theme';
import { WriterList } from './components/lists/WriterList';
import { WriterForm } from './components/forms/WriterForm/WriterForm';
import { SongList } from './components/lists/SongList';
import { SongForm } from './components/forms/SongForm/SongForm';
import { StartPage as DashboardStartPage } from './components/dashboardPages/StartPage/StartPage';
import LoginPage from './components/pages/LoginPage';
import RegisterPage from './components/pages/RegisterPage';
import { checkToken } from './services/httpService';
import { AuthProvider, useAuth } from './components/provider/AuthProvider';
import { Account } from './components/dashboardPages/Settings/Account/Account';
import { Forms } from './components/dashboardPages/Forms/Forms';
import { Help } from './components/dashboardPages/Help/Help';

const RequireAuth = ({ children }: any) => {
  const { setUserId } = useAuth();
  const [redirect, setRedirect] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const checkAuth = async () => {
    try {
      const user = await checkToken();
      if (setUserId) {
        setUserId(user.userId);
      }
    } catch {
      setRedirect(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  if (loading) {
    return null;
  }

  if (redirect) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const App = () => {
  return (
    <div className={styles.app}>
      <Toaster />
      <ThemeProvider
        theme={createTheme({
          direction: 'ltr',
          responsiveFontSizes: true,
          mode: 'light',
        })}
      >
        <AuthProvider>
          <Routes>
            {/* <Route path="/" element={<StartPage />} /> */}
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/redeem" element={<RegisterPage />} />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            >
              <Route path="start" element={<DashboardStartPage />} />
              <Route path="forms" element={<Forms />} />
              <Route path="help/faq" element={<Help />} />
              <Route path="help/contact" element={<Help />} />
              <Route path="writers" element={<WriterList />} />
              <Route path="writers/new" element={<WriterForm />} />
              <Route path="writers/edit/:id" element={<WriterForm />} />
              <Route path="songs" element={<SongList />} />
              <Route path="songs/new" element={<SongForm />} />
              <Route path="songs/edit/:id/:tab" element={<SongForm />} />
              <Route path="settings/account" element={<Account />} />
            </Route>
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
