import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ISongWriter,
  songWriterAdaptorRoleValue,
  songWriterArrangerRoleValue,
} from '../../../../interfaces/Song';

export interface ISongFormWritersTableHeadProps {
  songWriters: ISongWriter[];
}

export const SongFormWritersTableHead: React.FC<ISongFormWritersTableHeadProps> = ({
  songWriters,
}) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
          {t('Dashboard.Songs.WriterName')}
        </TableCell>
        <TableCell style={{ minWidth: '200px', maxWidth: '200px', overflow: 'hidden' }}>
          {t('Dashboard.Songs.Role')}
        </TableCell>
        <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
          {t('Dashboard.Songs.ShareComposition')}
        </TableCell>
        <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
          {t('Dashboard.Songs.ShareText')}
        </TableCell>
        {songWriters.some(
          (songWriter: ISongWriter) =>
            !songWriter.deleted && songWriter.roba_role === songWriterArrangerRoleValue,
        ) && (
          <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
            {t('Dashboard.Songs.ShareEditor')}
          </TableCell>
        )}
        {songWriters.some(
          (songWriter: ISongWriter) =>
            !songWriter.deleted && songWriter.roba_role === songWriterAdaptorRoleValue,
        ) && (
          <TableCell style={{ minWidth: '70px', maxWidth: '70px', overflow: 'hidden' }}>
            {t('Dashboard.Songs.ShareTextEditor')}
          </TableCell>
        )}
        {/*
        <TableCell style={{ minWidth: '55px', maxWidth: '55px', overflow: 'hidden' }}>
          {t('Dashboard.Songs.ShareInTotal')}
        </TableCell>
        */}
        <TableCell style={{ minWidth: '50px', maxWidth: '50px', overflow: 'hidden' }}>
          {t('Dashboard.Songs.Controlled')}
        </TableCell>
        <TableCell style={{ minWidth: '90px', maxWidth: '90px', overflow: 'hidden' }}>
          {t('Dashboard.Songs.Publisher')}
        </TableCell>
        <TableCell style={{ minWidth: '50px', maxWidth: '50px', overflow: 'hidden' }} align="right">
          {t('Dashboard.Common.Delete')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
