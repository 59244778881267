import React from 'react';
import { Box, Button, Drawer, Link, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

interface MainSidebarProps {
  onClose?: () => void;
  open?: boolean;
}

const MainSidebarLink = styled(Link)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: 'block',
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const MainSidebar: React.FC<MainSidebarProps> = props => {
  const { onClose, open } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const handlePathChange = () => {
    if (open) {
      onClose?.();
    }
  };

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={!lgUp && open}
      PaperProps={{ sx: { width: 256 } }}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 100,
      }}
      variant="temporary"
    >
      <Box sx={{ p: 2 }}>
        <Link href="https://www.google.com">
          <MainSidebarLink color="textSecondary" underline="none" variant="subtitle2">
            Live Demo
          </MainSidebarLink>
        </Link>
        <Link href="https://www.google.com">
          <MainSidebarLink color="textSecondary" underline="none" variant="subtitle2">
            Components
          </MainSidebarLink>
        </Link>
        <Link href="https://www.google.com">
          <MainSidebarLink color="textSecondary" underline="none" variant="subtitle2">
            Documentation
          </MainSidebarLink>
        </Link>
        <Button
          component="a"
          fullWidth
          href="https://www.google.com"
          sx={{ mt: 1.5 }}
          target="_blank"
          variant="contained"
        >
          Login
        </Button>
      </Box>
    </Drawer>
  );
};
