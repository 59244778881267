import React from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';
import { useAuth } from '../../provider/AuthProvider';

export interface IHelpFormData {
  casetypecode: string;
  title: string;
  description: string;
}
export const HelpFAQ: React.FC = () => {
  const { t } = useTranslation();
  const { selectedUser } = useAuth();

  if (!selectedUser) {
    return (
      <Box
        sx={{
          mt: 20,
          mb: 30,
          textAlign: 'center',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '70vh' }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} sx={{ position: 'relative' }}>
        <Box sx={{ flex: '1 1 auto', minWidth: 0 }}>
          <Box>
            <Typography
              variant="h1"
              style={{
                maxWidth: '500px',
                marginBottom: '32px',
                fontSize: '36px',
                textTransform: 'none',
              }}
            >
              {t('Dashboard.Sidebar.FAQ')}
            </Typography>
          </Box>
          <Card>
            <CardContent>...</CardContent>
          </Card>
        </Box>
      </Stack>
    </Box>
  );
};
