import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { X as XIcon } from '../../icons/x';
import { ISongRecording, ISongRecordingArtist } from '../../../interfaces/Song';
import { Dropzone } from '../../lib/Dropzone';
import { downloadFile } from '../../../services/httpService';
import { SpotifySearch } from '../../lib/SpotifySearch';
import { ISelectOption } from '../../../interfaces/SelectOption';
import { SongRecordingModalLyricsInfo } from './SongRecordingModalLyricsInfo';
import { SongRecordingModalCover } from './SongRecordingModalCover';
import styles from './SongForm.module.scss';
import { SongRecordingModalArtists } from './SongRecordingModalArtists';

interface SongRecordingModalProps {
  open: boolean;
  mainRecording: ISongRecording | undefined;
  formData: ISongRecording;
  fieldOptions: { [field: string]: ISelectOption[] } | undefined;
  artists: ISongRecordingArtist[];
  errors: { [field: string]: string };
  actionIsRunning: boolean;
  actionIsRunningText: string;
  uploadProgress: number;
  onFormChange: (value: any, key: string) => void;
  onSave: (editing: boolean) => void;
  onClose: () => void;
  onSpotifySelect: (suggestion: any) => void;
  onArtistsChange: (artists: ISongRecordingArtist[]) => void;
  onDeleteRecordingFile?: (recordingId: string) => void;
}

export const SongRecordingModal: React.FC<SongRecordingModalProps> = ({
  open,
  mainRecording,
  formData,
  fieldOptions,
  artists,
  errors,
  actionIsRunning,
  actionIsRunningText,
  uploadProgress,
  onFormChange,
  onSave,
  onClose,
  onDeleteRecordingFile,
  onSpotifySelect,
  onArtistsChange,
}) => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState<string>('de');
  const [downloading, setDownloading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (i18n.language === 'de-DE') {
      setLocale('de');
    } else {
      setLocale('en');
    }
  }, [i18n.language]);

  const handleFileDownload = async (recordingId: string) => {
    setDownloading(true);
    try {
      const response: any = await downloadFile(recordingId, 'roba_recording');
      const fileName = response.headers['content-disposition']
        .split(';')
        .find((n: string) => n.includes('filename='))
        .replace('filename=', '')
        .replaceAll("'", '')
        .replaceAll('"', '');
      saveAs(response.data, fileName);
    } catch {
      toast.error(t('Dashboard.Songs.RecordingsDownloadError'));
    } finally {
      setDownloading(false);
    }
  };

  const getISRCValue = (value: string): string => {
    if (!value) {
      return '';
    }
    if (value.length < 3) {
      return value;
    }
    const firstSeg = value.substring(0, 2);
    const secondSeg = value.substring(2, 5);
    const thirdSeg = value.substring(5, 7);
    const fourthSeg = value.substring(7, 12);
    if (value.length < 6) {
      return `${firstSeg}-${secondSeg}`;
    }
    if (value.length < 8) {
      return `${firstSeg}-${secondSeg}-${thirdSeg}`;
    }
    return `${firstSeg}-${secondSeg}-${thirdSeg}-${fourthSeg}`;
  };

  const handleISRCValueChange = (value: string): void => {
    if (value.length > 15) {
      return;
    }
    onFormChange(value.toUpperCase().replaceAll('-', ''), 'roba_isrc');
  };

  const handleFileReject = () => {
    toast.error(t('Dashboard.Songs.FileNotExpectedFormat'));
  };

  const handleIsMainRecordingChange = (checked: boolean) => {
    if (checked) {
      // Type of Recording: Original
      onFormChange(722040000, 'roba_type');
    }
    onFormChange(checked, 'roba_ismainrecording');
  };

  const handleClose = () => {
    setEditing(false);
    onClose();
  };

  const handleSave = () => {
    onSave(editing);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={!!open}
      className={styles.songRecordingModal}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">
          {formData.roba_recordingid
            ? t('Dashboard.Songs.EditRecording')
            : t('Dashboard.Songs.AddRecording')}
        </Typography>
        <IconButton color="inherit" onClick={handleClose}>
          <XIcon fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          p: 5,
        }}
      >
        {/* Spotify Search */}
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                mb: 2,
              }}
            >
              <SpotifySearch disabled={actionIsRunning} onSelect={onSpotifySelect} />
            </Box>
          </Grid>
        </Grid>

        {/* Audiofile */}
        <Grid
          container
          spacing={3}
          sx={{
            mb: 2,
          }}
        >
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              mt: 3,
            }}
          >
            <Typography variant="h6">Audiofile</Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            {formData.roba_recordingid && formData.uploadedFileName && !editing && (
              <Grid item md={12} xs={12}>
                <InputLabel style={{ marginBottom: '10px' }}>
                  {t('Dashboard.Songs.UploadedFile')}
                </InputLabel>
                <Box sx={{ position: 'relative' }}>
                  <Button
                    variant="outlined"
                    startIcon={<PlayCircleOutlineIcon />}
                    disabled={actionIsRunning || downloading}
                    onClick={() => handleFileDownload(formData.roba_recordingid as string)}
                  >
                    {formData.uploadedFileName}
                  </Button>
                  <IconButton
                    title={t('Dashboard.Songs.EditFile')}
                    disabled={actionIsRunning || downloading}
                  >
                    <EditIcon onClick={() => setEditing(true)} style={{ cursor: 'pointer' }} />
                  </IconButton>
                  {onDeleteRecordingFile && (
                    <IconButton
                      title={t('Dashboard.Songs.DeleteFile')}
                      disabled={actionIsRunning || downloading}
                    >
                      <DeleteOutlineIcon
                        onClick={() => onDeleteRecordingFile(formData.roba_recordingid as string)}
                        style={{ cursor: 'pointer' }}
                      />
                    </IconButton>
                  )}
                  {downloading && (
                    <>
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'relative',
                          top: '5px',
                          marginLeft: '10px',
                        }}
                      />
                      <Typography
                        sx={{ m: 1 }}
                        style={{
                          display: 'inline-block',
                          marginLeft: '12px',
                          paddingRight: '10px',
                        }}
                      >
                        {t('Dashboard.Songs.RecordingDownload')}
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
            )}
            {(!formData.uploadedFileName || editing) && (
              <Grid item md={12} xs={12}>
                {formData.file && (
                  <Typography style={{ marginBottom: '6px', fontWeight: 'bold' }}>
                    {t('Dashboard.Songs.SelectedFile')}: {formData.file.name}
                    <IconButton title={t('Dashboard.Songs.DeleteFile')} disabled={actionIsRunning}>
                      <DeleteOutlineIcon
                        onClick={() => onFormChange(null, 'file')}
                        style={{ cursor: 'pointer' }}
                      />
                    </IconButton>
                  </Typography>
                )}
                <Dropzone
                  accept={{
                    'audio/mpeg': [],
                    'audio/wav': [],
                    'audio/x-wav': [],
                  }}
                  disabled={actionIsRunning}
                  onChange={(files: File[]) => onFormChange(files[0], 'file')}
                  onFileReject={handleFileReject}
                />
                <FormHelperText>{t('Dashboard.Songs.RecordingUploadHint')}</FormHelperText>
                <p>{t('Dashboard.Songs.UploadHint')}</p>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Asset Information */}
        <Grid container spacing={3}>
          <Grid item md={6} />
          <Grid item md={12} xs={12}>
            <Typography variant="h6">Asset information</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox name="roba_ismainrecording" checked={formData.roba_ismainrecording} />
                }
                label={t('Dashboard.Songs.MainRecording')}
                disabled={
                  actionIsRunning ||
                  (!formData.roba_ismainrecording &&
                    !!mainRecording &&
                    mainRecording?.roba_recordingid !== formData.roba_recordingid)
                }
                onChange={(e: any) => handleIsMainRecordingChange(e.target.checked)}
              />
              {!formData.roba_ismainrecording &&
                !!mainRecording &&
                mainRecording?.roba_recordingid !== formData.roba_recordingid && (
                  <FormHelperText>{t('Dashboard.Songs.MainRecordingAlreadyExists')}</FormHelperText>
                )}
              {errors.roba_ismainrecording && (
                <FormHelperText error>{errors.roba_ismainrecording}</FormHelperText>
              )}
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="roba_islibrary" checked={formData.roba_islibrary} />}
                label="Production Music / Library Music"
                disabled={actionIsRunning}
                onChange={(e: any) => onFormChange(e.target.checked, 'roba_islibrary')}
              />
              {errors.roba_islibrary && (
                <FormHelperText error>{errors.roba_islibrary}</FormHelperText>
              )}
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth variant="filled" id="roba_type">
              <InputLabel>Type of Recording</InputLabel>
              <Select
                variant="filled"
                label="Type of Recording"
                value={formData.roba_type}
                error={!!errors.roba_type}
                disabled={actionIsRunning}
                onChange={(e: any) => onFormChange(e.target.value, 'roba_type')}
              >
                {!!formData.roba_type && <MenuItem value="">-</MenuItem>}
                {fieldOptions?.roba_type?.map((fieldOption: ISelectOption) => (
                  <MenuItem key={fieldOption.value} value={fieldOption.value}>
                    {fieldOption.labels[i18n.language.includes('en') ? 'en' : 'de']}
                  </MenuItem>
                ))}
              </Select>
              {errors.roba_type && <FormHelperText error>{errors.roba_type}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth variant="filled">
              <InputLabel>Ist das Recording eine Auftragskomposition</InputLabel>
              <Select
                variant="filled"
                label="Ist das Recording eine Auftragskomposition"
                value={formData.roba_type_commission}
                error={!!errors.roba_type_commission}
                disabled={actionIsRunning}
                onChange={(e: any) => onFormChange(e.target.value, 'roba_type_commission')}
              >
                {!!formData.roba_type_commission && <MenuItem value="">-</MenuItem>}
                {fieldOptions?.roba_type_commission?.map((fieldOption: ISelectOption) => (
                  <MenuItem key={fieldOption.value} value={fieldOption.value}>
                    {fieldOption.labels[i18n.language.includes('en') ? 'en' : 'de']}
                  </MenuItem>
                ))}
              </Select>
              {errors.roba_type_commission && (
                <FormHelperText error>{errors.roba_type_commission}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        {/* Recording Information, Image */}
        <Grid
          container
          spacing={3}
          sx={{
            mt: 3,
          }}
        >
          <Grid item md={6} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Recording information</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.Title')}
                  name="roba_title"
                  id="roba_title"
                  required
                  value={formData.roba_title}
                  helperText={errors.roba_title}
                  error={!!errors.roba_title}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_title')}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.Version')}
                  name="roba_mixversion"
                  value={formData.roba_mixversion}
                  helperText={errors.roba_mixversion}
                  error={!!errors.roba_mixversion}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_mixversion')}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.ISRC')}
                  name="roba_isrc"
                  id="roba_isrc"
                  value={getISRCValue(formData.roba_isrc)}
                  helperText={errors.roba_isrc ? errors.roba_isrc : t('Dashboard.Songs.ISRCHint')}
                  error={!!errors.roba_isrc}
                  disabled={actionIsRunning}
                  onChange={(e: any) => handleISRCValueChange(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  sx={{
                    width: '90px',
                  }}
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.RuntimeMinutes')}
                  name="roba_durationmins"
                  required
                  value={formData.roba_durationmins}
                  helperText={errors.roba_durationmins}
                  error={!!errors.roba_durationmins}
                  disabled={actionIsRunning}
                  onChange={(e: any) => {
                    if (e.target.value?.length === 4) {
                      return;
                    }
                    if (parseInt(e.target.value) < 0) {
                      onFormChange(0, 'roba_durationmins');
                      return;
                    }
                    onFormChange(e.target.value, 'roba_durationmins');
                  }}
                  onBlur={(e: any) => {
                    if (e.target.value?.length === 1) {
                      onFormChange(`0${e.target.value}`, 'roba_durationmins');
                    }
                  }}
                />
                <span style={{ margin: '10px', position: 'relative', top: '20px' }}>:</span>
                <TextField
                  type="number"
                  inputProps={{
                    min: 0,
                  }}
                  sx={{
                    width: '110px',
                  }}
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.RuntimeSeconds')}
                  name="roba_durationsecs"
                  id="roba_durationsecs"
                  required
                  value={formData.roba_durationsecs}
                  helperText={errors.roba_durationsecs}
                  error={!!errors.roba_durationsecs}
                  disabled={actionIsRunning}
                  onChange={(e: any) => {
                    if (e.target.value?.length === 3) {
                      return;
                    }
                    if (parseInt(e.target.value) < 0) {
                      onFormChange(0, 'roba_durationsecs');
                      return;
                    }
                    if (parseInt(e.target.value) > 60) {
                      onFormChange(59, 'roba_durationsecs');
                      return;
                    }
                    onFormChange(e.target.value, 'roba_durationsecs');
                  }}
                  onBlur={(e: any) => {
                    if (e.target.value?.length === 1) {
                      onFormChange(`0${e.target.value}`, 'roba_durationsecs');
                    }
                  }}
                />
              </Grid>
              {/* }
              <Grid item md={12} xs={12}>
                <FormControl fullWidth variant="filled" id="roba_advisory">
                  <InputLabel required>{t('Dashboard.Songs.ParentalAdvisory')}</InputLabel>
                  <Select
                    required
                    variant="filled"
                    label={t('Dashboard.Songs.ParentalAdvisory')}
                    value={formData.roba_advisory}
                    error={!!errors.roba_advisory}
                    disabled={actionIsRunning}
                    onChange={(e: any) => onFormChange(e.target.value, 'roba_advisory')}
                  >
                    {!!formData.roba_advisory && <MenuItem value="">-</MenuItem>}
                    {fieldOptions?.roba_advisory?.map((fieldOption: ISelectOption) => (
                      <MenuItem key={fieldOption.value} value={fieldOption.value}>
                        {fieldOption.labels[
                          i18n.language.includes('en') ? 'en' : 'de'
                        ].toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.roba_advisory && (
                    <FormHelperText error>{errors.roba_advisory}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.Label')}
                  name="roba_recordlabel"
                  id="roba_recordlabel"
                  required
                  value={formData.roba_recordlabel}
                  helperText={errors.roba_recordlabel}
                  error={!!errors.roba_recordlabel}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_recordlabel')}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  inputProps={{ maxLength: 8 }}
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.GVLLabelCode')}
                  value={formData.roba_recordlabel_gvlcode}
                  helperText={errors.roba_recordlabel_gvlcode}
                  error={!!errors.roba_recordlabel_gvlcode}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_recordlabel_gvlcode')}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth variant="filled" id="roba_genre">
                  <InputLabel required>{t('Dashboard.Songs.Genre')}</InputLabel>
                  <Select
                    required
                    multiple
                    variant="filled"
                    label={t('Dashboard.Songs.Genre')}
                    disabled={actionIsRunning}
                    value={formData.roba_genre}
                    error={!!errors.roba_genre}
                    onChange={(e: any) => onFormChange(e.target.value, 'roba_genre')}
                  >
                    {fieldOptions?.roba_genre?.map((fieldOption: ISelectOption) => (
                      <MenuItem key={fieldOption.value} value={fieldOption.value}>
                        {fieldOption.labels[i18n.language.includes('en') ? 'en' : 'de']}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.roba_genre && <FormHelperText error>{errors.roba_genre}</FormHelperText>}
                </FormControl>
              </Grid>
              */}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <SongRecordingModalCover
              formData={formData}
              errors={errors}
              actionIsRunning={actionIsRunning}
              onFormChange={onFormChange}
            />
          </Grid>
        </Grid>

        {/* Artist information */}
        <Grid
          container
          spacing={3}
          sx={{
            mb: 4,
          }}
        >
          <Grid item md={12} xs={12}>
            <SongRecordingModalArtists
              actionIsRunning={actionIsRunning}
              artists={artists}
              fieldOptions={fieldOptions}
              formErrors={errors}
              onArtistsChange={onArtistsChange}
            />
            {errors?.noArtist && (
              <Box sx={{ mt: 4 }} id="noArtist">
                <FormHelperText error sx={{ fontSize: '14px' }}>
                  <b>{errors?.noArtist}</b>
                </FormHelperText>
              </Box>
            )}
          </Grid>
        </Grid>

        {/* Album Information */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Album information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>{t('Dashboard.Songs.AlbumType')}</InputLabel>
                  <Select
                    variant="filled"
                    label={t('Dashboard.Songs.AlbumType')}
                    disabled={actionIsRunning}
                    value={formData.roba_album_type}
                    error={!!errors.roba_album_type}
                    onChange={(e: any) => onFormChange(e.target.value, 'roba_album_type')}
                  >
                    {!!formData.roba_album_type && <MenuItem value="">-</MenuItem>}
                    {[
                      { key: '722040000', text: 'Album' },
                      { key: '722040001', text: 'Single' },
                      { key: '722040002', text: 'Compilation' },
                    ].map((option: { key: string; text: string }) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.roba_album_type && (
                    <FormHelperText error>{errors.roba_album_type}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={9} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.AlbumTitle')}
                  name="roba_album"
                  value={formData.roba_album}
                  helperText={errors.roba_album}
                  error={!!errors.roba_album}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_album')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.Albumversion')}
                  name="roba_album_version"
                  value={formData.roba_album_version}
                  helperText={errors.roba_album_version}
                  error={!!errors.roba_album_version}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_album_version')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                  <DatePicker
                    label={t('Dashboard.Songs.ReleaseDate')}
                    value={formData.roba_album_releasedon}
                    disabled={actionIsRunning}
                    onChange={(newValue: any) => {
                      onFormChange(newValue.$d, 'roba_album_releasedon');
                    }}
                    renderInput={params => <TextField fullWidth variant="filled" {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  type="number"
                  label={t('Dashboard.Songs.AlbumBarcode')}
                  name="roba_album_barcode"
                  value={formData.roba_album_barcode}
                  helperText={errors.roba_album_barcode}
                  error={!!errors.roba_album_barcode}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_album_barcode')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  type="number"
                  label={t('Dashboard.Songs.AlbumBarcodeEAN')}
                  name="roba_album_barcode_ean"
                  value={formData.roba_album_barcode_ean}
                  helperText={errors.roba_album_barcode_ean}
                  error={!!errors.roba_album_barcode_ean}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_album_barcode_ean')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Songs.CatalogNumber')}
                  name="roba_album_cataloguenumber"
                  value={formData.roba_album_cataloguenumber}
                  helperText={errors.roba_album_cataloguenumber}
                  error={!!errors.roba_album_cataloguenumber}
                  disabled={actionIsRunning}
                  onChange={(e: any) => onFormChange(e.target.value, 'roba_album_cataloguenumber')}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Lyric information */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Lyric information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth variant="filled" id="roba_title_language">
                  <InputLabel>{t('Dashboard.Songs.TitleLanguage')}</InputLabel>
                  <Select
                    variant="filled"
                    label={t('Dashboard.Songs.TitleLanguage')}
                    value={formData.roba_title_language}
                    error={!!errors.roba_title_language}
                    disabled={actionIsRunning}
                    onChange={(e: any) => onFormChange(e.target.value, 'roba_title_language')}
                  >
                    {!!formData.roba_title_language && <MenuItem value="">-</MenuItem>}
                    <MenuItem value={722040032}>
                      {i18n.language.includes('en') ? 'DE - GERMAN' : 'DE - DEUTSCH'}
                    </MenuItem>
                    <MenuItem value={722040037}>
                      {i18n.language.includes('en') ? 'EN - ENGLISH' : 'EN - ENGLISCH'}
                    </MenuItem>
                    {fieldOptions?.roba_title_language
                      ?.filter(
                        (option: ISelectOption) => ![722040032, 722040037].includes(option.value),
                      )
                      .map((fieldOption: ISelectOption) => (
                        <MenuItem key={fieldOption.value} value={fieldOption.value}>
                          {fieldOption.labels[
                            i18n.language.includes('en') ? 'en' : 'de'
                          ].toUpperCase()}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.roba_title_language && (
                    <FormHelperText error>{errors.roba_title_language}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} />
              <Grid item md={6} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox name="roba_isinstrumental" checked={formData.roba_isinstrumental} />
                    }
                    label={t('Dashboard.Songs.RecordingIsInstrumental')}
                    disabled={actionIsRunning}
                    onChange={(e: any) => onFormChange(e.target.checked, 'roba_isinstrumental')}
                  />
                  {errors.roba_hassamples && (
                    <FormHelperText error>{errors.roba_isinstrumental}</FormHelperText>
                  )}
                </FormGroup>
                {!formData.roba_isinstrumental && (
                  <>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      sx={{
                        mt: 3,
                        mb: 3,
                      }}
                    >
                      <FormControl fullWidth variant="filled">
                        <InputLabel>{t('Dashboard.Songs.LanguageOfLyrics')}</InputLabel>
                        <Select
                          variant="filled"
                          label={t('Dashboard.Songs.LanguageOfLyrics')}
                          value={formData.roba_lyrics_language}
                          error={!!errors.roba_lyrics_language}
                          disabled={actionIsRunning}
                          onChange={(e: any) =>
                            onFormChange(e.target.value, 'roba_lyrics_language')
                          }
                        >
                          {!!formData.roba_lyrics_language && <MenuItem value="">-</MenuItem>}
                          <MenuItem value={722040032}>
                            {i18n.language.includes('en') ? 'DE - GERMAN' : 'DE - DEUTSCH'}
                          </MenuItem>
                          <MenuItem value={722040037}>
                            {i18n.language.includes('en') ? 'EN - ENGLISH' : 'EN - ENGLISCH'}
                          </MenuItem>
                          {fieldOptions?.roba_lyrics_language
                            ?.filter(
                              (option: ISelectOption) =>
                                ![722040032, 722040037].includes(option.value),
                            )
                            .map((fieldOption: ISelectOption) => (
                              <MenuItem key={fieldOption.value} value={fieldOption.value}>
                                {fieldOption.labels[
                                  i18n.language.includes('en') ? 'en' : 'de'
                                ].toUpperCase()}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.roba_lyrics_language && (
                          <FormHelperText error>{errors.roba_lyrics_language}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        autoComplete="off"
                        variant="filled"
                        label={t('Dashboard.Songs.Lyrics')}
                        rows={20}
                        name="roba_lyrics"
                        value={formData.roba_lyrics}
                        helperText={errors.roba_lyrics}
                        error={!!errors.roba_lyrics}
                        disabled={actionIsRunning}
                        onChange={(e: any) => onFormChange(e.target.value, 'roba_lyrics')}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {false && !formData.roba_isinstrumental && <SongRecordingModalLyricsInfo />}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <div>
          {actionIsRunning && !!uploadProgress && (
            <>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  size={40}
                  value={uploadProgress}
                  sx={{
                    top: 10,
                    position: 'relative',
                  }}
                />
                <Box
                  sx={{
                    top: 20,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                  >{`${Math.round(uploadProgress)}%`}</Typography>
                </Box>
              </Box>
              <Typography
                sx={{ m: 1 }}
                style={{
                  display: 'inline-block',
                  marginLeft: '12px',
                  paddingRight: '10px',
                }}
              >
                {t('Dashboard.Songs.UploadingFile')}
              </Typography>
            </>
          )}
        </div>
        {actionIsRunning && !uploadProgress && (
          <>
            <CircularProgress size={24} />
            <Typography
              sx={{ m: 1 }}
              style={{
                display: 'inline-block',
                marginLeft: '12px',
                paddingRight: '10px',
              }}
            >
              {actionIsRunningText}
            </Typography>
          </>
        )}
        {!actionIsRunning && (
          <>
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning || downloading}
              onClick={handleSave}
            >
              {t('Dashboard.Common.Save')}
            </Button>
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="outlined"
              disabled={actionIsRunning || downloading}
              onClick={handleClose}
            >
              {t('Dashboard.Common.Cancel')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
