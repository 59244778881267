import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';
import { Dropzone } from '../../../lib/Dropzone';
import { ISong } from '../../../../interfaces/Song';
import { downloadFile } from '../../../../services/httpService';

export interface ISongFormWritersEditingPermissionProps {
  songData: ISong;
  file: File | undefined;
  actionIsRunning: boolean;
  errors: { [field: string]: any };
  onFileChange: (files: File | undefined) => void;
  onDeleteFile: () => void;
}

export const SongFormWritersEditingPermission: React.FC<ISongFormWritersEditingPermissionProps> = ({
  songData,
  file,
  actionIsRunning,
  errors,
  onFileChange,
  onDeleteFile,
}) => {
  const { t } = useTranslation();

  const [editing, setEditing] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [dropzoneOpenTrigger, setDropzoneOpenTrigger] = useState<number>(1);

  const handleFileReject = () => {
    toast.error(t('Dashboard.Songs.FileNotExpectedFormat'));
  };

  const handleCancelEditing = () => {
    setEditing(false);
    onFileChange(undefined);
  };

  const handleFileDownload = async (songId: string) => {
    setDownloading(true);
    try {
      const response: any = await downloadFile(songId, 'roba_song');
      const fileName = response.headers['content-disposition']
        .split(';')
        .find((n: string) => n.includes('filename='))
        .replace('filename=', '')
        .replaceAll("'", '')
        .replaceAll('"', '');
      saveAs(response.data, fileName);
    } catch {
      toast.error(t('Dashboard.Songs.RecordingsDownloadError'));
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Box sx={{ mt: 1 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={(!!songData.uploadedFileName && !editing) || !!file}
            readOnly
            onClick={() => setDropzoneOpenTrigger(dropzoneOpenTrigger + 1)}
            disabled={actionIsRunning}
          />
        }
        label={t('Dashboard.Songs.EditingPermission')}
      />
      {songData.uploadedFileName && !editing && (
        <Box sx={{ position: 'relative' }}>
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            disabled={actionIsRunning || downloading}
            onClick={() => handleFileDownload(songData.roba_songid as string)}
          >
            {songData.uploadedFileName}
          </Button>
          <IconButton
            title={t('Dashboard.Songs.EditFile')}
            disabled={actionIsRunning || downloading}
          >
            <EditIcon onClick={() => setEditing(true)} style={{ cursor: 'pointer' }} />
          </IconButton>
          <IconButton
            title={t('Dashboard.Songs.DeleteFile')}
            disabled={actionIsRunning || downloading}
          >
            <DeleteOutlineIcon onClick={() => onDeleteFile()} style={{ cursor: 'pointer' }} />
          </IconButton>
          {downloading && (
            <>
              <CircularProgress
                size={24}
                sx={{
                  position: 'relative',
                  top: '5px',
                  marginLeft: '10px',
                }}
              />
              <Typography
                sx={{ m: 1 }}
                style={{
                  display: 'inline-block',
                  marginLeft: '12px',
                  paddingRight: '10px',
                }}
              >
                {t('Dashboard.Songs.RecordingDownload')}
              </Typography>
            </>
          )}
        </Box>
      )}
      {(!songData.uploadedFileName || editing) && (
        <div>
          {file && (
            <>
              <Typography style={{ marginBottom: '6px', fontWeight: 'bold' }}>
                {t('Dashboard.Songs.SelectedFile')}: {file.name}
                <ClearIcon
                  onClick={() => onFileChange(undefined)}
                  style={{
                    cursor: 'pointer',
                    position: 'relative',
                    top: '6px',
                    marginLeft: '4px',
                  }}
                />
              </Typography>
              {errors.file && <FormHelperText error>{errors.file}</FormHelperText>}
            </>
          )}
          <Dropzone
            accept={{
              'application/pdf': [],
            }}
            disabled={actionIsRunning}
            placeholder={t('Dashboard.Songs.AddEditingPermission')}
            openTrigger={dropzoneOpenTrigger}
            onChange={(files: File[]) => onFileChange(files[0])}
            onFileReject={handleFileReject}
          />
        </div>
      )}
      {songData.uploadedFileName && editing && (
        <div style={{ marginTop: '10px' }}>
          <Button
            variant="outlined"
            disabled={actionIsRunning || downloading}
            onClick={handleCancelEditing}
          >
            {t('Dashboard.Common.Cancel')}
          </Button>
        </div>
      )}
    </Box>
  );
};
