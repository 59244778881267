import { useRef, useState } from 'react';
import type { FC } from 'react';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  ButtonBase,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Menu as MenuIcon } from '../icons/menu';
import { Bell as BellIcon } from '../icons/bell';
import { Search as SearchIcon } from '../icons/search';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { AccountPopover } from './account-popover';
import { ContentSearchDialog } from './content-search-dialog';
import { NotificationsPopover } from './notifications-popover';
import { LanguagePopover } from './language-popover';
import deFlag from '../../assets/images/de_flag.svg';
import enFlag from '../../assets/images/uk_flag.svg';

interface DashboardNavbarProps extends AppBarProps {
  collapsedSidebar: boolean;
  onOpenSidebar?: () => void;
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none',
      }),
}));

export const LanguageButton = () => {
  const { i18n } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <>
      <IconButton onClick={handleOpenPopover} ref={anchorRef} sx={{ ml: 1 }}>
        <Box
          sx={{
            display: 'flex',
            height: 20,
            width: 20,
            '& img': {
              width: '100%',
            },
          }}
        >
          <img alt="" src={i18n.language.includes('de') ? deFlag : enFlag} />
        </Box>
      </IconButton>
      <LanguagePopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

const ContentSearchButton = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenSearchDialog = (): void => {
    setOpenDialog(true);
  };

  const handleCloseSearchDialog = (): void => {
    setOpenDialog(false);
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton onClick={handleOpenSearchDialog} sx={{ ml: 1 }}>
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <ContentSearchDialog onClose={handleCloseSearchDialog} open={openDialog} />
    </>
  );
};

/*
Todo: remove
const ContactsButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <>
      <Tooltip title="Contacts">
        <IconButton onClick={handleOpenPopover} sx={{ ml: 1 }} ref={anchorRef}>
          <UsersIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <ContactsPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};
*/

const NotificationsButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [unread, setUnread] = useState<number>(0);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  // Unread notifications should come from a context and be shared with both this component and
  // notifications popover. To simplify the demo, we get it from the popover

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  const handleUpdateUnread = (value: number): void => {
    setUnread(value);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton ref={anchorRef} sx={{ ml: 1 }} onClick={handleOpenPopover}>
          <Badge color="error" badgeContent={unread}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        onUpdateUnread={handleUpdateUnread}
        open={openPopover}
      />
    </>
  );
};

const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  const user = {
    avatar: '/static/mock-images/avatars/avatar-anika_visser.png',
    name: 'Anika Visser',
  };

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2,
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40,
          }}
          src={user.avatar}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};
/*
const AccountSelect = () => {

  const { t } = useTranslation();
  const { selectedUser, setSelectedUser, myData } = useAuth();
  const navigate = useNavigate();

  const handleDefaultAgreementChange = (e: any) => {
    if (setSelectedUser) {
      setSelectedUser({
        ...(selectedUser as ISelectedUser),
        defaultAgreement: e.target.value,
      });
      navigate('/dashboard/start');
    }
    const data = {
      user: selectedUser?.id,
      defaultAgreement: e.target.value,
    };
    updateDefaultAgreement(data);
  };

  return (
    <div style={{ width: '200px' }}>
      <FormControl fullWidth variant="filled">
        <InputLabel required>{t('Dashboard.Common.Agreement')}</InputLabel>
        <Select
          required
          variant="filled"
          label={t('Dashboard.Common.Agreement')}
          value={selectedUser?.defaultAgreement}
          disabled={!selectedUser}
          onChange={handleDefaultAgreementChange}
          key={selectedUser?.id}
        >
          {(myData?.agreements || []).map((agreement: IAgreement) => (
            <MenuItem key={agreement.roba_agreementid} value={agreement.roba_agreementid}>
              {agreement.roba_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
  */

export const DashboardNavbar: FC<DashboardNavbarProps> = props => {
  const { onOpenSidebar, collapsedSidebar, ...other } = props;

  return (
    <DashboardNavbarRoot
      sx={{
        left: {
          lg: collapsedSidebar ? 100 : 240,
        },
        width: {
          lg: `calc(100% - ${collapsedSidebar ? 100 : 240}px)`,
        },
      }}
      {...other}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        {/* <ContentSearchButton />
        <NotificationsButton /> */}
        {/* <AccountSelect /> */}
        <LanguageButton />
        <AccountButton />
      </Toolbar>
    </DashboardNavbarRoot>
  );
};
