import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Box,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Table,
  TableBody,
  CircularProgress,
  Typography,
  CardActions,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import {
  ISongWriter,
  newSongWriter,
  ISong,
  ISongWriterPublisher,
  newSongWriterPublisher,
  songWriterArrangerRoleValue,
  songWriterAdaptorRoleValue,
  robaGemaPerformanceDistributionDefault,
  songWriterRoleAuthorValue,
  songWriterRoleComposerAuthorValue,
} from '../../../../interfaces/Song';
import { IWriter } from '../../../../interfaces/Writer';
import { Scrollbar } from '../../../template/scrollbar';
import { getSongWritersErrors, isAuthor, isComposer } from '../SongFormHelper';
import { ISongFormStep, ISongFormSteps } from '../SongForm';
import { SongFormWritersARSplit } from './SongFormWritersARSplit';
import { SongFormWritersPublisher } from './SongFormWritersPublisher';
import { SongFormWritersTotalSharesRow } from './SongFormWritersTotalSharesRow';
import { SongFormWritersTableHead } from './SongFormWritersTableHead';
import { SongFormWritersTableRow } from './SongFormWritersTableRow';
import { SongFormWritersCommandBar } from './SongFormWritersCommandBar';
import { SongFormWritersPublicDomain } from './SongFormWritersPublicDomain';
import { SongFormWritersEditingPermission } from './SongFormWritersEditingPermission';
import { SongFormWritersPublicDomainIncludingText } from './SongFormWritersPublicDomainIncludingText';

export interface ISongFormWritersTableProps {
  songData: ISong;
  initSongWriters: ISongWriter[];
  writers: IWriter[];
  addedSongWriter: string | undefined;
  steps: ISongFormSteps;
  isLoading: boolean;
  actionIsRunning: boolean;
  actionIsRunningText: string;
  onDelete: () => void;
  onNavigate: (
    songWriters: ISongWriter[],
    file: File | undefined,
    step: ISongFormStep,
    arValue: number,
    ispublicdomain: boolean,
    publicDomainIncludingText: boolean,
    hasChanged: boolean,
  ) => void;
  onShowCreateWriterModal: (showCreateWriterModal: boolean) => void;
  onDeleteFile: () => void;
  onEditWriter: (writerId: string) => void;
}

export const SongFormWritersTable: React.FC<ISongFormWritersTableProps> = ({
  songData,
  initSongWriters,
  writers,
  addedSongWriter,
  steps,
  isLoading,
  actionIsRunning,
  actionIsRunningText,
  onDelete,
  onNavigate,
  onShowCreateWriterModal,
  onDeleteFile,
  onEditWriter,
}) => {
  const { t } = useTranslation();

  const [openSongWriter, setOpenSongWriter] = useState<string | null>(null);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [arValue, setARValue] = useState<number>(songData.roba_gema_performance_distribution);
  const [arValueChanged, setArValueChanged] = useState<boolean>(false);
  const [customArValueConfirmed, setCustomArValueConfirmed] = useState<boolean>(false);
  const [songWriters, setSongWriters] = useState<ISongWriter[]>([]);
  const [ispublicdomain, setIspublicdomain] = useState<boolean>(false);
  const [publicDomainIncludingText, setPublicDomainIncludingText] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [errors, setErrors] = useState<{ [field: string]: any }>({});
  const [songWriterCreateId, setSongWriterCreateId] = useState<string | undefined>(undefined);

  const showARCheckbox = useMemo(
    () =>
      songWriters
        .filter((songWriter: ISongWriter) => !songWriter.deleted)
        .some((songWriter: ISongWriter) => isComposer(songWriter.roba_role)) &&
      songWriters
        .filter((songWriter: ISongWriter) => !songWriter.deleted)
        .some((songWriter: ISongWriter) => isAuthor(songWriter.roba_role)),
    [songWriters],
  );

  const showPublicDomain = useMemo(() => {
    const sWriters = songWriters.filter((songWriter: ISongWriter) => !songWriter.deleted);
    return sWriters.some(
      (songWriter: ISongWriter) =>
        songWriter.roba_role === songWriterArrangerRoleValue ||
        songWriter.roba_role === songWriterAdaptorRoleValue,
    );
  }, [songWriters]);

  const showPublicDomainIncludingText = useMemo(() => {
    const sWriters = songWriters.filter((songWriter: ISongWriter) => !songWriter.deleted);
    return (
      sWriters.some(
        (songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue,
      ) &&
      !sWriters.some(
        (songWriter: ISongWriter) =>
          songWriter.roba_role === songWriterRoleAuthorValue ||
          songWriter.roba_role === songWriterRoleComposerAuthorValue ||
          songWriter.roba_role === songWriterAdaptorRoleValue,
      )
    );
  }, [songWriters]);

  const showEditingPermission = useMemo(() => {
    const sWriters = songWriters.filter((songWriter: ISongWriter) => !songWriter.deleted);
    return (
      ((sWriters.some((songWriter: ISongWriter) => isComposer(songWriter.roba_role)) &&
        sWriters.some(
          (songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue,
        )) ||
        (sWriters.some((songWriter: ISongWriter) => isAuthor(songWriter.roba_role)) &&
          sWriters.some(
            (songWriter: ISongWriter) => songWriter.roba_role === songWriterAdaptorRoleValue,
          ))) &&
      !ispublicdomain
    );
  }, [songWriters, ispublicdomain]);

  const hasErrors = (): boolean => {
    const errors = getSongWritersErrors(
      songWriters,
      t,
      arValue,
      ispublicdomain,
      file,
      songData.uploadedFileName as string,
      customArValueConfirmed,
      writers,
    );
    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleOpenSongWriter = (songWriterId: string): void => {
    setOpenSongWriter(prevValue => (prevValue === songWriterId ? null : songWriterId));
  };

  const addSongWriter = () => {
    const songWriter = { ...newSongWriter };
    songWriter.roba_songwritersid = uuidv4();
    const nextSongWriter = [...songWriters, songWriter];
    setSongWriters(nextSongWriter);
    setHasChanged(true);
    setOpenSongWriter(null);
    const nextErrors = { ...errors };
    delete nextErrors.general;
    setErrors(nextErrors);
    setSongWriterCreateId(undefined);
  };

  const handleCreateWriter = (writerId: string) => {
    const nextSongWriters = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid !== songWriterCreateId) {
        return songWriter;
      }
      return {
        ...songWriter,
        roba_writer: writerId,
      };
    });
    setSongWriters(nextSongWriters);
    setHasChanged(true);
    setOpenSongWriter(null);
    const nextErrors = { ...errors };
    delete nextErrors.general;
    setErrors(nextErrors);
    setSongWriterCreateId(undefined);
  };

  const addSongWriterPublisher = () => {
    const nextSongWriter = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid !== openSongWriter) {
        return songWriter;
      }

      const publisherLength = songWriter.publisher.filter(
        (swPublisher: ISongWriterPublisher) => !swPublisher.deleted && !swPublisher.isThirdParty,
      ).length;
      const isFirstPublisher = publisherLength === 0;

      const songWriterPublisher: ISongWriterPublisher = { ...newSongWriterPublisher };
      songWriterPublisher.roba_songwriters_publishersid = uuidv4();
      /*
      if (selectedUser?.defaultAgreement && isFirstPublisher) {
        songWriterPublisher.roba_agreementipiid = selectedUser?.defaultAgreement;
      }
        */
      // first publisher
      if (isFirstPublisher) {
        songWriterPublisher.roba_share = '100';
      }
      const nextSWPublisher = [...songWriter.publisher, songWriterPublisher];

      return {
        ...songWriter,
        edited: true,
        publisher: nextSWPublisher,
      };
    });
    setSongWriters(nextSongWriter);
    setHasChanged(true);
  };

  const addSongWriterThirdPartyPublisher = () => {
    const nextSongWriter = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid !== openSongWriter) {
        return songWriter;
      }

      const songWriterPublisher: ISongWriterPublisher = { ...newSongWriterPublisher };
      songWriterPublisher.roba_songwriters_publishersid = uuidv4();
      songWriterPublisher.isThirdParty = true;
      const nextSWPublisher = [...songWriter.publisher, songWriterPublisher];
      return {
        ...songWriter,
        edited: true,
        publisher: nextSWPublisher,
      };
    });
    setSongWriters(nextSongWriter);
    setHasChanged(true);
  };

  const deleteSongWriter = (songWriterId: string) => {
    const nextSongWriter = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid === songWriterId) {
        return {
          ...songWriter,
          deleted: true,
        };
      }
      return songWriter;
    });
    setSongWriters(nextSongWriter);
    setHasChanged(true);
    const nextErrors = { ...errors };
    delete nextErrors[songWriterId];
    delete nextErrors.general;
    delete nextErrors.roba_total_share_composition;
    delete nextErrors.roba_total_share_text;
    delete nextErrors.roba_total_share_editor;
    delete nextErrors.roba_total_share_text_editor;
    setErrors(nextErrors);
    setOpenSongWriter(null);
  };

  const deleteSongWriterPublisher = (songWriterPublisherId: string) => {
    const nextSongWriter = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid !== openSongWriter) {
        return songWriter;
      }

      const isDeleteLastCoPublisher =
        songWriter.publisher.filter((sWPublisher: ISongWriterPublisher) => !sWPublisher.deleted)
          .length === 2;

      const nextSongWriterPublisher = songWriter.publisher.map(
        (sWPublisher: ISongWriterPublisher) => {
          if (sWPublisher.roba_songwriters_publishersid === songWriterPublisherId) {
            return {
              ...sWPublisher,
              deleted: true,
            };
          }
          if (isDeleteLastCoPublisher) {
            return {
              ...sWPublisher,
              roba_share: '100',
              edited: true,
            };
          }
          return sWPublisher;
        },
      );
      return {
        ...songWriter,
        edited: true,
        publisher: nextSongWriterPublisher,
      };
    });
    setSongWriters(nextSongWriter);
    setHasChanged(true);
    const nextErrors = { ...errors };
    delete nextErrors.roba_swPublisher_share;
    delete nextErrors.roba_total_share_publisher;
    delete nextErrors.roba_swPublisher_publisher;
    setErrors(nextErrors);
  };

  const handleSongWriterChange = (value: any, key: string, songWriterId: string) => {
    const nextSongWriters = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid === songWriterId) {
        const changedWriter = {
          ...songWriter,
          [key]: value,
          edited: true,
        };
        if (key === 'roba_writer') {
          const writer = writers.find((writer: IWriter) => writer.roba_writerid === value);
          changedWriter.roba_name = writer
            ? `${writer?.roba_firstname} ${writer?.roba_lastname}`
            : '';
        }
        if (key === 'roba_role') {
          changedWriter.roba_share = null;
          changedWriter.roba_share_text = null;
          changedWriter.roba_share_editor = null;
          changedWriter.roba_share_editor_text = null;
        }
        if (key === 'roba_iscollecting') {
          setOpenSongWriter(null);
        }
        return changedWriter;
      }
      return songWriter;
    });
    setSongWriters(nextSongWriters);
    setHasChanged(true);
    const nextErrors = { ...errors };
    if (value) {
      delete nextErrors[songWriterId]?.[key];
      if (key === 'roba_role') {
        delete nextErrors.general;
      }
      if (key === 'roba_role' || key === 'roba_share' || key === 'roba_share_text') {
        delete nextErrors[songWriterId]?.roba_share;
        delete nextErrors[songWriterId]?.roba_share_text;
        delete nextErrors[songWriterId]?.roba_total_share_composition;
        delete nextErrors[songWriterId]?.roba_total_share_text;
        delete nextErrors[songWriterId]?.roba_share_editor;
        delete nextErrors[songWriterId]?.roba_share_editor_text;
        delete nextErrors[songWriterId]?.roba_total_share_publisher;
      }
    }
    if (key === 'roba_iscollecting' && value !== null) {
      delete nextErrors[songWriterId]?.roba_iscollecting;
    }
    setErrors(nextErrors);
  };

  const handleIsCollectingChange = (isCollecting: boolean, songWriterId: string) => {
    handleSongWriterChange(isCollecting, 'roba_iscollecting', songWriterId);
    const nextSongWriters = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid === songWriterId) {
        const changedWriter = {
          ...songWriter,
          roba_iscollecting: isCollecting,
          edited: true,
          publisher: [],
        };
        return changedWriter;
      }
      return songWriter;
    });
    setSongWriters(nextSongWriters);
    setHasChanged(true);
  };

  const handleSongWriterPublisherChange = (
    value: any,
    key: string,
    songWriterPublisherId: string,
  ) => {
    const nextSongWriters = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid !== openSongWriter) {
        return songWriter;
      }
      const nextSongWriterPublisher = songWriter.publisher.map(
        (sWPublisher: ISongWriterPublisher) => {
          if (sWPublisher.roba_songwriters_publishersid === songWriterPublisherId) {
            return {
              ...sWPublisher,
              [key]: value,
              edited: true,
            };
          }
          return sWPublisher;
        },
      );
      return {
        ...songWriter,
        edited: true,
        publisher: nextSongWriterPublisher,
      };
    });
    setSongWriters(nextSongWriters);
    setHasChanged(true);
    const nextErrors = { ...errors };
    if (value) {
      if (key === 'roba_agreementipiid') {
        delete nextErrors[openSongWriter as string]?.roba_swPublisher_publisher;
        delete nextErrors[openSongWriter as string]?.roba_iscollecting_publisher;
      }
      if (key === 'roba_share') {
        delete nextErrors[openSongWriter as string]?.roba_swPublisher_share;
        delete nextErrors[openSongWriter as string]?.roba_total_share_publisher;
      }
      if (key === 'roba_unmanagedpublishername') {
        delete nextErrors[openSongWriter as string]?.roba_swPublisher_name;
      }
      if (key === 'roba_unmanagedpublisheripinumber') {
        delete nextErrors[openSongWriter as string]?.roba_swPublisher_ipi;
      }
      if (key === 'roba_agreementipiid') {
        delete nextErrors[openSongWriter as string]?.roba_swPublisher_publisher;
      }
    }
    setErrors(nextErrors);
  };

  const handleSongWriterPublisherChangeFromIPISearch = (
    data: any,
    songWriterPublisherId: string,
  ) => {
    const nextSongWriters = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid !== openSongWriter) {
        return songWriter;
      }
      const nextSongWriterPublisher = songWriter.publisher.map(
        (sWPublisher: ISongWriterPublisher) => {
          if (sWPublisher.roba_songwriters_publishersid === songWriterPublisherId) {
            return {
              ...sWPublisher,
              ...data,
              edited: true,
            };
          }
          return sWPublisher;
        },
      );
      return {
        ...songWriter,
        edited: true,
        publisher: nextSongWriterPublisher,
      };
    });
    setSongWriters(nextSongWriters);
    setHasChanged(true);
    const nextErrors = { ...errors };
    delete nextErrors[openSongWriter as string]?.roba_swPublisher_name;
    delete nextErrors[openSongWriter as string]?.roba_swPublisher_ipi;
    setErrors(nextErrors);
  };

  const handlePublicDomainChange = (ispublicdomain: boolean): void => {
    setIspublicdomain(ispublicdomain);
    const nextErrors = { ...errors };
    delete nextErrors.general;
    setErrors(nextErrors);
    setFile(undefined);
  };

  const handleFileChange = (file: File | undefined): void => {
    setFile(file);
    const nextErrors = { ...errors };
    delete nextErrors.file;
    delete nextErrors.general;
    setErrors(nextErrors);
  };

  const splitSharesEqually = () => {
    const songWriterComposersCount = songWriters.filter(
      (songWriter: ISongWriter) => !songWriter.deleted && isComposer(songWriter.roba_role),
    ).length;
    const songWriterAuthorsCount = songWriters.filter(
      (songWriter: ISongWriter) => !songWriter.deleted && isAuthor(songWriter.roba_role),
    ).length;
    const songWriterEditorsCount = songWriters.filter(
      (songWriter: ISongWriter) =>
        !songWriter.deleted && songWriter.roba_role === songWriterArrangerRoleValue,
    ).length;
    const songWriterTextEditorsCount = songWriters.filter(
      (songWriter: ISongWriter) =>
        !songWriter.deleted && songWriter.roba_role === songWriterAdaptorRoleValue,
    ).length;
    const singleComposerShare = (100 / songWriterComposersCount).toPrecision(4);
    const singleAuthorShare = (100 / songWriterAuthorsCount).toPrecision(4);
    const singleEditorShare = (100 / songWriterEditorsCount).toPrecision(4);
    const singleTextEditorShare = (100 / songWriterTextEditorsCount).toPrecision(4);
    let composerShareOffset = (
      100 -
      parseFloat(singleComposerShare) * songWriterComposersCount
    ).toPrecision(4);
    let authorShareOffset = (
      100 -
      parseFloat(singleAuthorShare) * songWriterAuthorsCount
    ).toPrecision(4);
    let editorsShareOffset = (
      100 -
      parseFloat(singleEditorShare) * songWriterEditorsCount
    ).toPrecision(4);
    let textEditorsShareOffset = (
      100 -
      parseFloat(singleTextEditorShare) * songWriterTextEditorsCount
    ).toPrecision(4);

    const nextSongWriters = songWriters.map((songWriter: ISongWriter) => {
      const sW = {
        ...songWriter,
        edited: true,
      };

      if (isComposer(songWriter.roba_role)) {
        if (composerShareOffset) {
          sW.roba_share = (
            parseFloat(singleComposerShare) + parseFloat(composerShareOffset)
          ).toPrecision(4);
          composerShareOffset = '';
        } else {
          sW.roba_share = singleComposerShare;
        }
      } else {
        sW.roba_share = '0';
      }

      if (isAuthor(songWriter.roba_role)) {
        if (authorShareOffset) {
          sW.roba_share_text = (
            parseFloat(singleAuthorShare) + parseFloat(authorShareOffset)
          ).toPrecision(4);
          authorShareOffset = '';
        } else {
          sW.roba_share_text = singleAuthorShare;
        }
      } else {
        sW.roba_share_text = '0';
      }

      if (songWriter.roba_role === songWriterArrangerRoleValue) {
        if (editorsShareOffset) {
          sW.roba_share_editor = (
            parseFloat(singleEditorShare) + parseFloat(editorsShareOffset)
          ).toPrecision(4);
          editorsShareOffset = '';
        } else {
          sW.roba_share_editor = singleEditorShare;
        }
      } else {
        sW.roba_share_editor = '0';
      }

      if (songWriter.roba_role === songWriterAdaptorRoleValue) {
        if (textEditorsShareOffset) {
          sW.roba_share_editor_text = (
            parseFloat(singleTextEditorShare) + parseFloat(textEditorsShareOffset)
          ).toPrecision(4);
          textEditorsShareOffset = '';
        } else {
          sW.roba_share_editor_text = singleTextEditorShare;
        }
      } else {
        sW.roba_share_editor_text = '0';
      }

      return sW;
    });
    setSongWriters(nextSongWriters);
    setHasChanged(true);
    const nextErrors = { ...errors };
    delete nextErrors.roba_share;
    delete nextErrors.roba_share_text;
    delete nextErrors.roba_share_editor;
    delete nextErrors.roba_share_editor_text;
    delete nextErrors.roba_total_share_composition;
    delete nextErrors.roba_total_share_text;
    delete nextErrors.roba_total_share_editor;
    delete nextErrors.roba_total_share_text_editor;
    delete nextErrors.roba_total_share_publisher;
    setErrors(nextErrors);
  };

  const setSongWriterPublisherToAll = (songWriterId: string) => {
    const nextSWPublisher = songWriters
      .find((songWriter: ISongWriter) => songWriter.roba_songwritersid === songWriterId)
      ?.publisher.map((sWPublisher: ISongWriterPublisher) => ({
        ...sWPublisher,
        added: true,
      }));

    const nextSongWriter = songWriters.map((songWriter: ISongWriter) => {
      if (songWriter.roba_songwritersid !== songWriterId) {
        const prevSWPublisher = songWriter.publisher.map((sWPublisher: ISongWriterPublisher) => ({
          ...sWPublisher,
          deleted: true,
        }));

        return {
          ...songWriter,
          publisher: [...prevSWPublisher, ...(nextSWPublisher || [])],
          edited: true,
        };
      }
      return songWriter;
    });
    setSongWriters(nextSongWriter);
    setHasChanged(true);
    toast.success(t('Dashboard.Songs.SetSongWriterPublisherToAll'));
  };

  const handleNavigate = async (step: ISongFormStep) => {
    if (!hasErrors()) {
      onNavigate(
        songWriters,
        file,
        step,
        arValue,
        ispublicdomain,
        publicDomainIncludingText,
        hasChanged,
      );
    }
  };

  const handleARCheckboxChange = (checked: boolean) => {
    if (checked) {
      setARValue(robaGemaPerformanceDistributionDefault);
    } else {
      setARValue(0.5);
      setCustomArValueConfirmed(false);
    }
    setArValueChanged(true);
  };

  const handleARValueChange = (arValue: number) => {
    setARValue(arValue);
    setArValueChanged(true);
    const nextErrors = { ...errors };
    setErrors(nextErrors);
  };

  const onCustomArValueConfirm = (customArValueConfirmed: boolean) => {
    setCustomArValueConfirmed(customArValueConfirmed);
    const nextErrors = { ...errors };
    delete nextErrors.CustomArValueConfirmed;
    setErrors(nextErrors);
  };

  useEffect(() => {
    if (songData) {
      setARValue(songData.roba_gema_performance_distribution);
      setCustomArValueConfirmed(
        songData.roba_gema_performance_distribution !== robaGemaPerformanceDistributionDefault,
      );
      setIspublicdomain(songData.roba_ispublicdomain);
      setPublicDomainIncludingText(songData.roba_publicdomainincludingtext);
    }
  }, [songData]);

  useEffect(() => {
    if (initSongWriters.length) {
      setSongWriters(initSongWriters);
    }
  }, [initSongWriters]);

  useEffect(() => {
    if (addedSongWriter) {
      handleCreateWriter(addedSongWriter);
    } else {
      setSongWriterCreateId(undefined);
    }
  }, [addedSongWriter]);

  // reset errors when edit or add writers
  useEffect(() => {
    setErrors({});
  }, [writers]);

  return (
    <>
      <CardHeader title={t('Dashboard.Songs.WriterSplits')} />
      <Divider />
      <CardContent>
        <Scrollbar>
          <Table sx={{ minWidth: 700 }}>
            <SongFormWritersTableHead songWriters={songWriters} />
            <TableBody>
              {songWriters.map((songWriter: ISongWriter, index: number) => {
                const open = songWriter.roba_songwritersid === openSongWriter;
                return (
                  <Fragment key={index}>
                    <SongFormWritersTableRow
                      open={open}
                      songWriter={songWriter}
                      songWriters={songWriters}
                      writers={writers}
                      actionIsRunning={actionIsRunning}
                      errors={errors[songWriter.roba_songwritersid as string]}
                      onSongWriterChange={handleSongWriterChange}
                      onIsCollectingChange={handleIsCollectingChange}
                      onOpenSongWriter={handleOpenSongWriter}
                      onDeleteSongWriter={deleteSongWriter}
                      onEditWriter={onEditWriter}
                      onShowCreateWriterModal={(
                        showCreateWriterModal: boolean,
                        songWriterId: string,
                      ) => {
                        onShowCreateWriterModal(showCreateWriterModal);
                        setSongWriterCreateId(songWriterId);
                      }}
                    />
                    {open && (
                      <SongFormWritersPublisher
                        songWriter={songWriter}
                        actionIsRunning={actionIsRunning}
                        isLoading={isLoading}
                        errors={errors[songWriter.roba_songwritersid as string]}
                        onSongWriterPublisherChange={handleSongWriterPublisherChange}
                        onSongWriterPublisherChangeFromIPISearch={
                          handleSongWriterPublisherChangeFromIPISearch
                        }
                        onDeleteSongWriterPublisher={deleteSongWriterPublisher}
                        onAddSongWriterPublisher={addSongWriterPublisher}
                        onSetSongWriterPublisherToAll={setSongWriterPublisherToAll}
                        onAddSongWriterThirdPartyPublisher={addSongWriterThirdPartyPublisher}
                        onFinish={(songWriterId: string) => {
                          handleOpenSongWriter(songWriterId);
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
              {!!songWriters.length && (
                <SongFormWritersTotalSharesRow songWriters={songWriters} errors={errors} />
              )}
            </TableBody>
          </Table>
        </Scrollbar>

        <SongFormWritersCommandBar
          songWriters={songWriters}
          actionIsRunning={actionIsRunning}
          onAddSongWriter={addSongWriter}
          onSplitSharesEqually={splitSharesEqually}
          onShowCreateWriterModal={(showCreateWriterModal: boolean) =>
            onShowCreateWriterModal(showCreateWriterModal)
          }
        />

        {showARCheckbox && (
          <Grid container spacing={3} style={{ marginTop: '12px' }}>
            <Grid item md={12} xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={arValue === robaGemaPerformanceDistributionDefault} />
                  }
                  label={
                    <>
                      {t('Dashboard.Songs.BasisaufteilungCheckbox')}
                      <Tooltip
                        title={<p style={{ fontSize: '16px' }}>{t('Dashboard.Songs.ARInfo')}</p>}
                      >
                        <IconButton style={{ position: 'relative', top: '-2px' }}>
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  disabled={actionIsRunning}
                  onChange={(e: any) => handleARCheckboxChange(e.target.checked)}
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}

        {showARCheckbox &&
          (arValue !== robaGemaPerformanceDistributionDefault || arValueChanged) && (
            <SongFormWritersARSplit
              arValue={arValue}
              customArValueConfirmed={customArValueConfirmed}
              actionIsRunning={actionIsRunning}
              errors={errors}
              onArValueChange={(arValue: number) => handleARValueChange(arValue)}
              onCustomArValueConfirm={onCustomArValueConfirm}
            />
          )}

        {showPublicDomain && (
          <SongFormWritersPublicDomain
            ispublicdomain={ispublicdomain}
            actionIsRunning={actionIsRunning}
            errors={errors}
            onPublicDomainChange={handlePublicDomainChange}
          />
        )}

        {showPublicDomainIncludingText && (
          <SongFormWritersPublicDomainIncludingText
            publicDomainIncludingText={publicDomainIncludingText}
            actionIsRunning={actionIsRunning}
            onPublicDomainIncludingTextChange={(publicDomainIncludingText: boolean) =>
              setPublicDomainIncludingText(publicDomainIncludingText)
            }
          />
        )}

        {showEditingPermission && (
          <SongFormWritersEditingPermission
            songData={songData}
            file={file}
            actionIsRunning={actionIsRunning}
            errors={errors}
            onFileChange={handleFileChange}
            onDeleteFile={onDeleteFile}
          />
        )}

        {errors?.general && (
          <Box sx={{ mt: 4 }}>
            <FormHelperText error sx={{ fontSize: '14px' }}>
              <b>{errors?.general}</b>
            </FormHelperText>
          </Box>
        )}

        {!!Object.keys(errors).length &&
          Object.keys(errors).some(
            (songWriterId: any) => errors[songWriterId].roba_iscollecting_publisher,
          ) && (
            <Box sx={{ mt: 4 }}>
              <FormHelperText error sx={{ fontSize: '14px' }}>
                <b>{t('Dashboard.Songs.IscollectingPublisherError')}</b>
              </FormHelperText>
            </Box>
          )}
      </CardContent>

      <CardActions
        sx={{
          flexWrap: 'wrap',
          m: -1,
          position: 'relative',
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            mt: 1,
          }}
        >
          <Grid item md={6} xs={12}>
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning}
              onClick={() => handleNavigate(steps.song)}
            >
              {t('Dashboard.Songs.SaveAndPrev')}
            </Button>
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning}
              onClick={() => handleNavigate(steps.recordings)}
            >
              {t('Dashboard.Songs.SaveAndNext')}
            </Button>
          </Grid>
          <Grid item md={6} xs={12} style={{ textAlign: 'right' }}>
            <Link to="/dashboard/songs" style={{ textDecoration: 'none' }}>
              <Button component="a" variant="outlined" disabled={actionIsRunning}>
                {t('Dashboard.Songs.Cancel')}
              </Button>
            </Link>
            {songData?.roba_songid && (
              <Button sx={{ m: 1 }} color="error" disabled={actionIsRunning} onClick={onDelete}>
                {t('Dashboard.Songs.DeleteSong')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardActions>

      {actionIsRunning && (
        <CardContent style={{ paddingTop: '0' }}>
          <CircularProgress size={24} />
          <Typography
            style={{
              display: 'inline-block',
              marginLeft: '12px',
              position: 'relative',
              top: '-6px',
            }}
          >
            {actionIsRunningText}
          </Typography>
        </CardContent>
      )}
    </>
  );
};
