import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface ISongFormWritersPublicDomainProps {
  ispublicdomain: boolean;
  actionIsRunning: boolean;
  errors: { [field: string]: any };
  onPublicDomainChange: (ispublicdomain: boolean) => void;
}

export const SongFormWritersPublicDomain: React.FC<ISongFormWritersPublicDomainProps> = ({
  ispublicdomain,
  actionIsRunning,
  errors,
  onPublicDomainChange,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 4 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={ispublicdomain}
            style={{ color: errors.roba_public_domain_check ? 'rgb(209, 67, 67)' : '#00B3D4' }}
          />
        }
        style={{ color: errors.roba_public_domain_check ? 'rgb(209, 67, 67)' : 'inherit' }}
        label={t('Dashboard.Songs.PublicDomainCheckbox')}
        disabled={actionIsRunning}
        onChange={(e: any) => onPublicDomainChange(e.target.checked)}
      />
    </Box>
  );
};
