import { validateIpi } from '../../../helper/ValidateIPI';
import { validateRequiredFields } from '../../../helper/ValidationHelper';
import {
  ISong,
  ISongAlternativeTitle,
  ISongWriter,
  ISongWriterPublisher,
  robaGemaPerformanceDistributionDefault,
  songRequiredFields,
  songWriterAdaptorRoleValue,
  songWriterArrangerRoleValue,
  songWriterRequiredFields,
  songWriterRoleAuthorValue,
  songWriterRoleComposerAuthorValue,
  songWriterRoleComposerValue,
} from '../../../interfaces/Song';
import { IWriter } from '../../../interfaces/Writer';

/* Error docs 

Übergreifend:

Rollen Validierung:
general: 
  - "Es muss mindestens ein Writer hinzugefügt werden!"
  - "Das Werk enthält keinen Komponist*innen-Anteil."
  - "Das Werk enthält nur Anteile unkontrollierter Writer."

roba_public_domain_check: "Die Werkmeldung enthält nur Bearbeiter*innen-Anteile, da es sich um eine Bearbeitung eines gemeinfreien Werkes handelt.",

Shares:
roba_total_share_composition = "Bitte alle Shares angeben" || "Der Gesamtwert aller Anteile ergibt nicht 100%"
roba_total_share_text = "Bitte alle Shares angeben" || "Der Gesamtwert aller Anteile ergibt nicht 100%"
roba_total_share_editor = "Bitte alle Shares angeben" || "Der Gesamtwert aller Anteile ergibt nicht 100%"
roba_total_share_text_editor = "Bitte alle Shares angeben" || "Der Gesamtwert aller Anteile ergibt nicht 100%"


Pro SongWriter:
Alle mandatory fields: "Pflichtfeld"
roba_iscollecting: "Pflichtfeld"
roba_iscollecting_publisher: boolean
roba_iscollecting_missingFields: "Fehlende Felder"
roba_swPublisher_publisher: "Pflichtfeld" (wenn NICHT Third Party Publisher)
roba_swPublisher_share: "Pflichtfeld" (wenn NICHT Third Party Publisher)
roba_swPublisher_name: "Pflichtfeld" (wenn Third Party Publisher)
roba_swPublisher_ipi: "Bitte eine gültige IPI Nummer angeben" (wenn Third Party Publisher)
roba_total_share_publisher: zB "Der Gesamtwert aller Anteile ist größer als 100%"

*/

export const getSongWritersErrors = (
  sWs: ISongWriter[],
  t: any,
  arValue: number,
  ispublicdomain: boolean,
  file: File | undefined,
  uploadedFileName: string,
  customArValueConfirmed: boolean,
  writers: IWriter[],
) => {
  const errors: any = {};

  const songWriters = sWs.filter((songWriter: ISongWriter) => !songWriter.deleted);

  if (!songWriters.length) {
    return { general: t('Dashboard.Songs.MinSongWritersError') };
  }

  if (songWriters.length) {
    songWriters.forEach((songWriter: ISongWriter) => {
      // set init obj for every songwriter
      errors[songWriter.roba_songwritersid as string] = {};

      // required fields
      const requiredFieldsErrors = validateRequiredFields(songWriterRequiredFields, songWriter, t);
      errors[songWriter.roba_songwritersid as string] = requiredFieldsErrors;

      // check is controlled value
      if (songWriter.roba_iscollecting === null) {
        errors[songWriter.roba_songwritersid as string].roba_iscollecting = t(
          'MasterDataForm.MandatoryField',
        );
      }

      // check is controlled value and publisher
      if (
        songWriter.roba_iscollecting === true &&
        !songWriter.publisher.filter((swPublisher: ISongWriterPublisher) => !swPublisher.deleted)
          .length
      ) {
        errors[songWriter.roba_songwritersid as string].roba_iscollecting_publisher = true;
      }

      // check mandatory email and address of writer if songwriter is controlled
      if (songWriter.roba_iscollecting === true && songWriter.roba_writer) {
        const writer = writers.find(
          (writer: IWriter) => writer.roba_writerid === songWriter.roba_writer,
        );
        const hasValidAddress =
          !!writer?.roba_street1 &&
          !!writer?.roba_postalcode &&
          !!writer?.roba_city &&
          !!writer?.roba_country;
        if (!writer?.roba_email || !hasValidAddress) {
          errors[songWriter.roba_songwritersid as string].roba_iscollecting_missingFields = t(
            'Dashboard.Songs.WriterMissingFieldsError',
          );
        }
      }

      // publisher checks
      const swPublisher = songWriter.publisher.filter(
        (swPublisher: ISongWriterPublisher) => !swPublisher.deleted,
      );
      if (swPublisher.length) {
        // check mandatory selected publisher value if not third party
        if (
          swPublisher.some(
            (swPublisher: ISongWriterPublisher) =>
              !swPublisher.isThirdParty && !swPublisher.roba_agreementipiid,
          )
        ) {
          errors[songWriter.roba_songwritersid as string].roba_swPublisher_publisher = t(
            'MasterDataForm.MandatoryField',
          );
        }

        // check mandatory name value if third party
        if (
          swPublisher.some(
            (swPublisher: ISongWriterPublisher) =>
              swPublisher.isThirdParty && !swPublisher.roba_unmanagedpublishername,
          )
        ) {
          errors[songWriter.roba_songwritersid as string].roba_swPublisher_name = t(
            'MasterDataForm.MandatoryField',
          );
        }

        // check ipi value if third party
        if (
          swPublisher.some(
            (swPublisher: ISongWriterPublisher) =>
              swPublisher.isThirdParty &&
              swPublisher.roba_unmanagedpublisheripinumber &&
              !validateIpi(swPublisher.roba_unmanagedpublisheripinumber),
          )
        ) {
          errors[songWriter.roba_songwritersid as string].roba_swPublisher_ipi =
            t('MasterDataForm.ValidIpi');
        }

        // check mandatory share value
        if (
          swPublisher.some(
            (swPublisher: ISongWriterPublisher) =>
              !swPublisher.isThirdParty && !swPublisher.roba_share,
          )
        ) {
          errors[songWriter.roba_songwritersid as string].roba_swPublisher_share = t(
            'MasterDataForm.MandatoryField',
          );
        }

        // check total share
        const sumSharesPublisher = swPublisher.reduce(
          (sumSharesPublisher: number, swPublisher: ISongWriterPublisher) => {
            return (
              sumSharesPublisher +
              (swPublisher.roba_share ? parseFloat(swPublisher.roba_share as string) : 0)
            );
          },
          0,
        );

        // check total share equal 100%
        if (sumSharesPublisher !== 100) {
          errors[songWriter.roba_songwritersid as string].roba_total_share_publisher = t(
            'Dashboard.Songs.WriterTotalSharesEqualError',
          );
        }

        // check total share over 100%
        if (sumSharesPublisher > 100) {
          errors[songWriter.roba_songwritersid as string].roba_total_share_publisher = t(
            'Dashboard.Songs.WriterTotalSharesLargerError',
          );
        }
      }
    });

    // remove songwriters from error obj with no errors
    songWriters.forEach((songWriter: ISongWriter) => {
      if (!Object.keys(errors[songWriter.roba_songwritersid as string]).length) {
        delete errors[songWriter.roba_songwritersid as string];
      }
    });

    // Share checks
    // composer share
    if (songWriters.some((songWriter: ISongWriter) => isComposer(songWriter.roba_role))) {
      if (
        songWriters.some(
          (songWriter: ISongWriter) => isComposer(songWriter.roba_role) && !songWriter.roba_share,
        )
      ) {
        errors.roba_total_share_composition = t('Dashboard.Songs.WriterSharesError');
      } else {
        const sumSharesComposer = songWriters
          .filter((songWriter: ISongWriter) => isComposer(songWriter.roba_role))
          .reduce((sumShares: number, songWriter: ISongWriter) => {
            if (songWriter.deleted) {
              return sumShares;
            }
            return (
              sumShares + (songWriter.roba_share ? parseFloat(songWriter.roba_share as string) : 0)
            );
          }, 0);
        if (sumSharesComposer !== 100) {
          errors.roba_total_share_composition = t('Dashboard.Songs.WriterTotalSharesEqualError');
        }
      }
    }

    // author share
    if (songWriters.some((songWriter: ISongWriter) => isAuthor(songWriter.roba_role))) {
      if (
        songWriters.some(
          (songWriter: ISongWriter) =>
            isAuthor(songWriter.roba_role) && !songWriter.roba_share_text,
        )
      ) {
        errors.roba_total_share_text = t('Dashboard.Songs.WriterSharesError');
      } else {
        const sumSharesAuthors = songWriters
          .filter((songWriter: ISongWriter) => isAuthor(songWriter.roba_role))
          .reduce((sumShares: number, songWriter: ISongWriter) => {
            return (
              sumShares +
              (songWriter.roba_share_text ? parseFloat(songWriter.roba_share_text as string) : 0)
            );
          }, 0);
        if (sumSharesAuthors !== 100) {
          errors.roba_total_share_text = t('Dashboard.Songs.WriterTotalSharesEqualError');
        }
      }
    }

    // editor shares
    if (
      songWriters.some(
        (songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue,
      )
    ) {
      if (
        songWriters.some(
          (songWriter: ISongWriter) =>
            songWriter.roba_role === songWriterArrangerRoleValue && !songWriter.roba_share_editor,
        )
      ) {
        errors.roba_total_share_editor = t('Dashboard.Songs.WriterSharesError');
      } else {
        const sumSharesEditors = songWriters
          .filter((songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue)
          .reduce((sumShares: number, songWriter: ISongWriter) => {
            return (
              sumShares +
              (songWriter.roba_share_editor
                ? parseFloat(songWriter.roba_share_editor as string)
                : 0)
            );
          }, 0);
        if (sumSharesEditors !== 100) {
          errors.roba_total_share_editor = t('Dashboard.Songs.WriterTotalSharesEqualError');
        }
      }
    }

    // text editor share
    if (
      songWriters.some(
        (songWriter: ISongWriter) => songWriter.roba_role === songWriterAdaptorRoleValue,
      )
    ) {
      if (
        songWriters.some(
          (songWriter: ISongWriter) =>
            songWriter.roba_role === songWriterAdaptorRoleValue &&
            !songWriter.roba_share_editor_text,
        )
      ) {
        errors.roba_total_share_text_editor = t('Dashboard.Songs.WriterSharesError');
      } else {
        const sumSharesTextEditors = songWriters
          .filter((songWriter: ISongWriter) => songWriter.roba_role === songWriterAdaptorRoleValue)
          .reduce((sumShares: number, songWriter: ISongWriter) => {
            return (
              sumShares +
              (songWriter.roba_share_editor_text
                ? parseFloat(songWriter.roba_share_editor_text as string)
                : 0)
            );
          }, 0);
        if (sumSharesTextEditors !== 100) {
          errors.roba_total_share_text_editor = t('Dashboard.Songs.WriterTotalSharesEqualError');
        }
      }
    }

    // Role checks
    // no composer check
    if (
      !errors.general &&
      !songWriters.some((songWriter: ISongWriter) => isComposer(songWriter.roba_role)) &&
      !songWriters.some(
        (songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue,
      )
    ) {
      errors.general = t('Dashboard.Songs.NoComposersError');
    }

    // only controlled check
    if (
      !errors.general &&
      !songWriters.some((songWriter: ISongWriter) => songWriter.roba_iscollecting)
    ) {
      errors.general = t('Dashboard.Songs.NoControlledWriterError');
    }

    // only editors check
    if (
      !errors.general &&
      (onlyEditorsInComposerCategory(songWriters) ||
        onlyTextEditorsInAuthorCategory(songWriters)) &&
      !ispublicdomain
    ) {
      errors.roba_public_domain_check = true;
    }

    // editor present, public domain or editing permission check
    if (
      !errors.general &&
      ((songWriters.some((songWriter: ISongWriter) => isComposer(songWriter.roba_role)) &&
        songWriters.some(
          (songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue,
        )) ||
        (songWriters.some((songWriter: ISongWriter) => isAuthor(songWriter.roba_role)) &&
          songWriters.some(
            (songWriter: ISongWriter) => songWriter.roba_role === songWriterAdaptorRoleValue,
          ))) &&
      !ispublicdomain &&
      !file &&
      !uploadedFileName
    ) {
      errors.general = t('Dashboard.Songs.PublicDomainEditingPermissionError');
    }
  }

  // file
  if (file && file.size > 1000000) {
    errors.file = t('Dashboard.Songs.FileSizeError');
  }

  // arValue
  if (arValue < 0.352 || arValue > 0.802) {
    errors.arValue = 'ArValue';
  }

  if (arValue !== robaGemaPerformanceDistributionDefault && !customArValueConfirmed) {
    errors.CustomArValueConfirmed = true;
  }

  return errors;
};

export const getSongFormSongDataErrors = (
  formData: ISong,
  titles: ISongAlternativeTitle[],
  t: any,
) => {
  const errors = validateRequiredFields(songRequiredFields, formData, t);

  if (!formData.roba_hassamples && formData.roba_sampleslicensed) {
    errors.roba_hassamples = t('Dashboard.Songs.IncludesSamplesError');
  }

  if (formData.roba_hassamples && !formData.roba_sampleslicensed) {
    errors.roba_sampleslicensed = t('Dashboard.Songs.RightsSamplesError');
  }

  if (titles.length && !titles[titles.length - 1].roba_title) {
    errors.titles = 'Bitte alternativen Titel angeben';
  }

  return errors;
};

export const isComposer = (role: number | null | undefined): boolean => {
  return !!role && [songWriterRoleComposerValue, songWriterRoleComposerAuthorValue].includes(role);
};

export const isAuthor = (role: number | null | undefined): boolean => {
  return !!role && [songWriterRoleAuthorValue, songWriterRoleComposerAuthorValue].includes(role);
};

export const getSongWritersTotalShare = (
  songWriter: ISongWriter,
  songWriters: ISongWriter[],
): number => {
  const shareComposition = songWriter.roba_share ? parseFloat(songWriter.roba_share as string) : 0;
  const shareText = songWriter.roba_share_text
    ? parseFloat(songWriter.roba_share_text as string)
    : 0;
  const shareEditor = songWriter.roba_share_editor
    ? parseFloat(songWriter.roba_share_editor as string)
    : 0;
  const shareTextEditor = songWriter.roba_share_editor_text
    ? parseFloat(songWriter.roba_share_editor_text as string)
    : 0;

  const sWriters = songWriters.filter((songWriter: ISongWriter) => !songWriter.deleted);
  let quotient = 0;

  if (sWriters.some((songWriter: ISongWriter) => isAuthor(songWriter.roba_role))) {
    quotient += 1;
  }

  if (sWriters.some((songWriter: ISongWriter) => isComposer(songWriter.roba_role))) {
    quotient += 1;
  }

  if (
    sWriters.some((songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue)
  ) {
    quotient += 1;
  }

  if (
    sWriters.some((songWriter: ISongWriter) => songWriter.roba_role === songWriterAdaptorRoleValue)
  ) {
    quotient += 1;
  }

  const totalShare = (shareComposition + shareText + shareEditor + shareTextEditor) / quotient;
  return totalShare;
};

export const onlyEditorsInComposerCategory = (songWriters: ISongWriter[]): boolean => {
  const editors = songWriters.filter(
    (songWriter: ISongWriter) => songWriter.roba_role === songWriterArrangerRoleValue,
  );
  const composers = songWriters.filter((songWriter: ISongWriter) =>
    isComposer(songWriter.roba_role),
  );
  return !!editors.length && !composers.length;
};

export const onlyTextEditorsInAuthorCategory = (songWriters: ISongWriter[]): boolean => {
  const textEditors = songWriters.filter(
    (songWriter: ISongWriter) => songWriter.roba_role === songWriterAdaptorRoleValue,
  );
  const authors = songWriters.filter((songWriter: ISongWriter) => isAuthor(songWriter.roba_role));
  return !!textEditors.length && !authors.length;
};
