import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  ESongState,
  ISong,
  ISongAlternativeTitle,
  ISongRecording,
  ISongWriter,
} from '../../../interfaces/Song';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';
import {
  getSongRecordings,
  getSongWriters,
  getWriters,
  updateSong,
} from '../../../services/httpService';
import { ISongFormStep, ISongFormSteps } from './SongForm';
import styles from './SongForm.module.scss';
import { IWriter } from '../../../interfaces/Writer';
import { useAuth } from '../../provider/AuthProvider';
import { ISelectOption } from '../../../interfaces/SelectOption';
import { validateRequiredFields } from '../../../helper/ValidationHelper';
import { SongFormOverviewDetails } from '../../lib/SongFormOverviewDetails/SongFormOverviewDetails';

export interface ISongFormOverviewProps {
  songData: ISong;
  fieldOptions: { [field: string]: ISelectOption[] } | undefined;
  titles: ISongAlternativeTitle[];
  steps: ISongFormSteps;
  onDelete: () => void;
  onStepChange: (step: ISongFormStep) => void;
}

export const SongFormOverview: React.FC<ISongFormOverviewProps> = ({
  songData,
  fieldOptions,
  titles,
  steps,
  onDelete,
  onStepChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { selectedUser } = useAuth();

  const [isLoadingRecordings, setIsLoadingRecordings] = useState<boolean>(false);
  const [isLoadingSongWriters, setIsLoadingSongWriters] = useState<boolean>(false);
  const [recordings, setRecordings] = useState<ISongRecording[]>([]);
  const [writers, setWriters] = useState<IWriter[]>([]);
  const [songWriters, setSongWriters] = useState<ISongWriter[]>([]);
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ check1: boolean }>({
    check1: false,
  });
  const [errors, setErrors] = useState<{ [field: string]: string }>({});

  const hasErrors = (): boolean => {
    const requiredFields = ['check1'];
    const errors: any = validateRequiredFields(requiredFields, formData, t);

    if (!songWriters.length) {
      errors.writers = t('Dashboard.Songs.MinSongWritersError');
    }

    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleChange = (value: any, key: string) => {
    setFormData((formData: { check1: boolean }) => ({
      ...formData,
      [key]: value,
    }));
    if (value && errors[key]) {
      setErrors((errors: { [field: string]: string }) => {
        const nextErrors = { ...errors };
        delete nextErrors[key];
        return nextErrors;
      });
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!hasErrors()) {
      setActionIsRunning(true);
      try {
        const data: any = { ...songData, statuscode: ESongState.Submitted };
        delete data.file;
        delete data.uploadedFileName;
        await updateSong(data, []);
        navigate('/dashboard/songs');
      } catch {
        toast.error(t('Dashboard.Songs.SongCompleteError'));
        setActionIsRunning(false);
      } finally {
        setActionIsRunning(true);
      }
    }
  };

  const _getSongWriters = async (): Promise<void> => {
    setIsLoadingSongWriters(true);
    try {
      const writers = await getWriters(/* selectedUser?.defaultAgreement as string */);
      setWriters(writers);
      const response = await getSongWriters(params.id as string);
      const songWriters = response.value?.map((songWriter: any) => ({
        roba_songwritersid: songWriter.roba_songwritersid,
        roba_title: songWriter.roba_title,
        roba_name: songWriter.roba_name,
        roba_writer: songWriter._roba_writer_value,
        roba_role: songWriter.roba_role,
        roba_share: songWriter.roba_share,
        roba_share_text: songWriter.roba_share_text,
        roba_share_editor: songWriter.roba_share_editor,
        roba_share_editor_text: songWriter.roba_share_editor_text,
        roba_iscollecting: songWriter.roba_iscollecting,
        publisher: songWriter.publisher.map((publisher: any) => ({
          roba_songwriters_publishersid: publisher.roba_songwriters_publishersid,
          roba_agreementipiid: publisher._roba_agreementipiid_value,
          roba_share: publisher.roba_share,
          roba_unmanagedpublishername: publisher.roba_unmanagedpublishername,
          roba_unmanagedpublisheripinumber: publisher.roba_unmanagedpublisheripinumber,
        })),
      }));
      setSongWriters(songWriters);
      setIsLoadingSongWriters(false);
      if (!songWriters.length) {
        const errors = { writers: t('Dashboard.Songs.MinSongWritersError') };
        setErrors(errors);
      }
    } catch {
      toast.error(t('Dashboard.Songs.SongWritersGetError'));
    }
  };

  const getRecordings = async () => {
    setIsLoadingRecordings(true);
    try {
      const response = await getSongRecordings(params.id as string);
      const recordings = response?.map((recording: any) => {
        const artists = recording.artists.map((artist: any) => ({
          roba_artistid: artist.roba_artistid,
          roba_appleid: artist.roba_appleid,
          roba_isni: artist.roba_isni,
          roba_name: artist.roba_name,
          roba_type: artist.roba_type,
          roba_spotifyid: artist.roba_spotifyid,
        }));
        return {
          roba_recordingid: recording.roba_recordingid,
          roba_ismainrecording: recording.roba_ismainrecording,
          artists,
          roba_type: recording.roba_type,
          roba_type_commission: recording.roba_type_commission,
          roba_islibrary: recording.roba_islibrary,
          roba_title: recording.roba_title,
          roba_mixversion: recording.roba_mixversion,
          roba_isrc: recording.roba_isrc,
          roba_durationmins: recording.roba_durationmins,
          roba_durationsecs: recording.roba_durationsecs,
          roba_title_language: recording.roba_title_language,
          roba_spotifyid: recording.roba_spotifyid,
          roba_album_type: recording.roba_album_type,
          roba_album: recording.roba_album,
          roba_album_version: recording.roba_album_version,
          roba_album_releasedon: recording.roba_album_releasedon,
          roba_album_barcode: recording.roba_album_barcode,
          roba_album_barcode_ean: recording.roba_album_barcode_ean,
          roba_album_cataloguenumber: recording.roba_album_cataloguenumber,
          roba_isinstrumental: recording.roba_isinstrumental,
          roba_lyrics_language: recording.roba_lyrics_language,
          roba_lyrics: recording.roba_lyrics,
          uploadedFileName: recording.uploadedFileName,
          roba_imageurl: recording.roba_imageurl,
        };
      });
      setRecordings(recordings);
      setIsLoadingRecordings(false);
    } catch {
      toast.error(t('Dashboard.Songs.RecordingsGetError'));
    }
  };

  useEffect(() => {
    if (params.id && selectedUser) {
      getRecordings();
      _getSongWriters();
    }
  }, [params, selectedUser]);

  if (!songData?.roba_songid || isLoadingSongWriters || isLoadingRecordings) {
    return (
      <CardContent>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
        </Box>
      </CardContent>
    );
  }

  return (
    <div className={styles.accountForm}>
      <CardHeader title={t('Dashboard.Songs.Overview')} />
      <Divider />
      <CardContent>
        <SongFormOverviewDetails
          songData={songData}
          actionIsRunning={actionIsRunning}
          fieldOptions={fieldOptions}
          titles={titles}
          writers={writers}
          songWriters={songWriters}
          recordings={recordings}
        />
        <Grid container spacing={3} style={{ marginTop: '48px' }}>
          <Grid item md={12} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="check1"
                    checked={formData.check1}
                    style={{ color: errors.check1 ? 'rgb(209, 67, 67)' : 'inherit' }}
                  />
                }
                style={{ color: errors.check1 ? 'rgb(209, 67, 67)' : 'inherit' }}
                label={t('Dashboard.Songs.OverviewCheckbox')}
                disabled={actionIsRunning}
                onChange={(e: any) => handleChange(e.target.checked, 'check1')}
              />
            </FormGroup>
          </Grid>
          <Grid item md={12} xs={12}>
            {errors?.writers && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error sx={{ fontSize: '16px' }}>
                  {errors?.writers}
                </FormHelperText>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          flexWrap: 'wrap',
          m: -1,
          position: 'relative',
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            mt: 1,
          }}
        >
          <Grid item md={6} xs={12}>
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning}
              onClick={() => onStepChange(steps.recordings)}
            >
              {t('Dashboard.Common.Prev')}
            </Button>

            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning || !!errors.writers}
              onClick={handleSubmit}
            >
              {t('Dashboard.Songs.Complete')}
            </Button>
          </Grid>
          <Grid item md={6} xs={12} style={{ textAlign: 'right' }}>
            <Link to="/dashboard/songs" style={{ textDecoration: 'none' }}>
              <Button component="a" variant="outlined" disabled={actionIsRunning}>
                {t('Dashboard.Songs.Cancel')}
              </Button>
            </Link>
            {songData?.roba_songid && (
              <Button sx={{ m: 1 }} color="error" disabled={actionIsRunning} onClick={onDelete}>
                {t('Dashboard.Songs.DeleteSong')}
              </Button>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </div>
  );
};
