import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  createCachedWriter,
  getPublisherFromCache,
  getWriter,
  searchPseudonyms,
  searchPublisher,
  searchWriter,
} from '../../services/httpService';

export interface IPseudonymSearchProps {
  initValue?: string;
  disabled: boolean;
  onSelect: (selectedItem: any) => void;
  onClear?: () => void;
}

export interface IPseudonymSearchSuggestion {
  id?: string;
  label: string;
  roba_ipinumber: string;
  roba_firstname: string;
  roba_name: string;
}

export const PseudonymSearch: React.FC<IPseudonymSearchProps> = ({
  initValue,
  disabled,
  onSelect,
  onClear,
}) => {
  const { t } = useTranslation();
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState<string>('');
  const [autoCompleteSelectedValue, setAutoCompleteSelectedValue] = useState<any>(null);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false);

  const getSuggestions = async (search: string) => {
    setLoadingSuggestions(true);
    const writer = await searchPseudonyms(search);
    const suggestions = writer.value?.map((item: any) => {
      return {
        id: item.roba_writerid,
        label: `${item.roba_firstname ? `${item.roba_firstname} ` : ''}${item.roba_name}${
          item.roba_ipinumber ? `(IPI: ${item.roba_ipinumber})` : ''
        }`,
        roba_firstname: item.roba_firstname,
        roba_name: item.roba_name,
        roba_ipinumber: item.roba_ipinumber,
      };
    });
    suggestions.sort((a: IPseudonymSearchSuggestion, b: IPseudonymSearchSuggestion) =>
      a.roba_name?.localeCompare(b.roba_name),
    );
    setSuggestions(suggestions || []);
    setLoadingSuggestions(false);
  };

  const debounceAutoCompleteInputValueChange = useCallback(
    debounce((search: string) => {
      if (search) {
        getSuggestions(search);
      }
    }, 500),
    [],
  );

  const handleAutoCompleteInputValueChange = (e: any) => {
    setLoadingSuggestions(false);
    if (e && e.target.value !== 0) {
      if (suggestions.length) {
        setSuggestions([]);
      }
      setLoadingSuggestions(false);
      setAutoCompleteInputValue(e.target.value);
      debounceAutoCompleteInputValueChange(e.target.value);
      if (onClear && e && e.target.value === '') {
        onClear();
      }
    }
  };

  const handleAutoCompleteSelectedValueChange = async (
    selectedSuggestion: IPseudonymSearchSuggestion,
    triggerOnSelect = true,
  ) => {
    setAutoCompleteSelectedValue(selectedSuggestion);
    setAutoCompleteInputValue(selectedSuggestion.label);
    if (triggerOnSelect) {
      onSelect(selectedSuggestion.id);
    }
  };

  const setInitValue = async () => {
    const suggestion = suggestions.find(
      (suggestion: IPseudonymSearchSuggestion) => suggestion.id === initValue,
    );
    if (suggestion) {
      handleAutoCompleteSelectedValueChange(suggestion, false);
    } else {
      const initWriter = await getWriter(initValue as string);
      if (initWriter) {
        const suggestion = {
          id: initWriter.roba_writerid,
          label: `${initWriter.roba_firstname ? `${initWriter.roba_firstname} ` : ''}${
            initWriter.roba_name
          }${initWriter.roba_ipinumber ? `(IPI: ${initWriter.roba_ipinumber})` : ''}`,
          roba_firstname: initWriter.roba_firstname,
          roba_name: initWriter.roba_name,
          roba_ipinumber: initWriter.roba_ipinumber,
        };
        setSuggestions([suggestion]);
        handleAutoCompleteSelectedValueChange(suggestion, false);
      }
    }
  };

  useEffect(() => {
    if (initValue) {
      setInitValue();
    }
  }, [initValue]);

  return (
    <Autocomplete
      filterOptions={(x: any) => x}
      options={suggestions}
      value={autoCompleteSelectedValue}
      inputValue={autoCompleteInputValue}
      onInputChange={handleAutoCompleteInputValueChange}
      onChange={(_: any, selectedSuggestion: IPseudonymSearchSuggestion) =>
        handleAutoCompleteSelectedValueChange(selectedSuggestion)
      }
      disabled={disabled}
      disableClearable
      loading={loadingSuggestions}
      noOptionsText={t('Dashboard.Writers.NoWriters')}
      loadingText={t('Dashboard.Writers.LoadingWriters')}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label={t('Dashboard.Writers.LinkToWriter')}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingSuggestions ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <SearchIcon style={{ position: 'relative', top: '-6px' }} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
