import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import { InfoOutlined } from '@mui/icons-material';
import styles from './WriterForm.module.scss';
import { IWriter, newWriter } from '../../../interfaces/Writer';
import {
  createWriter,
  getFieldOptions,
  updateWriter,
  getPlaces,
  deleteWriter,
  _isSongWriter,
  getWriter,
  getPseudonyms,
} from '../../../services/httpService';
import { ISelectOption } from '../../../interfaces/SelectOption';
import { AKM, GEMA, SUISA } from '../../../interfaces/AccountFormData';
import { getWriterFormErrors } from './WriterFormHelper';
import { ISuggestion, ISuggestionContextItem } from '../../../interfaces/Suggestion';
import { useAuth } from '../../provider/AuthProvider';
import loadingSpinner from '../../../assets/images/loadingSpinner.svg';
import { IPISearch } from '../../lib/IPISearch';
import { DeleteDialog } from '../../lib/DeleteDialog';
import { PseudonymSearch } from '../../lib/PseudonymSearch';

export interface IWriterFormProps {
  writerToEdit?: IWriter;
  onModalSave?: (createdWriterId?: string | undefined) => void;
  onModalClose?: () => void;
}

export const WriterForm: React.FC<IWriterFormProps> = ({
  writerToEdit,
  onModalSave,
  onModalClose,
}) => {
  const { t, i18n } = useTranslation();
  const { selectedUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSongWriter, setIsSongWriter] = useState<boolean>(false);
  const [pseudonyms, setPseudonyms] = useState<IWriter[]>([]);
  const [origFormData, setOrigFormData] = useState<IWriter | undefined>(undefined);
  const [formData, setFormData] = useState<IWriter>(newWriter);
  const [formDataAfterIPISelect, setFormDataAfterIPISelect] = useState<IWriter>(newWriter);
  const [collectingSocieties, setCollectingSocieties] = useState<ISelectOption[]>([]);
  const [errors, setErrors] = useState<{ [field: string]: string }>({});
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const [actionIsRunning, setActionIsRunning] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [isIPISearch, setIsIPISearch] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const isInModal = useMemo(() => location.pathname.includes('songs'), [location]);
  const isEditForm = useMemo(
    () => location.pathname.includes('edit') && (!isInModal || writerToEdit),
    [location],
  );

  const hasValidAddress = useMemo(
    () =>
      !!origFormData?.roba_street1 &&
      !!origFormData?.roba_postalcode &&
      !!origFormData?.roba_city &&
      !!origFormData?.roba_country,
    [origFormData],
  );

  const hasErrors = (): boolean => {
    const errors = getWriterFormErrors(formData, t);
    setErrors(errors);
    return !!Object.keys(errors).length;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!hasErrors()) {
      try {
        setActionIsRunning(true);
        /*
        const _roba_agreementid_value = formData._roba_agreementid_value
          ? formData._roba_agreementid_value
          : (selectedUser?.defaultAgreement as string);
        */
        const data = {
          ...formData,
          // roba_firstname: convertNonAsciiLetters(formData.roba_firstname),
          // roba_lastname: convertNonAsciiLetters(formData.roba_lastname),
          roba_firstname: formData.roba_firstname,
          roba_lastname: formData.roba_lastname,
          // _roba_agreementid_value,
        };

        let createdWriter;
        if (data.roba_writerid) {
          await updateWriter(data);
        } else {
          createdWriter = await createWriter(data);
        }
        toast.success(
          data.roba_writerid
            ? t('Dashboard.Writers.WriterUpdateSuccess')
            : t('Dashboard.Writers.WriterCreateSuccess'),
        );

        if (isInModal && onModalSave) {
          onModalSave(createdWriter?.roba_writerid);
        } else {
          navigate(`/dashboard/writers`);
        }
      } catch (e: any) {
        let errorMessage = '';
        if (e?.response?.data === 'PARENT_WRITER_ERROR') {
          errorMessage = t('Dashboard.Writers.ParentWriterError');
        } else if (e?.response?.data?.error === 'IPI_EXISTS') {
          errorMessage = t('Dashboard.Writers.IPIExistsError');
        } else {
          errorMessage = formData.roba_writerid
            ? t('Dashboard.Writers.WriterUpdateError')
            : t('Dashboard.Writers.WriterCreateError');
        }
        toast.error(errorMessage);
      } finally {
        setActionIsRunning(false);
      }
    }
  };

  const handleChange = (value: any, key: string) => {
    const nextFormdata = { ...formData, [key]: value };
    setFormData(nextFormdata);
    if (value && errors[key]) {
      const nextErrors = { ...errors };
      delete nextErrors[key];
      setErrors(nextErrors);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteDialogOpen(false);
      setActionIsRunning(true);
      await deleteWriter(formData.roba_writerid as string);
      navigate(`/dashboard/writers`);
      toast.success(t('Dashboard.Writers.WriterDeleteSuccess'));
    } catch (e) {
      toast.error(t('Dashboard.Writers.WriterDeleteError'));
    } finally {
      setActionIsRunning(false);
    }
  };

  const onAddressChange = (addressData: Partial<IWriter>): void => {
    setFormData({ ...formData, ...addressData });
    const nextErrors = { ...errors };
    Object.keys(addressData).forEach((field: string) => {
      if (addressData[field as keyof IWriter] && errors[field]) {
        delete nextErrors[field];
      }
    });
    setErrors(nextErrors);
  };

  const getSelectFieldOptions = async (): Promise<void> => {
    try {
      const collectingSocieties = await (
        await getFieldOptions('collectingSocieties')
      ).filter((option: ISelectOption) => ![GEMA, AKM, SUISA].includes(option.value));
      setCollectingSocieties(collectingSocieties);
    } catch (e) {
      toast.error(t('Dashboard.Writers.DropdownOptionsError'));
    }
  };

  const handleSuggestionSelect = (suggestion: ISuggestion) => {
    const number = suggestion.address ? ` ${suggestion.address}` : '';
    const street = suggestion.text + number;
    const zipcode =
      suggestion?.context?.find((item: ISuggestionContextItem) => item.id.includes('postcode'))
        ?.text_de || '';
    const city =
      suggestion?.context.find((item: ISuggestionContextItem) => item.id.includes('place'))
        ?.text_de || '';
    const state =
      suggestion?.context.find((item: ISuggestionContextItem) => item.id.includes('region'))
        ?.text_de || '';
    const country =
      suggestion?.context.find((item: ISuggestionContextItem) => item.id.includes('country'))
        ?.text_de || '';

    const addressData: Partial<IWriter> = {
      roba_street1: street,
      roba_postalcode: zipcode,
      roba_city: city,
      roba_stateorprovince: state,
      roba_country: country,
    };

    onAddressChange(addressData);
    setSuggestions([]);
  };

  const getSuggestions = async (address: string) => {
    const places = await getPlaces(address);
    setSuggestions(places?.data?.features || []);
  };

  const debounceAddressChange = useCallback(
    debounce((address: string) => {
      getSuggestions(address);
    }, 500),
    [],
  );

  const handleAddressChange = (e: any) => {
    if (suggestions.length) {
      setSuggestions([]);
    }
    handleChange(e.target.value, 'roba_street1');
    debounceAddressChange(e.target.value);
  };

  const handleIPISearchSelect = (selectedWriter: any) => {
    const nextFormData = {
      ...formData,
      roba_firstname: selectedWriter.roba_firstname,
      roba_lastname: selectedWriter.roba_lastname,
      roba_ipinumber: selectedWriter.roba_ipinumber,
    };
    setFormData(nextFormData);
    setFormDataAfterIPISelect(nextFormData);
    setIsIPISearch(true);
  };

  const init = async (): Promise<void> => {
    setIsLoading(true);
    try {
      let formData;
      if (writerToEdit) {
        formData = getFormData(writerToEdit);
      } else {
        const writerId = params?.id;
        const writer = await getWriter(writerId as string);
        formData = getFormData(writer);
      }
      setFormData(formData);
      setOrigFormData(formData);
      const songWriter = await _isSongWriter(formData?.roba_writerid as string);
      setIsSongWriter(!!songWriter.length);
      const pseudonyms = await getPseudonyms(formData?.roba_writerid as string);
      setPseudonyms(pseudonyms);
    } finally {
      setIsLoading(false);
    }
  };

  const getFormData = (writer: IWriter): IWriter => {
    return {
      roba_writerid: writer.roba_writerid,
      roba_firstname: writer.roba_firstname,
      roba_lastname: writer.roba_lastname,
      roba_email: writer.roba_email,
      roba_ipinumber: writer.roba_ipinumber,
      roba_society_mechanical: writer.roba_society_mechanical,
      roba_society_performing: writer.roba_society_performing,
      roba_street1: writer.roba_street1,
      roba_street2: writer.roba_street2,
      roba_postalcode: writer.roba_postalcode,
      roba_city: writer.roba_city,
      roba_stateorprovince: writer.roba_stateorprovince,
      roba_country: writer.roba_country,
      _roba_pseudonymparentwriterid_value: writer._roba_pseudonymparentwriterid_value,
    };
  };

  const handleClose = () => {
    if (isInModal && onModalClose) {
      onModalClose();
    } else {
      navigate(`/dashboard/writers`);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      init();
    }
  }, [isEditForm, params, selectedUser, writerToEdit]);

  useEffect(() => {
    getSelectFieldOptions();
  }, []);

  if (isLoading || !selectedUser) {
    return (
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" className={styles.writerForm}>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={showOverlay}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isInModal && (
        <Box sx={{ mb: 4 }}>
          <Link to="/dashboard/writers" style={{ textDecoration: 'none' }}>
            <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography
              variant="subtitle2"
              style={{ display: 'inline-block', position: 'relative', top: '-6px' }}
            >
              {t('Dashboard.Writers.Writers')}
            </Typography>
          </Link>
        </Box>
      )}
      <Box mt={3}>
        <Card>
          <CardHeader
            title={
              isEditForm ? t('Dashboard.Writers.EditWriter') : t('Dashboard.Writers.CreateWriter')
            }
            subheader={isInModal ? t('Dashboard.Writers.SubheaderInfo') : ''}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12} sx={{ mb: 3 }}>
                <IPISearch
                  disabled={actionIsRunning || isSongWriter}
                  label={t('Dashboard.Writers.SearchWriters')}
                  noOptionsText={t('Dashboard.Writers.NoWriters')}
                  loadingText={t('Dashboard.Writers.LoadingWriters')}
                  onSelect={handleIPISearchSelect}
                  onShowOverlay={(showOverlay: boolean) => setShowOverlay(showOverlay)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  key={formDataAfterIPISelect.roba_firstname}
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Writers.FirstName')}
                  name="roba_firstname"
                  value={formData.roba_firstname}
                  helperText={errors.roba_firstname}
                  error={!!errors.roba_firstname}
                  disabled={
                    actionIsRunning || isSongWriter || isIPISearch || !!formData.roba_writerid
                  }
                  onChange={(e: any) => handleChange(e.target.value, 'roba_firstname')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  key={formDataAfterIPISelect.roba_lastname}
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Writers.LastName')}
                  name="roba_lastname"
                  required
                  value={formData.roba_lastname}
                  helperText={errors.roba_lastname}
                  error={!!errors.roba_lastname}
                  disabled={
                    actionIsRunning || isSongWriter || isIPISearch || !!formData.roba_writerid
                  }
                  onChange={(e: any) => handleChange(e.target.value, 'roba_lastname')}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Writers.EMail')}
                  name="roba_email"
                  value={formData.roba_email}
                  helperText={errors.roba_email}
                  error={!!errors.roba_email}
                  disabled={actionIsRunning || !!origFormData?.roba_email}
                  onChange={(e: any) => handleChange(e.target.value, 'roba_email')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>{t('Dashboard.Writers.CollectingSocietyPR')}</InputLabel>
                  <Select
                    variant="filled"
                    label={t('Dashboard.Writers.CollectingSocietyPR')}
                    value={formData.roba_society_performing}
                    error={!!errors.roba_society_performing}
                    disabled={actionIsRunning || !!origFormData?.roba_society_performing}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_society_performing')}
                  >
                    <MenuItem />
                    <MenuItem value={GEMA}>GEMA</MenuItem>
                    <MenuItem value={AKM}>AKM</MenuItem>
                    <MenuItem value={SUISA}>SUISA</MenuItem>
                    <hr />
                    {collectingSocieties.map((fieldOption: ISelectOption) => (
                      <MenuItem key={fieldOption.value} value={fieldOption.value}>
                        {fieldOption.labels[i18n.language.includes('en') ? 'en' : 'de']}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.roba_society_performing && (
                    <FormHelperText error>{errors.roba_society_performing}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>{t('Dashboard.Writers.CollectingSocietyMR')}</InputLabel>
                  <Select
                    variant="filled"
                    label={t('Dashboard.Writers.CollectingSocietyMR')}
                    value={formData.roba_society_mechanical}
                    error={!!errors.roba_society_mechanical}
                    disabled={actionIsRunning || !!origFormData?.roba_society_mechanical}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_society_mechanical')}
                  >
                    <MenuItem />
                    <MenuItem value={GEMA}>GEMA</MenuItem>
                    <MenuItem value={AKM}>AKM</MenuItem>
                    <MenuItem value={SUISA}>SUISA</MenuItem>
                    <hr />
                    {collectingSocieties.map((fieldOption: ISelectOption) => (
                      <MenuItem key={fieldOption.value} value={fieldOption.value}>
                        {fieldOption.labels[i18n.language.includes('en') ? 'en' : 'de']}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.roba_society_mechanical && (
                    <FormHelperText error>{errors.roba_society_mechanical}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  key={`${formData.roba_society_performing}-${formData.roba_society_mechanical}`}
                  fullWidth
                  autoComplete="off"
                  variant="filled"
                  label={t('Dashboard.Writers.IPINumber')}
                  name="roba_ipinumber"
                  required={
                    !!formData.roba_society_performing || !!formData.roba_society_mechanical
                  }
                  value={formData.roba_ipinumber}
                  helperText={errors.roba_ipinumber}
                  error={!!errors.roba_ipinumber}
                  disabled={
                    actionIsRunning ||
                    isSongWriter ||
                    isIPISearch ||
                    !!origFormData?.roba_society_performing ||
                    !!origFormData?.roba_society_mechanical
                  }
                  onChange={(e: any) => handleChange(e.target.value, 'roba_ipinumber')}
                />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="off"
                    variant="filled"
                    label={t('Dashboard.Writers.Street')}
                    value={formData.roba_street1}
                    helperText={errors.roba_street1}
                    error={!!errors.roba_street1}
                    disabled={actionIsRunning || hasValidAddress}
                    onChange={handleAddressChange}
                  />
                  <div className={styles.autocompleteWrapper}>
                    {suggestions.map((suggestion: ISuggestion) => (
                      <MenuItem
                        value={suggestion.id}
                        onClick={() => handleSuggestionSelect(suggestion)}
                      >
                        {suggestion.place_name}
                      </MenuItem>
                    ))}
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="off"
                    variant="filled"
                    label={t('Dashboard.Writers.Street2')}
                    value={formData.roba_street2}
                    helperText={errors.roba_street2}
                    error={!!errors.roba_street2}
                    disabled={actionIsRunning || hasValidAddress}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_street2')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="off"
                    type="number"
                    variant="filled"
                    label={t('Dashboard.Writers.ZIPCode')}
                    value={formData.roba_postalcode}
                    helperText={errors.roba_postalcode}
                    error={!!errors.roba_postalcode}
                    disabled={actionIsRunning || hasValidAddress}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_postalcode')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="off"
                    variant="filled"
                    label={t('Dashboard.Writers.City')}
                    value={formData.roba_city}
                    helperText={errors.roba_city}
                    error={!!errors.roba_city}
                    disabled={actionIsRunning || hasValidAddress}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_city')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="off"
                    variant="filled"
                    label={t('Dashboard.Writers.State')}
                    value={formData.roba_stateorprovince}
                    helperText={errors.roba_stateorprovince}
                    error={!!errors.roba_stateorprovince}
                    disabled={actionIsRunning || hasValidAddress}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_stateorprovince')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="off"
                    variant="filled"
                    label={t('Dashboard.Writers.Country')}
                    value={formData.roba_country}
                    helperText={errors.roba_country}
                    error={!!errors.roba_country}
                    disabled={actionIsRunning || hasValidAddress}
                    onChange={(e: any) => handleChange(e.target.value, 'roba_country')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent sx={{ paddingTop: '0px' }}>
            <h3 style={{ fontSize: '1.0909rem', marginBottom: 0 }}>
              {t('Dashboard.Writers.LinkedWriters')}
              <Tooltip
                title={<p style={{ fontSize: '16px' }}>{t('Dashboard.Writers.PseudonymsInfo')}</p>}
              >
                <IconButton>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </h3>
            <Grid container>
              <Grid item xs={10}>
                {(!formData.roba_writerid || !pseudonyms.length) && (
                  <PseudonymSearch
                    disabled={actionIsRunning}
                    initValue={formData._roba_pseudonymparentwriterid_value as string}
                    onSelect={(writerId: string) =>
                      handleChange(writerId, '_roba_pseudonymparentwriterid_value')
                    }
                    onClear={() => handleChange(undefined, '_roba_pseudonymparentwriterid_value')}
                  />
                )}
                {formData.roba_writerid && !!pseudonyms.length && (
                  <List>
                    {pseudonyms.map((writer: IWriter) => (
                      <ListItem disablePadding>
                        <ListItemButton
                          href={`/dashboard/writers/edit/${writer.roba_writerid}`}
                          target="_blank"
                        >
                          <ListItemIcon>
                            <OpenInNewIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${writer.roba_firstname ? `${writer.roba_firstname} ` : ''} ${
                              writer.roba_lastname
                            }`}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
              <Grid item xs={2}>
                {formData._roba_pseudonymparentwriterid_value && (
                  <a
                    href={`/dashboard/writers/edit/${formData._roba_pseudonymparentwriterid_value}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      display: 'block',
                      marginLeft: '10px',
                      marginTop: '15px',
                      cursor: 'pointer',
                    }}
                  >
                    <OpenInNewIcon fontSize="large" />
                    <p style={{ display: 'none' }}>Öffnen</p>
                  </a>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions
            sx={{
              flexWrap: 'wrap',
              m: -1,
            }}
          >
            <Button
              type="submit"
              sx={{ m: 1 }}
              variant="contained"
              disabled={actionIsRunning}
              onClick={handleSubmit}
            >
              {isEditForm || writerToEdit
                ? t('Dashboard.Writers.Save')
                : t('Dashboard.Writers.Create')}
            </Button>
            <Button
              component="a"
              sx={{
                m: 1,
                mr: 'auto',
              }}
              variant="outlined"
              disabled={actionIsRunning}
              onClick={handleClose}
            >
              {t('Dashboard.Writers.Cancel')}
            </Button>
            {isEditForm && formData?.roba_writerid && !isSongWriter && (
              <Button
                color="error"
                disabled={actionIsRunning}
                onClick={() => setDeleteDialogOpen(true)}
              >
                {t('Dashboard.Writers.DeleteWriter')}
              </Button>
            )}
          </CardActions>

          {actionIsRunning && (
            <CardContent style={{ paddingTop: '0' }}>
              <CircularProgress size={24} />
              <Typography
                style={{
                  display: 'inline-block',
                  marginLeft: '12px',
                  position: 'relative',
                  top: '-6px',
                }}
              >
                {t('Dashboard.Writers.SavingWriter')}
              </Typography>
            </CardContent>
          )}
        </Card>
      </Box>
      <DeleteDialog
        open={deleteDialogOpen}
        headline={t('Dashboard.Writers.ConfirmDeleteWriter')}
        onDelete={handleDelete}
        onClose={() => setDeleteDialogOpen(false)}
      />
    </Container>
  );
};
