import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { searchSong } from '../../services/httpService';

export interface ISpotifySearchProps {
  disabled: boolean;
  label?: string;
  clear?: boolean;
  error?: boolean;
  helperText?: string;
  onSelect: (suggestion: ISpotifySearchSuggestion) => void;
  onClick?: () => void;
}

export interface ISpotifySearchSuggestion {
  title: string;
  label: string;
  id: string;
  isrc: string;
  durationmins: string;
  durationsecs: string;
  image: string;
  performedby: string;
  album: string;
  releaseDate: Date;
  albumType: string;
  artists?: any[];
}

export const SpotifySearch: React.FC<ISpotifySearchProps> = ({
  disabled,
  clear,
  label,
  error,
  helperText,
  onSelect,
  onClick,
}) => {
  const { t } = useTranslation();

  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState<string>('');
  const [autoCompleteSelectedValue, setAutoCompleteSelectedValue] = useState<any>(null);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false);

  const getSuggestions = async (search: string) => {
    setLoadingSuggestions(true);
    let spotifyId = '';
    if (search.includes('open.spotify')) {
      const searchSplit = search.split('/');
      if (searchSplit.length) {
        spotifyId = searchSplit[searchSplit.length - 1];
      }
    }
    const searchValue = spotifyId ? '' : search;
    const songs = await searchSong(searchValue, spotifyId);
    const suggestions = songs.map((song: any) => {
      const artist = song.artists[0]?.name;
      const performedby = song.artists.map((artist: any) => artist.name).join(', ');
      const durationInSec = Math.round(song.duration_ms / 1000);
      const mins = Math.floor(durationInSec / 60);
      const secs = durationInSec % 60;
      return {
        title: song.name,
        label: `${artist} - ${song.name}`,
        id: song.id,
        isrc: song.external_ids.isrc,
        durationmins: mins.toString(),
        durationsecs: secs.toString(),
        image: song.album?.images[0]?.url,
        performedby,
        album: song.album.name,
        albumType: song.album.album_type,
        albumId: song.album.id,
        releaseDate: new Date(song.album.release_date),
        artists: song.artists,
      };
    });
    setSuggestions(suggestions);
    setLoadingSuggestions(false);
  };

  const debounceAutoCompleteInputValueChange = useCallback(
    debounce((search: string) => {
      if (search) {
        getSuggestions(search);
      }
    }, 500),
    [],
  );

  const handleAutoCompleteInputValueChange = (e: any) => {
    if (onClick) {
      onClick();
    }
    setLoadingSuggestions(false);
    if (e && e.target.value !== 0) {
      if (suggestions.length) {
        setSuggestions([]);
      }
      setLoadingSuggestions(false);
      setAutoCompleteInputValue(e.target.value);
      debounceAutoCompleteInputValueChange(e.target.value);
    }
  };

  const handleAutoCompleteSelectedValueChange = (_: any, newValue: any) => {
    setAutoCompleteSelectedValue(newValue);
    setAutoCompleteInputValue(newValue.label);
    onSelect(newValue);
  };

  useEffect(() => {
    if (clear) {
      setAutoCompleteSelectedValue(undefined);
      setAutoCompleteInputValue('');
    }
  }, [clear]);

  return (
    <Autocomplete
      options={suggestions}
      value={autoCompleteSelectedValue}
      inputValue={autoCompleteInputValue}
      onInputChange={handleAutoCompleteInputValueChange}
      onChange={handleAutoCompleteSelectedValueChange}
      disabled={disabled}
      disableClearable
      loading={loadingSuggestions}
      noOptionsText={t('Dashboard.Songs.NoRecordings')}
      loadingText={t('Dashboard.Songs.LoadingRecordings')}
      filterOptions={options => options}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label={label || t('Dashboard.Songs.SearchFromSpotify')}
          disabled={disabled}
          onClick={() => {
            if (onClick) onClick();
          }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingSuggestions ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <SearchIcon style={{ position: 'relative', top: '-6px' }} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img loading="lazy" width="40" src={option.image} alt="" />
          {option.label}
        </Box>
      )}
    />
  );
};
