import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import {
  createCachedWriter,
  getPublisherFromCache,
  searchPublisher,
  searchWriter,
} from '../../services/httpService';

export interface IIPISearchProps {
  initValue?: string;
  disabled: boolean;
  isPublisherSearch?: boolean;
  label: string;
  noOptionsText: string;
  loadingText: string;
  helperText?: string;
  error?: boolean;
  onSelect: (selectedItem: any) => void;
  onShowOverlay?: (showOverlay: boolean) => void;
  onClear?: () => void;
}

export interface IIPISearchSuggestion {
  id?: string;
  label: string;
  roba_ipinumber: string;
  roba_type: string;
  cached: boolean;
  roba_firstname?: string;
  roba_name?: string;
}

export const IPISearch: React.FC<IIPISearchProps> = ({
  initValue,
  disabled,
  isPublisherSearch,
  label,
  noOptionsText,
  loadingText,
  helperText,
  error,
  onSelect,
  onClear,
  onShowOverlay,
}) => {
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState<string>('');
  const [autoCompleteSelectedValue, setAutoCompleteSelectedValue] = useState<any>(null);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false);

  const formatName = (name: string) => {
    if (!name) {
      return '';
    }
    let words = name.split(' ');
    let formattedName;
    let formattedWords = words.map(
      (word: string) =>
        `${word.substring(0, 1).toUpperCase()}${word.substring(1, word.length).toLowerCase()}`,
    );
    formattedName = formattedWords.join(' ');
    words = name.split('-');
    if (words.length > 1) {
      formattedWords = words.map(
        (word: string) =>
          `${word.substring(0, 1).toUpperCase()}${word.substring(1, word.length).toLowerCase()}`,
      );
      formattedName = formattedWords.join('-');
    }
    return formattedName;
  };

  const getSuggestions = async (ipi: string) => {
    setLoadingSuggestions(true);
    let items;
    if (isPublisherSearch) {
      items = await searchPublisher(ipi);
    } else {
      items = await searchWriter(ipi);
    }
    const suggestions = items.cached?.value?.map((item: any) => {
      return {
        id: item.roba_writer_cacheid,
        label: `${item.roba_firstname ? `${item.roba_firstname} ` : ''}${item.roba_name} (IPI: ${
          item.roba_ipinumber
        })`,
        roba_firstname: item.roba_firstname,
        roba_name: item.roba_name,
        roba_ipinumber: item.roba_ipinumber,
        roba_type: item.roba_type,
        cached: true,
      };
    });
    if (items.api.length) {
      const ipiNumbers = suggestions.map(
        (suggestion: IIPISearchSuggestion) => suggestion.roba_ipinumber,
      );
      items.api.forEach((item: any) => {
        if (!ipiNumbers.includes(item.ipNameNumber.toString())) {
          suggestions.push({
            label: `${item.firstName ? `${formatName(item.firstName)} ` : ''}${formatName(
              item.name,
            )} (IPI: ${item.ipNameNumber})`,
            roba_ipinumber: item.ipNameNumber,
            roba_type: item.type,
            cached: false,
          });
        }
      });
    }
    setSuggestions(suggestions || []);
    setLoadingSuggestions(false);
  };

  const debounceAutoCompleteInputValueChange = useCallback(
    debounce((ipi: string) => {
      if (ipi) {
        getSuggestions(ipi);
      }
    }, 500),
    [],
  );

  const handleAutoCompleteInputValueChange = (e: any) => {
    setLoadingSuggestions(false);
    if (e && e.target.value !== 0) {
      if (suggestions.length) {
        setSuggestions([]);
      }
      setLoadingSuggestions(false);
      setAutoCompleteInputValue(e.target.value);
      debounceAutoCompleteInputValueChange(e.target.value);
      if (onClear && e && e.target.value === '') {
        onClear();
      }
    }
  };

  const handleAutoCompleteSelectedValueChange = async (
    selectedSuggestion: IIPISearchSuggestion,
    triggerOnSelect = true,
  ) => {
    let selectedWriter;
    if (triggerOnSelect && onShowOverlay) {
      onShowOverlay(true);
    }
    if (!selectedSuggestion.cached) {
      const cachedWriter = await createCachedWriter(
        selectedSuggestion.roba_ipinumber,
        !!isPublisherSearch,
      );
      selectedWriter = {
        id: cachedWriter.roba_writer_cacheid,
        roba_firstname: cachedWriter.roba_firstname,
        roba_lastname: cachedWriter.roba_name,
        roba_ipinumber: cachedWriter.roba_ipinumber,
      };
    } else {
      selectedWriter = {
        id: selectedSuggestion.id,
        roba_firstname: selectedSuggestion?.roba_firstname,
        roba_lastname: selectedSuggestion?.roba_name,
        roba_ipinumber: selectedSuggestion.roba_ipinumber,
      };
    }
    setAutoCompleteSelectedValue(selectedSuggestion);
    setAutoCompleteInputValue(selectedSuggestion.label);
    if (triggerOnSelect) {
      onSelect(selectedWriter);
    }
    if (triggerOnSelect && onShowOverlay) {
      onShowOverlay(false);
    }
  };

  const setInitValue = async () => {
    const suggestion = suggestions.find(
      (suggestion: IIPISearchSuggestion) => suggestion.id === initValue,
    );
    if (suggestion) {
      handleAutoCompleteSelectedValueChange(suggestion, false);
    } else {
      const initPublisher = await getPublisherFromCache(initValue as string);
      if (initPublisher.value.length) {
        const suggestion = {
          label: `${initPublisher.value[0].roba_name} (IPI: ${initPublisher.value[0].roba_ipinumber})`,
          roba_ipinumber: initPublisher.value[0].roba_ipinumber,
          roba_type: 'P',
          cached: true,
        };
        setSuggestions([suggestion]);
        handleAutoCompleteSelectedValueChange(suggestion, false);
      }
    }
  };

  useEffect(() => {
    if (initValue) {
      setInitValue();
    }
  }, [initValue]);

  return (
    <Autocomplete
      filterOptions={(x: any) => x}
      options={suggestions}
      value={autoCompleteSelectedValue}
      inputValue={autoCompleteInputValue}
      onInputChange={handleAutoCompleteInputValueChange}
      onChange={(_: any, selectedSuggestion: IIPISearchSuggestion) =>
        handleAutoCompleteSelectedValueChange(selectedSuggestion)
      }
      disabled={disabled}
      disableClearable
      loading={loadingSuggestions}
      noOptionsText={noOptionsText}
      loadingText={loadingText}
      renderInput={params => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          disabled={disabled}
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingSuggestions ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <SearchIcon style={{ position: 'relative', top: '-6px' }} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
