export const validateIpi = (valueToTest) => {
  let num = valueToTest;
  let total = 0;
  let d = "0";
  num = "00000000000" + num;
  num = num.substr(num.length-11);
  const digits = num.split('');
  for (let i = 0; i < 9; i++) {
    d = digits[i];
    total += (10 - i) * d;
  }
  total %= 101;
  if (total != 0) {
     total = (101 - total) % 100;
  }
  const correct = (total == num.substring(9, 11));
  return correct;
}