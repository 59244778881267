export interface IWriter {
  roba_writerid?: string;
  roba_firstname: string;
  roba_lastname: string;
  roba_email: string;
  // roba_iscontrolled: boolean;
  roba_ipinumber: string;
  roba_society_mechanical: number | null;
  roba_society_performing: number | null;
  roba_street1: string;
  roba_street2: string;
  roba_postalcode: string;
  roba_city: string;
  roba_stateorprovince: string;
  roba_country: string;
  _roba_pseudonymparentwriterid_value: string | null;
  // _roba_agreementid_value: string;
  childWriter?: string[];
  agreements?: string[];
  createdon?: string;
}

export const newWriter: IWriter = {
  roba_firstname: '',
  roba_lastname: '',
  roba_email: '',
  // roba_iscontrolled: false,
  roba_ipinumber: '',
  roba_society_mechanical: null,
  roba_society_performing: null,
  roba_street1: '',
  roba_street2: '',
  roba_postalcode: '',
  roba_city: '',
  roba_stateorprovince: '',
  roba_country: '',
  _roba_pseudonymparentwriterid_value: null,
  // _roba_agreementid_value: '',
};

export const writerRequiredFields = ['roba_lastname'];
