import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  InputAdornment,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TablePagination,
  TextField,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import { Search as SearchIcon } from '../icons/search';
import { Scrollbar } from '../template/scrollbar';
import { IWriter } from '../../interfaces/Writer';
import { getWriterList } from '../../services/httpService';
import { useAuth } from '../provider/AuthProvider';
import loadingSpinner from '../../assets/images/loadingSpinner.svg';
import { ISortingOption, useList } from '../../hooks/useList';
import { IAgreement } from '../../interfaces/MyData';

const getSortOptions = (t: any): ISortingOption[] => [
  {
    label: t('Dashboard.Lists.CreationDateLatest'),
    value: 'createdon_desc',
  },
  {
    label: t('Dashboard.Lists.CreationDateOldest'),
    value: 'createdon_asc',
  },
  {
    label: t('Dashboard.Lists.NameAscending'),
    value: 'roba_lastname_asc',
  },
  {
    label: t('Dashboard.Lists.NameDescending'),
    value: 'roba_lastname_desc',
  },
];

export const WriterList = () => {
  const { t } = useTranslation();
  const { selectedUser, myData } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [writers, setWriters] = useState<IWriter[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [agreementFilter, setAgreementFilter] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const sortOptions = useMemo(() => getSortOptions(t), [t]);

  const agreements = useMemo(() => {
    const agreements = myData?.agreements || [];
    agreements.sort((a: any, b: any) => a.roba_name.localeCompare(b.roba_name));
    return agreements;
  }, [myData?.agreements]);

  const {
    search,
    itemsPerPage,
    page,
    sorting,
    itemsCount,
    setSearch,
    setItemsPerPage,
    setPage,
    setSorting,
    setItemsCount,
  } = useList(sortOptions[0]);

  const paginatedWriters = useMemo(() => {
    let filteredItems = [...writers];

    if (search) {
      filteredItems = filteredItems.filter((writer: IWriter) =>
        `${writer.roba_firstname}${writer.roba_lastname}${writer.roba_email}${writer.roba_ipinumber}`
          ?.toLowerCase()
          .includes(search.toLowerCase()),
      );
    }

    filteredItems = filteredItems.filter(writer =>
      agreementFilter.some((agreement: string) =>
        writer.agreements?.find((a: any) => a === agreement),
      ),
    );

    filteredItems.sort((a, b) => {
      if (sorting === 'roba_lastname_asc') {
        return (a.roba_lastname || '').localeCompare(b.roba_lastname || '');
      }
      if (sorting === 'roba_lastname_desc') {
        return (b.roba_lastname || '').localeCompare(a.roba_lastname || '');
      }
      if (sorting === 'createdon_asc' || sorting === 'createdon_desc') {
        const dateA = a.createdon ? new Date(a.createdon).getTime() : 0;
        const dateB = b.createdon ? new Date(b.createdon).getTime() : 0;
        return sorting === 'createdon_asc' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });

    setItemsCount(filteredItems.length);

    return filteredItems.slice(page * itemsPerPage, (page + 1) * itemsPerPage);
  }, [writers, page, itemsPerPage, sorting, search, agreementFilter]);

  const fetchWriters = async () => {
    setIsLoading(true);
    try {
      const agreementIds = myData?.agreements.map(
        (agreement: IAgreement) => agreement.roba_agreementid,
      );
      const writers: any = await getWriterList(agreementIds as string[]);
      setWriters(writers);
      setItemsCount(writers.length);
    } catch (e) {
      toast.error(t('Dashboard.Writers.WriterGetError'));
    } finally {
      setIsLoading(false);
    }
  };

  const debounceSearchChange = useCallback(
    debounce((search: string) => {
      setSearch(search);
      setPage(0);
    }, 500),
    [],
  );

  const handleSearchChange = (search: string) => {
    setSearchInput(search);
    debounceSearchChange(search);
  };

  const handleAgreementsFilterChange = (value: string[]) => {
    let nextAgreementFilters = value;
    if (value.includes('all')) {
      nextAgreementFilters = agreements.map((agreement: any) => agreement.roba_agreementid);
    }
    setAgreementFilter(nextAgreementFilters);
  };

  useEffect(() => {
    const urlSearch = searchParams.get('search') || '';
    const urlPage = parseInt(searchParams.get('page') || '0', 10);
    const urlSorting = searchParams.get('sorting') || sortOptions[0].value;
    const itemsPerPage = parseInt(searchParams.get('itemsPerPage') || '10', 10);
    setSearch(urlSearch);
    setPage(urlPage);
    setSorting(urlSorting);
    setSearchInput(urlSearch);
    setItemsPerPage(itemsPerPage);
  }, []);

  useEffect(() => {
    if (agreements && agreements.length) {
      setAgreementFilter(agreements.map((agreement: any) => agreement.roba_agreementid));
    }
  }, [agreements]);

  useEffect(() => {
    if (selectedUser && myData?.agreementIpiNumbers?.length) {
      fetchWriters();
    }
  }, [selectedUser, myData]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (search) params.append('search', search);
    if (page !== 0) params.append('page', page.toString());
    if (sorting !== sortOptions[0].value) params.append('sorting', sorting);
    if (itemsPerPage !== 10) params.append('itemsPerPage', itemsPerPage.toString());
    if (agreements && agreements.length && agreements.length !== agreementFilter.length) {
      params.append('agreements', agreementFilter.join(';'));
    }
    navigate(`?${params.toString()}`, { replace: true });
  }, [search, page, sorting, itemsPerPage, agreements, agreementFilter]);

  if (!selectedUser) {
    return (
      <Box
        sx={{
          mt: 20,
          mb: 30,
          textAlign: 'center',
        }}
      >
        <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3,
      }}
    >
      <Box
        sx={{
          m: -1,
          mb: 1,
        }}
      >
        <Link to="/dashboard/songs" style={{ textDecoration: 'none' }}>
          <Button sx={{ m: 1 }} variant="contained">
            {t('Dashboard.Sidebar.WorkDeclaration')}
          </Button>
        </Link>
      </Box>
      <Card>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            m: -1,
            p: 2,
          }}
        >
          <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 500,
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              placeholder={t('Dashboard.Writers.Search')}
              value={searchInput}
              onChange={(e: any) => handleSearchChange(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              m: 1,
              width: 240,
            }}
          >
            <FormControl fullWidth variant="filled">
              <InputLabel>{t('Dashboard.Songs.FilterByAgreement')}</InputLabel>
              <Select
                multiple
                value={agreementFilter}
                onChange={(e: any) => handleAgreementsFilterChange(e.target.value)}
                renderValue={(agreementFilter: string[]) =>
                  agreements
                    .filter((agreement: any) =>
                      agreementFilter.includes(agreement.roba_agreementid),
                    )
                    .map((agreement: any) => agreement.roba_name)
                    .join(', ')
                }
              >
                {agreementFilter.length !== agreements.length && (
                  <MenuItem key="all" value="all">
                    <Checkbox checked={agreementFilter.length === agreements.length} />
                    <ListItemText>{t('Dashboard.Common.All')}</ListItemText>
                  </MenuItem>
                )}
                {agreements.map((agreement: any) => (
                  <MenuItem key={agreement.roba_agreementid} value={agreement.roba_agreementid}>
                    <Checkbox checked={agreementFilter.includes(agreement.roba_agreementid)} />
                    <ListItemText>{agreement.roba_name}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              m: 1,
              width: 240,
            }}
          >
            <TextField
              label={t('Dashboard.Writers.SortBy')}
              name="sort"
              select
              SelectProps={{ native: true }}
              value={sorting}
              onChange={(e: any) => {
                setSorting(e.target.value);
                setPage(0);
              }}
            >
              {sortOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>
        {isLoading && (
          <Box
            sx={{
              mt: 20,
              mb: 30,
              textAlign: 'center',
            }}
          >
            <img src={loadingSpinner} alt={t('Loading')} style={{ width: '40px' }} />
          </Box>
        )}
        {!isLoading && (
          <Scrollbar>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Dashboard.Writers.Name')}</TableCell>
                  <TableCell>{t('Dashboard.Writers.EMail')}</TableCell>
                  <TableCell>{t('Dashboard.Writers.IPINumber')}</TableCell>
                  <TableCell align="right">{t('Dashboard.Writers.Edit')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedWriters.map((writer: IWriter) => (
                  <TableRow hover key={writer.roba_writerid}>
                    <TableCell>
                      {writer.roba_firstname} {writer.roba_lastname}
                    </TableCell>
                    <TableCell>{writer.roba_email}</TableCell>
                    {/* <TableCell>writer.roba_client </TableCell> */}
                    <TableCell>{writer.roba_ipinumber}</TableCell>
                    <TableCell align="right">
                      <Link
                        to={`/dashboard/writers/edit/${writer.roba_writerid}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        )}
        <TablePagination
          component="div"
          count={itemsCount}
          onPageChange={(_, page: number) => setPage(page)}
          onRowsPerPageChange={(e: any) => setItemsPerPage(e.target.value)}
          page={page}
          rowsPerPage={itemsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Card>
    </Box>
  );
};
