import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import { WriterForm } from '../../WriterForm/WriterForm';
import { IWriter } from '../../../../interfaces/Writer';

interface SongFormWritersModalProps {
  open: boolean;
  writerToEdit: IWriter | undefined;
  onSave: (createdWriterId?: string | undefined) => void;
  onClose: () => void;
}

export const SongFormWritersModal: React.FC<SongFormWritersModalProps> = ({
  open,
  writerToEdit,
  onSave,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleSave = (createdWriterId: string | undefined) => {
    onSave(createdWriterId);
  };

  return (
    <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={!!open}>
      <DialogContent>
        <WriterForm
          writerToEdit={writerToEdit}
          onModalSave={handleSave}
          onModalClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};
